import { useEffect, useState } from "react";
import { useAuth } from "../auth/FirebaseAuth";
import { MAX_FETCH_ELEMENTS } from "../../app/constants";
import {
  ShimmerCategoryItem,
  ShimmerText,
  ShimmerThumbnail,
  ShimmerTitle,
} from "react-shimmer-effects";
import loading from "../assets/global/loading.svg";
import { motion } from "framer-motion";
import nothingHere from "../assets/income/nothing.webp";

export function OrderHistory() {
  const { currentUser } = useAuth();

  // state here
  const [recentOrders, setRecentOrders] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getParams = new URLSearchParams();
    getParams.append("skip", 0);
    getParams.append("limit", MAX_FETCH_ELEMENTS);
    getParams.append("user_uid", currentUser.uid);

    if (currentUser) {
      fetch(`${process.env.REACT_APP_SERVER}/orders/?${getParams}`, {
        method: "GET",
        mode: "cors",
      })
        .then((data) => data.json())
        .then((recent_orders) => {
          const orderDataNew = recent_orders.map((orderItem) => {
            return {
              ...orderItem,
              styles: "max-h-56",
            };
          });
          setRecentOrders(orderDataNew);
        })
        .catch((err) => {
          console.log("RECENT-ORDERS-FETCH_ERROR: ", err);
        });
    }
  }, [currentUser]);

  const handleStyleChange = (item) => {
    const reMapped = recentOrders.map((foodOrder) => {
      return foodOrder.id === item.id
        ? {
            ...foodOrder,
            styles:
              item.styles === "max-h-56"
                ? "max-h-96  overflow-y-scroll"
                : "max-h-56",
          }
        : foodOrder;
    });
    setRecentOrders(reMapped);
  };

  const loadMoreItems = (e) => {
    if (currentUser) {
      setShowLoading(true);

      const getParams = new URLSearchParams();
      getParams.append("skip", recentOrders.length);
      getParams.append("limit", recentOrders.length + MAX_FETCH_ELEMENTS);
      getParams.append("user_uid", currentUser.uid);

      fetch(`${process.env.REACT_APP_SERVER}/orders/?${getParams}`, {
        method: "GET",
        mode: "cors",
      })
        .then((data) => data.json())
        .then((order_data) => {
          if (order_data.length > 0) {
            const orderDataNew = order_data.map((orderItem) => {
              return {
                ...orderItem,
                styles: "max-h-56",
              };
            });
            setRecentOrders(recentOrders.concat(orderDataNew));
          } else {
            setLoadingError(true);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setShowLoading(false);
        });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.1 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.1 } } }}
      className="pb-10"
    >
      {currentUser ? (
        <div className="pb-20">
          {/* Recent */}
          <div>
            {/* HR */}
            <div className="flex items-center mx-3 mt-7">
              <hr className="flex-grow border-t border-gray-300" />
              <span className="px-3 text-primaryFontColor font-semibold">
                Orders
              </span>
              <hr className="flex-grow border-t border-gray-300" />
            </div>
            {recentOrders ? (
              <div className="sm:flex sm:flex-col sm:gap-5 items-start justify-center md:grid md:grid-cols-2 mx-3">
                {recentOrders &&
                  recentOrders.map((order, index) => (
                    <div key={index}>
                      <div
                        className={`mt-5 rounded-2xl border w-full p-3 md:p-6 md:min-h-40 my-anim shadow-[0px_0px_15px_-10px_rgba(0,0,0,0.7)] duration-300 ${
                          order.styles
                        } overflow-hidden ${
                          order.food_order_successfull
                            ? ""
                            : "bg-red-100 border-red-500"
                        }`}
                        onClick={() => {
                          handleStyleChange(order);
                        }}
                      >
                        <div className="flex justify-between gap-2 md:gap-5">
                          <div>
                            <p className="md:text-lg text-primaryFontColor">
                              Price
                            </p>
                            <h1 className="text-4xl font-bold  md:text-4xl lg:text-5xl text-[#f1414f]">
                              &#8377;{order.total}
                            </h1>
                            {!order.food_order_successfull && (
                              <p className="font-bold text-red-700"> Failed </p>
                            )}
                          </div>
                          <div className="flex items-center">
                            <svg
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="w-7 h-7 mr-1"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
                            </svg>
                          </div>
                        </div>
                        {order.food_delivered ? (
                          <div className="flex gap-2 items-center text-green-600 font-bold">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                            Delivered
                          </div>
                        ) : (
                          <div>
                            {order.food_order_successfull && (
                              <div className="flex gap-2 items-center text-primaryFontColor font-bold">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                </svg>
                                In progress
                              </div>
                            )}
                          </div>
                        )}
                        <div className="flex flex-col gap-3 mt-5">
                          {order.items.map((item, itemIndex) => (
                            <div
                              key={itemIndex}
                              className="bg-[#001925] rounded-lg p-3 flex justify-between text-white items-start"
                            >
                              <div>
                                <h1 className="lg:text-xl">{item.food_name}</h1>
                                <p className="text-sm text-mainColor font-semibold">
                                  {item.food_qty} Pieces
                                </p>
                              </div>
                              <h1 className="lg:text-4xl text-[#f1414f]">
                                &#8377;{item.food_price}
                              </h1>
                            </div>
                          ))}
                        </div>
                        <p className="mt-3 text-primaryFontColor">
                          {order.food_ordered_at}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div>
                <div className="overflow-hidden mt-5 m-3">
                  <div className="mt-3">
                    <ShimmerThumbnail height={200} rounded />
                    <ShimmerTitle line={1} className="w-2/3" />
                    <ShimmerText line={1} />
                  </div>

                  <div className="mt-3">
                    <ShimmerThumbnail height={200} rounded />
                    <ShimmerTitle line={1} className="w-2/3" />
                    <ShimmerText line={1} />
                  </div>

                  <div className="mt-3">
                    <ShimmerThumbnail height={200} rounded />
                    <ShimmerTitle line={1} className="w-2/3" />
                    <ShimmerText line={1} />
                  </div>
                </div>
              </div>
            )}
            {showLoading && (
              <div className="flex items-center justify-center">
                <img src={loading} alt="" className="w-12 h-12 mt-7" />
              </div>
            )}
            {!loadingError && recentOrders && recentOrders.length > 0 && (
              <span className="flex flex-col items-center justify-center w-full px-3 mt-2 md:mt-7">
                <button
                  disabled={loadingError}
                  className="w-full p-3 rounded-2xl mt-3 border font-bold text-[#f1414f] shadow-[0px_0px_15px_-10px_rgba(0,0,0,0.7)]  disabled:bg-slate-400 md:w-full md:text-3xl lg:text-sm lg:p-2 lg:max-w-56"
                  onClick={(e) => loadMoreItems(e)}
                >
                  Load More
                </button>
              </span>
            )}

            {!loadingError && recentOrders && recentOrders.length <= 0 && (
              <div className="flex flex-col items-center justify-center mt-16 p-5">
                <img src={nothingHere} alt="" />
                <p className="text-xl text-center mt-5 text-slate-400">
                  Nothing here yet
                </p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="overflow-hidden m-3">
          <ShimmerCategoryItem />
          <ShimmerThumbnail center height={50} width={300} />

          <div className="flex flex-row items-center justify-center gap-4 mt-2">
            <div>
              <ShimmerThumbnail height={70} width={70} rounded />
              <ShimmerText line={1} />
            </div>
            <div>
              <ShimmerThumbnail height={70} width={70} rounded />
              <ShimmerText line={1} />
            </div>
            <div>
              <ShimmerThumbnail height={70} width={70} rounded />
              <ShimmerText line={1} />
            </div>
            <div>
              <ShimmerThumbnail height={70} width={70} rounded />
              <ShimmerText line={1} />
            </div>
          </div>

          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-2/3" />
            <ShimmerText line={1} />
          </div>

          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-2/3" />
            <ShimmerText line={1} />
          </div>

          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-2/3" />
            <ShimmerText line={1} />
          </div>

          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-2/3" />
            <ShimmerText line={1} />
          </div>
        </div>
      )}
    </motion.div>
  );
}
