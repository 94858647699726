import { useEffect, useState } from "react";
import { MAX_FETCH_ELEMENTS } from "../../app/constants";
import { useAuth } from "../auth/FirebaseAuth";
import nothingHere from "../assets/income/nothing.webp";

import { motion } from "framer-motion";

export function Income({ userFetched }) {
  const { currentUser } = useAuth();
  const [income, setIncome] = useState(null);
  const [earnings, setEarnings] = useState(null);
  const [loadingError, setLoadingError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (currentUser) {
      // Income
      const income_data = fetch(
        `${process.env.REACT_APP_SERVER}/income/${currentUser.uid}`,
        {
          method: "GET",
          mode: "cors",
        }
      ).then((data) => data.json());

      // Earning
      const getParams = new URLSearchParams();
      getParams.append("skip", 0);
      getParams.append("limit", MAX_FETCH_ELEMENTS);
      getParams.append("user_uid", currentUser.uid);

      const earning_data = fetch(
        `${process.env.REACT_APP_SERVER}/earnings/?${getParams}`,
        {
          method: "GET",
          mode: "cors",
        }
      ).then((data) => data.json());

      Promise.all([income_data, earning_data]).then(([income_, earnings_]) => {
        if (!income_.user_uid) {
          setIncome(null);
        } else {
          setIncome(income_);
        }
        setEarnings(earnings_);
      });
    }
  }, [currentUser]);

  const loadMoreItems = () => {
    const getParams = new URLSearchParams();
    getParams.append("skip", earnings.length);
    getParams.append("limit", earnings.length + MAX_FETCH_ELEMENTS);
    getParams.append("user_uid", currentUser.uid);

    fetch(`${process.env.REACT_APP_SERVER}/earnings/?${getParams}`, {
      method: "GET",
      mode: "cors",
    })
      .then((data) => data.json())
      .then((new_earnings) => {
        if (new_earnings.length > 0) {
          setEarnings(earnings.concat(new_earnings));
        } else {
          setLoadingError(true);
        }
      });
  };

  function dateMapping(dateTime) {
    // Given datetime
    var datetime = new Date(dateTime);

    // Convert to India Standard Time
    var IST_datetime = datetime.toLocaleString("en-US", {
      timeZone: "Asia/Kolkata",
    });

    return IST_datetime;
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.1 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.1 } } }}
      className="pb-20"
    >
      {/*  */}
      <div className="flex items-center mx-3 mt-7">
        <hr className="flex-grow border-t border-gray-300" />
        <span className="px-3 text-primaryFontColor font-semibold">
          My Income
        </span>
        <hr className="flex-grow border-t border-gray-300" />
      </div>
      {currentUser && income !== null ? (
        <div className="mx-3">
          {/*  Income */}
          <div className="sm:flex sm:flex-col gap-3 md:grid md:grid-cols-2">
            <div className="mt-5">
              {/*  */}
              <div className="p-2 flex flex-wrap gap-5 justify-between rounded-2xl mt-2 md:min-h-44 shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] border">
                <div className="bg-slate-100 rounded-xl grow p-4">
                  <div className="text-2xl font-bold bg-gradient-to-r from-amber-500 to-pink-500 bg-clip-text text-transparent">
                    ACTIVE
                  </div>
                  <div className="flex justify-between min-w-28 md:min-w-32">
                    <div className="flex flex-col">
                      <h1 className="text-primaryFontColor text-[2rem] md:text-lg lg:text-4xl">
                        &#8377;{income && income.active_income.toFixed(1)}
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="bg-slate-100 rounded-xl p-4">
                  <div className="text-2xl font-bold bg-gradient-to-r from-slate-900 to-slate-400 bg-clip-text text-transparent">
                    PASSIVE
                  </div>

                  <div className="flex justify-between min-w-28 md:min-w-32">
                    <div className="flex flex-col">
                      <h1 className="text-[2rem] text-primaryFontColor md:text-lg lg:text-4xl">
                        &#8377;{income && income.passive_income.toFixed(1)}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*  */}
            <div className="mt-5">
              <div className="p-2 flex gap-5 rounded-2xl mt-2 md:min-h-44 shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] border">
                <div className="rounded-xl p-4 w-full">
                  <div className="text-2xl font-bold bg-gradient-to-r from-slate-900 to-slate-400 bg-clip-text text-transparent">
                    TOTAL
                  </div>
                  <div className="flex justify-between">
                    <div className="flex flex-col gap-2">
                      <h1 className="text-[2rem] md:text-4xl lg:text-5xl text-primaryFontColor">
                        &#8377;
                        {income &&
                          (
                            income.active_income + income.passive_income
                          ).toFixed(1)}
                      </h1>
                      {earnings && earnings.length > 0 && (
                        <p className="text-mainColor">
                          <span className="font-bold md:text-lg">Updated </span>
                          {dateMapping(earnings[0].earned_date)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3 text-red-600 text-center">
                <strong>NOTE:</strong> Your Affiliate Income will be credited on
                9, 18 and 27 of every month, but your income should be more than
                300rs. Terms and Conditions apply.
              </div>
            </div>
          </div>

          {/* Notifications */}
          <div className="bg-white relative">
            <div className="flex items-center mt-5 pb-5 mx-3">
              <hr className="flex-grow border-t border-gray-300" />
              <span className="px-3 text-primaryFontColor font-semibold">
                NOTIFICATIONS
              </span>
              <hr className="flex-grow border-t border-gray-300" />
            </div>
            <div className="sm:flex sm:flex-col pt-2  md:grid md:grid-cols-2 gap-3">
              {earnings.map((earning, index) => (
                <div
                  className="max-w-sm rounded-2xl overflow-hidden border mt-2"
                  key={index}
                >
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2 text-primaryFontColor">
                      You've Earned &#8377;{earning.amount.toFixed(1)}
                    </div>
                    <p className="text-gray-700 text-base">
                      Congratulations on your earnings! Keep up the great work.
                    </p>
                  </div>
                  <div className="px-6 pb-4">
                    <p className="text-gray-600 text-sm">
                      Earned through→{" "}
                      {userFetched &&
                        earning.referrer_idf &&
                        earning.referrer_idf !== userFetched.user_name && (
                          <span>
                            <span className="text-blue-500">
                              {earning.referrer_idf}
                            </span>{" "}
                            →{" "}
                          </span>
                        )}
                      <span className="text-blue-500">{earning.buyer_idf}</span>
                    </p>
                    <p className="text-gray-600 text-sm">
                      Mode →{" "}
                      <span className="text-blue-500">{earning.mode}</span>
                    </p>
                    <p className="text-gray-600 text-sm">
                      Price bought →{" "}
                      <span className="text-blue-500">
                        &#8377;{earning.bought_price}
                      </span>
                    </p>
                    <p className="text-gray-600 text-sm">
                      Status →{" "}
                      <span
                        className={` ${
                          earning.payed ? "text-green-500" : "text-red-500"
                        }`}
                      >
                        {earning.payed ? "Paid" : "Not paid"}
                      </span>
                    </p>
                    <p className="mt-5">{dateMapping(earning.earned_date)}</p>
                  </div>
                </div>
              ))}
              {/* <div className="h-40" /> */}
            </div>
          </div>

          {/*  */}
          {!loadingError ? (
            <span className="flex flex-col items-center justify-center w-full">
              <button
                disabled={loadingError}
                className="bg-mainColor w-full p-3 rounded-2xl mt-10 text-white disabled:bg-slate-400 md:w-full md:text-3xl lg:text-sm lg:p-2 lg:max-w-56"
                onClick={(e) => loadMoreItems(e)}
              >
                Load More
              </button>
            </span>
          ) : (
            <p></p>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center mt-16 p-5">
          <img src={nothingHere} alt="" />
          <p className="text-xl text-center mt-5 text-slate-400">
            Nothing here yet
          </p>
        </div>
      )}
    </motion.div>
  );
}
