import React, { useEffect } from "react";
import { FaFacebook, FaInstagram, FaEnvelope } from "react-icons/fa";
import { motion } from "framer-motion";

export function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.1 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.1 } } }}
      className="bg-gray-100 min-h-screen flex flex-col justify-center items-center pb-20 overflow-hidden md:bg-white"
    >
      <div className="max-w-3xl mx-auto p-8 bg-white shadow-md rounded-md md:border">
        <h1 className="text-3xl font-bold mb-6 text-center text-mainColor">
          About EatoEarn
        </h1>
        <p className="text-gray-700 text-lg mb-6">
          Welcome to EatoEarn, your one-stop solution for convenient and
          delicious online food ordering. Our platform is designed to provide
          you with a seamless experience, allowing you to explore a variety of
          cuisines and discover new flavors from the comfort of your home.
        </p>
        <p className="text-gray-700 text-lg mb-6">
          At EatoEarn, we prioritize quality and efficiency. Our team works
          tirelessly to partner with the best restaurants in town, ensuring that
          you have access to a diverse menu of mouth-watering options. Whether
          you're craving a hearty meal, a quick snack, or something sweet,
          EatoEarn has got you covered.
        </p>
        <p className="text-gray-700 text-lg mb-6">
          Stay connected with us on social media to stay updated on the latest
          promotions, special offers, and culinary trends. Follow us on
        </p>
        <div className="flex items-center justify-center gap-3 mt-2 flex-wrap">
          <span
            className="text-blue-500 flex items-center gap-1"
            onClick={() =>
              window.open(
                "https://www.facebook.com/profile.php?id=61557031020995&mibextid=opq0tG"
              )
            }
          >
            <FaFacebook /> Facebook
          </span>
          <span
            className="text-[#1e3050] flex items-center gap-1"
            onClick={() =>
              window.open(
                "https://x.com/eatoearn?s=21&t=4TsFxkVoHW9gXw_tSA6kkQ"
              )
            }
          >
            <svg
              className="w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
            </svg>
            /Twitter
          </span>
          <span
            className="text-pink-500 flex items-center gap-1"
            onClick={() =>
              window.open(
                "https://www.instagram.com/eatoearn?igsh=anI1amY5YzVzZzht"
              )
            }
          >
            <FaInstagram /> Instagram
          </span>
        </div>
      </div>
      <div className="mt-8 text-center">
        <p className="text-gray-600">Contact us at</p>
        <p
          className="text-blue-500 flex items-center gap-2"
          onClick={() => window.open("mailto:support@eatoearn.com")}
        >
          <FaEnvelope /> support@eatoearn.com
        </p>
      </div>
    </motion.div>
  );
}
