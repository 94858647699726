import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Navigate,
  useNavigate,
  useLocation,
  useRoutes,
} from "react-router-dom";
import { Auth } from "./components/auth/Auth";
import { Login } from "./components/auth/Login";
import { Signup } from "./components/auth/Signup";
import { ForgetPassword } from "./components/auth/Forget";
import { PaymentSuccess } from "./components/phonepe/paymentSuccess";
import { useAuth } from "./components/auth/FirebaseAuth";
import { CustomerProfile } from "./components/user/CustomerProfile";
import { RecentlyViewed } from "./components/user/RecentlyViewed";
import { OrderHistory } from "./components/user/OrderHistory";
import { Addresses } from "./components/user/Addresses";
import { Favourites } from "./components/user/Favourites";
import {
  ADDRESS_DEFAULT,
  BANNER_DATA,
  CART,
  FEATURED_DATA,
  NAV_CART,
  NAV_FAV,
  NAV_MENU,
  ORDER_FOOD,
  PAY_DATA,
} from "./app/constants";
import { Customer } from "./components/user/Customer";
import brand from "./components/assets/global/brand.png";
import cart_empty from "./components/assets/cart/cart_empty.avif";
import { els } from "./app/utils";
import { Menu } from "./components/user/Menu";
import { Cart } from "./components/user/Cart";
import { ActivePlan } from "./components/user/ActivePlan";
import { Income } from "./components/user/Income";
import { ViewAll } from "./components/user/ViewAll";
import { ProfileSetting } from "./components/user/ProfileSetting";
import RefundReturnPolicy from "./components/user/RefundReturnPolicy";
import TermsAndConditions from "./components/user/TermsAndConditions";
import { AboutUs } from "./components/user/AboutUs";
import { Feedback } from "./components/user/Feedback";
import { AnimatePresence } from "framer-motion";
import { ShippingPolicy } from "./components/user/ShippingPolicy";
import { TailSpin } from "react-loader-spinner";
import { CompleteProfile } from "./components/auth/Complete";
import toast, { Toaster } from "react-hot-toast";

const App = () => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const [path, setPath] = useState(window.location.pathname.replace("/", ""));
  const [cartItems, setCartItems] = useState(JSON.parse(els.get(CART)));
  const [deliveryCharges, setDeliveryCharges] = useState(50);
  const [addresses, setAddresses] = useState([]);
  const [ELS_DATA, setELS_DATA] = useState(
    JSON.parse(els.get(ADDRESS_DEFAULT))
  );
  const [currentAddress, setCurrentAddress] = useState("");
  const [saved, setSaved] = useState(null);
  const [suggestion, setSuggestion] = useState("");
  const [userFetched, setUserFetched] = useState(null);
  const [availablity, setAvailablity] = useState({
    foods: false,
    plans: false,
  });
  const [banners, setBanners] = useState(
    JSON.parse(els.get(BANNER_DATA)) || []
  );
  const [featured, setFeatured] = useState(
    JSON.parse(els.get(FEATURED_DATA)) || []
  );
  const [showLoading, setShowLoading] = useState(true);
  const [currentWidth, setCurrentWidth] = useState(5);
  const [addrValue, setAddrValue] = useState(
    JSON.parse(els.get(ADDRESS_DEFAULT)) &&
      JSON.parse(els.get(ADDRESS_DEFAULT)).address_type
  );

  const changeQty = (item, add) => {
    if (add) {
      const newCart = cartItems.map((it) =>
        it.id === item.id
          ? {
              ...item,
              qty: it.qty + 1,
            }
          : it
      );
      setCartItems(newCart);
      els.set(CART, JSON.stringify(newCart));
    } else {
      if (item.qty === 1) {
        const filteredCart = cartItems.filter((it) => it.id !== item.id);
        setCartItems(filteredCart);
        els.set(CART, JSON.stringify(filteredCart));
      } else {
        const newCart = cartItems.map((it) =>
          it.id === item.id
            ? {
                ...item,
                qty: it.qty - 1,
              }
            : it
        );
        setCartItems(newCart);
        els.set(CART, JSON.stringify(newCart));
      }
    }
  };

  const getTotalCart = () => {
    let sum = 0;
    cartItems.forEach((item) => {
      sum += item.cost * item.qty;
    });
    return sum;
  };

  const handleSetCurrentAddress = (type) => {
    if (addresses.length > 0) {
      const saved = addresses.filter((it) => it.address_type === type)[0];
      els.set(ADDRESS_DEFAULT, JSON.stringify(saved));
      setCurrentAddress(saved.address_detected);
      handleDeliveryChargeChange(saved.address_pincode);
      setSaved(saved);
    }
  };

  const navigate = useNavigate();

  const handleCheckout = () => {
    const order = {
      user_uid: currentUser && currentUser.uid,
      billed_to:
        (currentUser && currentUser.displayName) ||
        (currentUser && currentUser.email.split("@")[0]),
      total: getTotalCart() + deliveryCharges + 3,
      delivery_charges: deliveryCharges,
      platform_fee: 3,
      items: cartItems.map((it) => {
        return {
          food_thumb_url: it.food_thumb_url,
          food_name: it.food_name,
          food_price: it.cost,
          food_qty: it.qty,
          food_type: it.opt,
        };
      }),
      suggestion: suggestion,
      address: saved,
    };

    fetch(`${process.env.REACT_APP_SERVER}/pay`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json", // Specify the content type as JSON
      },
      body: JSON.stringify({
        pay_type: ORDER_FOOD,
        user_uid: currentUser && currentUser.uid,
        food_order: order,
      }),
    })
      .then((data) => data.json())
      .then((json_data) => {
        if (json_data.pay_page_url) {
          els.set(
            PAY_DATA,
            JSON.stringify({
              merchant_transaction_id: json_data.merchant_transaction_id,
              pay_type: ORDER_FOOD,
              user_uid: currentUser.uid,
              code: json_data.code,
              success: json_data.success,
            })
          );
          // window.open(json_data.pay_page_url, "_blank");
          window.location.href = json_data.pay_page_url;
        }
      })
      .catch((err) => {
        // setModalState({
        //   title: "Error",
        //   message: "Unkown error occurred, please try again",
        // });
        // setModalOpen(true);
        window.alert("error");
      });
  };

  const handleDeliveryChargeChange = (pincode) => {
    if (currentUser && saved) {
      fetch(`${process.env.REACT_APP_SERVER}/pincodes/${pincode}`, {
        method: "GET",
        mode: "cors",
      })
        .then((data) => data.json())
        .then((pincode_data) => {
          setDeliveryCharges(pincode_data.charges);
        })
        .catch((err) => {});
    }
  };

  useLayoutEffect(() => {
    setPath(location.pathname.replace("/", ""));
    setELS_DATA(JSON.parse(els.get(ADDRESS_DEFAULT)));
    if (ELS_DATA) {
      setAddresses(
        [ELS_DATA].concat(
          addresses.filter((it) => it.address_type !== ELS_DATA.address_type)
        )
      );
    }
  }, [location.pathname]);

  useEffect(() => {
    if (ELS_DATA !== null) {
      setSaved(ELS_DATA);
      setCurrentAddress(ELS_DATA.address_detected);
      setCurrentWidth(ELS_DATA.address_type.length);
      handleDeliveryChargeChange(ELS_DATA.address_pincode);
    }

    // Loading overlay...
    if (window.document.readyState === "complete") {
      setTimeout(() => {
        setShowLoading(false);
      }, 500);
    } else {
      window.addEventListener("load", () => {
        setTimeout(() => {
          setShowLoading(false);
        }, 500);
      });
    }

    // User
    if (currentUser) {
      fetch(`${process.env.REACT_APP_SERVER}/users/${currentUser.uid}`, {
        method: "GET",
        mode: "cors",
      })
        .then((data) => data.json())
        .then((user_data) => {
          setUserFetched(user_data);
        })
        .catch((err) => {});
    }

    // Banners
    fetch(`${process.env.REACT_APP_SERVER}/banners`, {
      method: "GET",
      mode: "cors",
    })
      .then((data) => data.json())
      .then((banners_) => {
        els.set(BANNER_DATA, JSON.stringify(banners_));
        setBanners(banners_);
      })
      .catch((err) => {});

    // Featured
    fetch(`${process.env.REACT_APP_SERVER}/featured`, {
      method: "GET",
      mode: "cors",
    })
      .then((data) => data.json())
      .then((featured_) => {
        els.set(FEATURED_DATA, JSON.stringify(featured_));
        setFeatured(featured_);
      })
      .catch((err) => {});

    // Availablity...
    fetch(`${process.env.REACT_APP_SERVER}/availablity/`, {
      method: "GET",
      mode: "cors",
    })
      .then((resp) => resp.json())
      .then((setting) => {
        setAvailablity(setting);
      })
      .catch((err) => {});

    const paramsGet = new URLSearchParams();
    paramsGet.append("user_uid", currentUser && currentUser.uid);

    if (currentUser) {
      fetch(`${process.env.REACT_APP_SERVER}/addresses?${paramsGet}`, {
        method: "GET",
        mode: "cors",
      })
        .then((data) => data.json())
        .then((address_data) => {
          setAddresses(address_data);
          if (ELS_DATA === null) {
            setCurrentAddress(address_data[0].address_detected);
            els.set(ADDRESS_DEFAULT, JSON.stringify(address_data[0]));
            setSaved(address_data[0]);
          } else {
            setAddresses(
              [ELS_DATA].concat(
                address_data.filter(
                  (it) => it.address_type !== ELS_DATA.address_type
                )
              )
            );
          }
        })
        .catch((err) => {});
    }
  }, [currentUser]);

  const element = useRoutes([
    {
      path: "/",
      element: (
        <Customer
          setCartItems={(items) => setCartItems(items)}
          userMode={(userFetched && userFetched.user_mode) || "customer"}
          userFetched={userFetched}
          banners={banners}
          featuredItems={featured}
        />
      ),
    },
    { path: "/auth", element: <Auth /> },
    { path: "/login", element: <Login /> },
    {
      path: "/signup",
      element: (
        <Signup userFetched={userFetched} setUserFetched={setUserFetched} />
      ),
    },
    {
      path: "/view",
      element: <ViewAll setCartItems={(items) => setCartItems(items)} />,
    },
    { path: "/feedback", element: <Feedback /> },
    { path: "/about", element: <AboutUs /> },
    { path: "/income", element: <Income userFetched={userFetched} /> },
    {
      path: "/plan",
      element: (
        <ActivePlan
          saved={saved}
          availablity={availablity}
          charges={deliveryCharges}
        />
      ),
    },
    { path: "/cart", element: <Cart availablity={availablity} /> },
    { path: "/menu", element: <Menu /> },
    {
      path: "/favourites",
      element: <Favourites setCartItems={(items) => setCartItems(items)} />,
    },
    { path: "/return-refund-policy", element: <RefundReturnPolicy /> },
    { path: "/terms-services", element: <TermsAndConditions /> },
    { path: "/shipping-policy", element: <ShippingPolicy /> },
    {
      path: "/settings",
      element: (
        <ProfileSetting
          userFetched={userFetched}
          setUserFetched={setUserFetched}
        />
      ),
    },
    {
      path: "/addresses",
      element: (
        <Addresses
          addresses={addresses}
          setAddresses={setAddresses}
          setCurrentAddress={setCurrentAddress}
          setSaved={setSaved}
        />
      ),
    },
    { path: "/history", element: <OrderHistory /> },
    { path: "/recent", element: <RecentlyViewed /> },
    {
      path: "/profile",
      element: (
        <CustomerProfile
          userFetched={userFetched}
          setUserFetched={setUserFetched}
        />
      ),
    },
    { path: "/forget", element: <ForgetPassword /> },
    {
      path: "/payment",
      element: <PaymentSuccess setCartItems={(items) => setCartItems(items)} />,
    },
    {
      path: "/complete",
      element: <CompleteProfile />,
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);

  return (
    <div>
      <Toaster position="top-center" />

      {/* Loader */}
      {showLoading && (
        <div className="flex items-center justify-center fixed w-[100vw] h-[100vh] bg-gray-100 z-[1000] left-0 top-0 opacity-80">
          <TailSpin
            visible={showLoading}
            height="80"
            width="80"
            color="#FC6011"
            ariaLabel="tail-spin-loading"
            radius="1"
          />
        </div>
      )}

      <div className="sm:flex sm:flex-col md:grid md:grid-cols-6 h-full">
        {/* Nav */}
        <div className="flex fixed p-0 z-50 w-full bg-white bottom-0 opacity-95 md:w-1/6 md:left-0 md:h-full md:flex-col shadow-[0px_-2px_4px_rgba(0,0,0,.1)] lg:shadow-none lg:border-r">
          <div
            className="hidden lg:flex lg:items-center lg:justify-center lg:w-full lg:flex-col"
            onClick={() => navigate("/")}
          >
            <img src={brand} alt="" className="p-5 max-h-40" />
          </div>

          <div className="flex w-full items-center justify-between lg:p-3 md: left-0 md:justify-around md:h-full md:flex-col lg:items-start lg:mt-10">
            {/* Home */}
            <span
              className={`lg:w-full p-3 h-full grow ${
                path === "" && "border-mainColor border-t-2 md:border-none"
              } `}
              onClick={(e) => {
                setPath("");
                navigate("/");
              }}
            >
              <div
                className={`flex flex-col justify-center items-center lg:flex-row lg:p-3 lg:rounded-2xl lg:justify-between lg:px-5 lg:gap-3 lg:cursor-pointer ${
                  path === "" &&
                  "lg:shadow-[0px_0px_15px_-10px_rgba(0,0,0,0.7)]"
                }`}
              >
                {path === "" ? (
                  <svg
                    width="135"
                    height="135"
                    viewBox="0 0 135 135"
                    fill="none"
                    className="w-7 h-7 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M73.4658 12.6562C71.8835 11.0744 69.7378 10.1858 67.5004 10.1858C65.2631 10.1858 63.1174 11.0744 61.5351 12.6562L5.45107 68.7318C5.05883 69.124 4.74769 69.5897 4.53541 70.1022C4.32313 70.6147 4.21387 71.164 4.21387 71.7187C4.21387 72.2734 4.32313 72.8227 4.53541 73.3352C4.74769 73.8477 5.05883 74.3133 5.45107 74.7056C6.24324 75.4977 7.31765 75.9428 8.43795 75.9428C8.99266 75.9428 9.54194 75.8335 10.0544 75.6212C10.5669 75.4089 11.0326 75.0978 11.4248 74.7056L67.5004 18.6215L123.576 74.7056C124.368 75.4977 125.443 75.9428 126.563 75.9428C127.683 75.9428 128.758 75.4977 129.55 74.7056C130.342 73.9134 130.787 72.839 130.787 71.7187C130.787 70.5984 130.342 69.524 129.55 68.7318L109.688 48.8784V21.0937C109.688 19.9748 109.243 18.9017 108.452 18.1106C107.661 17.3194 106.588 16.8749 105.469 16.8749H97.0317C95.9128 16.8749 94.8398 17.3194 94.0486 18.1106C93.2574 18.9017 92.8129 19.9748 92.8129 21.0937V32.0034L73.4658 12.6562Z"
                      fill="#FC6011"
                    />
                    <path
                      d="M67.5 27.7847L118.125 78.4097V113.906C118.125 117.263 116.792 120.482 114.418 122.856C112.045 125.229 108.825 126.562 105.469 126.562H29.5312C26.1746 126.562 22.9554 125.229 20.5819 122.856C18.2084 120.482 16.875 117.263 16.875 113.906V78.4097L67.5 27.7847Z"
                      fill="#FC6011"
                    />
                  </svg>
                ) : (
                  <svg
                    width="135"
                    height="135"
                    viewBox="0 0 135 135"
                    fill="none"
                    className="w-7 h-7 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M73.4658 12.6562C71.8835 11.0744 69.7378 10.1858 67.5004 10.1858C65.2631 10.1858 63.1174 11.0744 61.5351 12.6562L5.45107 68.7318C5.05883 69.124 4.74769 69.5897 4.53541 70.1022C4.32313 70.6147 4.21387 71.164 4.21387 71.7187C4.21387 72.2734 4.32313 72.8227 4.53541 73.3352C4.74769 73.8477 5.05883 74.3133 5.45107 74.7056C6.24324 75.4977 7.31765 75.9428 8.43795 75.9428C8.99266 75.9428 9.54194 75.8335 10.0544 75.6212C10.5669 75.4089 11.0326 75.0978 11.4248 74.7056L67.5004 18.6215L123.576 74.7056C124.368 75.4977 125.443 75.9428 126.563 75.9428C127.683 75.9428 128.758 75.4977 129.55 74.7056C130.342 73.9134 130.787 72.839 130.787 71.7187C130.787 70.5984 130.342 69.524 129.55 68.7318L109.688 48.8784V21.0937C109.688 19.9748 109.243 18.9017 108.452 18.1106C107.661 17.3194 106.588 16.8749 105.469 16.8749H97.0317C95.9128 16.8749 94.8398 17.3194 94.0486 18.1106C93.2574 18.9017 92.8129 19.9748 92.8129 21.0937V32.0034L73.4658 12.6562Z"
                      fill="#B5B6B6"
                    />
                    <path
                      d="M67.5 27.7847L118.125 78.4097V113.906C118.125 117.263 116.792 120.482 114.418 122.856C112.045 125.229 108.825 126.562 105.469 126.562H29.5312C26.1746 126.562 22.9554 125.229 20.5819 122.856C18.2084 120.482 16.875 117.263 16.875 113.906V78.4097L67.5 27.7847Z"
                      fill="#B5B6B6"
                    />
                  </svg>
                )}
                <p className="hidden  text-mainColor md:text-2xl md:p-2 md:block lg:text-xl">
                  Home
                </p>
              </div>
            </span>

            {/* Menu */}
            <span
              className={`lg:w-full h-full p-3 grow ${
                path === NAV_MENU &&
                "border-mainColor border-t-2 md:border-none"
              } `}
              onClick={(e) => {
                setPath(NAV_MENU);
                navigate("/menu");
              }}
            >
              <div
                className={`flex flex-col justify-center items-center lg:flex-row lg:p-3 lg:rounded-2xl lg:justify-between lg:px-5 lg:gap-3 lg:cursor-pointer ${
                  path === NAV_MENU &&
                  "lg:shadow-[0px_0px_15px_-10px_rgba(0,0,0,0.7)]"
                }`}
              >
                {path === NAV_MENU ? (
                  <svg
                    width="135"
                    height="135"
                    viewBox="0 0 135 135"
                    fill="none"
                    className="w-7 h-7 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M58.6667 11H15.3333C12.9401 11 11 12.9401 11 15.3333V58.6667C11 61.0599 12.9401 63 15.3333 63H58.6667C61.0599 63 63 61.0599 63 58.6667V15.3333C63 12.9401 61.0599 11 58.6667 11Z"
                      fill="#FC6011"
                    />
                    <path
                      d="M118.741 11H75.4076C73.0143 11 71.0742 12.9401 71.0742 15.3333V58.6667C71.0742 61.0599 73.0143 63 75.4076 63H118.741C121.134 63 123.074 61.0599 123.074 58.6667V15.3333C123.074 12.9401 121.134 11 118.741 11Z"
                      fill="#FC6011"
                    />
                    <path
                      d="M118.741 70.074H75.4076C73.0143 70.074 71.0742 72.0141 71.0742 74.4073V117.741C71.0742 120.134 73.0143 122.074 75.4076 122.074H118.741C121.134 122.074 123.074 120.134 123.074 117.741V74.4073C123.074 72.0141 121.134 70.074 118.741 70.074Z"
                      fill="#FC6011"
                    />
                    <path
                      d="M58.6667 70.074H15.3333C12.9401 70.074 11 72.0141 11 74.4073V117.741C11 120.134 12.9401 122.074 15.3333 122.074H58.6667C61.0599 122.074 63 120.134 63 117.741V74.4073C63 72.0141 61.0599 70.074 58.6667 70.074Z"
                      fill="#FC6011"
                    />
                  </svg>
                ) : (
                  <svg
                    width="135"
                    height="135"
                    viewBox="0 0 135 135"
                    fill="none"
                    className="w-7 h-7 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M58.6667 11H15.3333C12.9401 11 11 12.9401 11 15.3333V58.6667C11 61.0599 12.9401 63 15.3333 63H58.6667C61.0599 63 63 61.0599 63 58.6667V15.3333C63 12.9401 61.0599 11 58.6667 11Z"
                      fill="#B5B6B6"
                    />
                    <path
                      d="M118.741 11H75.4076C73.0143 11 71.0742 12.9401 71.0742 15.3333V58.6667C71.0742 61.0599 73.0143 63 75.4076 63H118.741C121.134 63 123.074 61.0599 123.074 58.6667V15.3333C123.074 12.9401 121.134 11 118.741 11Z"
                      fill="#B5B6B6"
                    />
                    <path
                      d="M118.741 70.074H75.4076C73.0143 70.074 71.0742 72.0141 71.0742 74.4073V117.741C71.0742 120.134 73.0143 122.074 75.4076 122.074H118.741C121.134 122.074 123.074 120.134 123.074 117.741V74.4073C123.074 72.0141 121.134 70.074 118.741 70.074Z"
                      fill="#B5B6B6"
                    />
                    <path
                      d="M58.6667 70.074H15.3333C12.9401 70.074 11 72.0141 11 74.4073V117.741C11 120.134 12.9401 122.074 15.3333 122.074H58.6667C61.0599 122.074 63 120.134 63 117.741V74.4073C63 72.0141 61.0599 70.074 58.6667 70.074Z"
                      fill="#B5B6B6"
                    />
                  </svg>
                )}
                <p className="hidden text-mainColor md:text-2xl md:block lg:text-xl">
                  Menu
                </p>
              </div>
            </span>

            {/* Favourites */}
            <span
              className={`lg:w-full h-full p-3 grow ${
                path === NAV_FAV && "border-mainColor border-t-2 md:border-none"
              } `}
              onClick={(e) => {
                setPath(NAV_FAV);
                currentUser ? navigate("/favourites") : navigate("/login");
              }}
            >
              <div
                className={`flex flex-col justify-center items-center lg:flex-row lg:p-3 lg:rounded-2xl lg:justify-between lg:px-5 lg:gap-3 lg:cursor-pointer ${
                  path === NAV_FAV &&
                  "lg:shadow-[0px_0px_15px_-10px_rgba(0,0,0,0.7)]"
                }`}
              >
                {path === NAV_FAV ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#FC6011"
                    className="w-7 h-7 md:ml-0 md:w-9 md:h-9 lg:w-6 lg:h-6"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#B5B6B6"
                    className="w-7 h-7 md:ml-0 md:w-9 md:h-9 lg:w-6 lg:h-6"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"
                    />
                  </svg>
                )}
                <p className="hidden text-mainColor md:text-2xl md:block lg:text-xl">
                  Favourites
                </p>
              </div>
            </span>

            {/* Bag */}
            <span
              className={`lg:w-full h-full p-3 grow md:hidden ${
                path === NAV_CART &&
                "border-mainColor border-t-2 md:border-none"
              } `}
              onClick={(e) => {
                setPath(NAV_CART);
                navigate("/cart");
              }}
            >
              <div
                className={`flex flex-col justify-center items-center lg:flex-row lg:p-3 lg:rounded-2xl lg:justify-between lg:px-5 lg:cursor-pointer ${
                  path === NAV_CART &&
                  "lg:shadow-[0px_0px_15px_-10px_rgba(0,0,0,0.7)]"
                }`}
              >
                {path === NAV_CART ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#FC6011"
                    className="w-7 h-7 md:w-9 md:h-9 md:ml-5 lg:w-6 lg:h-6 lg:m-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#B5B6B6"
                    className="w-7 h-7 md:w-9 md:h-9 md:ml-5 lg:w-6 lg:h-6 lg:m-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4z" />
                  </svg>
                )}
                <p className="hidden md:text-2xl md:p-2 md:block text-mainColor lg:text-xl lg:p-0 lg:pr-5">
                  Bag
                </p>
              </div>
            </span>

            <div className="hidden lg:block"></div>
          </div>
        </div>

        <div className=""></div>

        <div className="sm:flex md:col-span-5">
          <div className="w-full lg:w-[70%]">
            {/* Header */}
            {path !== "profile" && (
              <div className="flex items-center justify-between mx-3 mt-3">
                <div className="select-none w-full">
                  <div className="flex items-center justify-between">
                    <div className="flex gap-0 md:gap-2">
                      <div className="border-solid rounded-[100%] w-11 h-11 scale-75 md:scale-100 bg-mainColor flex items-center justify-center">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12 13.43C13.7231 13.43 15.12 12.0331 15.12 10.31C15.12 8.58687 13.7231 7.19 12 7.19C10.2769 7.19 8.88 8.58687 8.88 10.31C8.88 12.0331 10.2769 13.43 12 13.43Z"
                            stroke="#ffffff"
                            strokeWidth="1.5"
                          />
                          <path
                            d="M3.62001 8.49C5.59001 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.39001 20.54C5.63001 17.88 2.47001 13.57 3.62001 8.49Z"
                            stroke="#ffffff"
                            strokeWidth="1.5"
                          />
                        </svg>
                      </div>
                      <div>
                        {currentUser && addresses.length > 0 ? (
                          <div>
                            <span className="flex items-center text-sm md:mt-[2px]">
                              <select
                                value={addrValue}
                                style={{
                                  width: `${Math.max(40, currentWidth * 8)}px`,
                                }}
                                onChange={(e) => {
                                  handleSetCurrentAddress(
                                    e.currentTarget.value.toLowerCase()
                                  );
                                  setAddrValue(e.currentTarget.value);
                                  setCurrentWidth(e.currentTarget.value.length);
                                }}
                                className="bg-transparent appearance-none text-ellipsis whitespace-nowrap font-bold text-primaryFontColor mt-1 md:mt-0"
                              >
                                {addresses.map((address, index) => (
                                  <option key={index}>
                                    {address.address_type
                                      .charAt(0)
                                      .toUpperCase() +
                                      address.address_type.slice(1)}
                                  </option>
                                ))}
                              </select>
                              <span className="mt-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="w-3 h-3"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                                  />
                                </svg>
                              </span>
                            </span>
                            <p className="text-secondaryFontColor -translate-y-1 text-sm md:text-lg">
                              {currentAddress}
                            </p>
                          </div>
                        ) : (
                          <div
                            onClick={() =>
                              navigate(currentUser ? "/addresses" : "/login")
                            }
                            className="px-2"
                          >
                            <span className="flex items-center gap-1 text-sm ">
                              <div className="appearance-none bg-transparent text-ellipsis whitespace-nowrap font-bold text-primaryFontColor mt-1 md:mt-0">
                                <p> Delivering to </p>
                              </div>
                              <svg
                                width="16"
                                height="16"
                                stroke="#3d4152"
                                strokeWidth="1"
                                fill="#3d4152"
                                className="scale-90 mt-1 md:mt-0 md:scale-95"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                                />
                              </svg>
                            </span>
                            <p className="text-secondaryFontColor -translate-y-0 md:-translate-y-1 text-sm md:text-lg">
                              Click to add address
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      onClick={() => navigate("/profile")}
                      className="cursor-pointer lg:mb-3"
                    >
                      {currentUser ? (
                        <div>
                          {currentUser.photoURL ? (
                            <img
                              src={currentUser.photoURL}
                              alt=""
                              className=" border-solid rounded-[100%] w-11 h-11 p-[0.3rem] md:p-0 -translate-y-0 md:-translate-y-1"
                            />
                          ) : (
                            <h1 className="font-bold w-9 h-9 bg-slate-200 rounded-full flex items-center justify-center text-2xl text-primaryFontColor">
                              {currentUser.email.charAt(0).toUpperCase()}
                            </h1>
                          )}
                        </div>
                      ) : (
                        <div className="border p-2 lg:p-3 mr-2 rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#FC6011"
                            width="18"
                            height="18"
                            viewBox="0 0 20 20"
                            aria-labelledby="icon-svg-title- icon-svg-desc-"
                            role="img"
                          >
                            <circle cx="10" cy="4.5" r="4.5"></circle>
                            <path d="M18.18,14.73c-2.35-4.6-6.49-4.48-8.15-4.48s-5.86-.12-8.21,4.48C.59,17.14,1.29,20,4.54,20H15.46C18.71,20,19.41,17.14,18.18,14.73Z"></path>
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/*  */}
            <AnimatePresence mode="wait">
              {React.cloneElement(element, { key: location.pathname })}
            </AnimatePresence>
          </div>

          <div className="hidden fixed right-0 w-1/4 lg:block lg:border-l-2 h-lvh overflow-y-auto">
            {/* Top image title */}
            <div className="bg-white w-full sticky top-0 opacity-95 border-b">
              <div className="flex items-center gap-5 justify-stretch mt-5 m-5">
                <span className="flex gap-5 mt-5">
                  <img
                    className=""
                    imageurl="https://media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_100,h_100,c_fill/xvyrclhxftulsglktaek"
                    height="50"
                    width="50"
                    imageid=""
                    alt="img renderer"
                    src="https://media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_100,h_100,c_fill/xvyrclhxftulsglktaek"
                  />
                </span>
                <div className="flex flex-col mt-5">
                  <h1 className="lg:text-xl">
                    <span className="text-mainColor">Eato</span>Earn
                  </h1>
                  <p className="text-secondaryFontColor">Srinagar</p>
                  <span className="w-9 h-1 border-b-2 border-black" />
                </div>
              </div>
            </div>

            <div>
              {cartItems !== null && cartItems.length > 0 ? (
                <div>
                  <div className="m-5 flex flex-col gap-5">
                    {cartItems.map((item, index) => (
                      <div
                        className="flex items-center justify-between"
                        key={index}
                      >
                        <div className="flex items-center gap-2">
                          <svg
                            width="76"
                            height="76"
                            viewBox="0 0 76 76"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-4 h-4 min-w-4"
                          >
                            <rect
                              x="3"
                              y="3"
                              width="70"
                              height="70"
                              stroke="#0F8A65"
                              strokeWidth="5"
                            />
                            <circle
                              cx="37.5"
                              cy="38.5"
                              r="17.5"
                              fill="#0F8A65"
                            />
                          </svg>
                          <div className="flex flex-col">
                            <p className="text-wrap text-primaryFontColor">
                              {item.food_name}
                            </p>
                            <p className="text-sm text-mainColor">{item.opt}</p>
                          </div>
                        </div>
                        <div className="flex items-center min-w-36 gap-2">
                          <div className="flex items-center border p-1">
                            <button
                              onClick={() => changeQty(item, false)}
                              className="transition-transform hover:scale-110"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#4A4B4D"
                                className="bi bi-dash"
                                viewBox="0 0 16 16"
                              >
                                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" />
                              </svg>
                            </button>

                            <input
                              type="text"
                              value={item.qty}
                              readOnly
                              className="w-12 text-center text-mainColor"
                            />

                            <button
                              onClick={() => changeQty(item, true)}
                              className="focus:outline-none transition-transform hover:scale-110"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#FC6011"
                                className="bi bi-plus-lg"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                                />
                              </svg>
                            </button>
                          </div>
                          <p className="text-primaryFontColor">
                            &#8377;{item.cost * item.qty}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* Suggestion */}
                  <div>
                    <div className="relative h-12 m-5 flex bg-[#f9f9f9] items-center p-5 gap-3">
                      <svg
                        className="w-3 h-3 absolute top-3 mt-[1px]"
                        viewBox="0 0 32 32"
                      >
                        <path d="M7.031 14c3.866 0 7 3.134 7 7s-3.134 7-7 7-7-3.134-7-7l-0.031-1c0-7.732 6.268-14 14-14v4c-2.671 0-5.182 1.040-7.071 2.929-0.364 0.364-0.695 0.751-0.995 1.157 0.357-0.056 0.724-0.086 1.097-0.086zM25.031 14c3.866 0 7 3.134 7 7s-3.134 7-7 7-7-3.134-7-7l-0.031-1c0-7.732 6.268-14 14-14v4c-2.671 0-5.182 1.040-7.071 2.929-0.364 0.364-0.695 0.751-0.995 1.157 0.358-0.056 0.724-0.086 1.097-0.086z"></path>
                      </svg>
                      <textarea
                        onInput={(e) => {
                          e.currentTarget.style.height = "auto";
                          e.currentTarget.parentElement.style.height = `auto`;
                          e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
                          e.currentTarget.parentElement.style.height = `${e.currentTarget.scrollHeight}px`;
                        }}
                        onChange={(e) => setSuggestion(e.currentTarget.value)}
                        placeholder="Any suggestions? We will pass it on..."
                        rows="1"
                        className="bg-transparent appearance-none outline-none ml-5 text-sm resize-none py-2 w-full"
                        maxLength="140"
                      ></textarea>
                    </div>
                  </div>

                  {/* Bill */}
                  <div className="m-5">
                    <h1 className="font-bold">Bill Details</h1>
                    <div className="flex flex-col text-primaryFontColor gap-2 text-sm">
                      <div className="flex items-center justify-between">
                        <p> Item Total </p>
                        <p>
                          &#8377;
                          {getTotalCart()}
                        </p>
                      </div>

                      <div className="flex items-center justify-between">
                        <p> Delivery partner fee </p>
                        <p> &#8377;{deliveryCharges} </p>
                      </div>
                      <hr />
                      <div className="flex items-center justify-between">
                        <p> Platform fee </p>
                        <p> &#8377;3 </p>
                      </div>
                      <div className="w-full h-[2px] bg-black" />
                      <div className="flex items-center justify-between font-bold py-3">
                        <p> TO PAY </p>
                        <p> &#8377;{getTotalCart() + deliveryCharges + 3} </p>
                      </div>
                    </div>
                  </div>

                  {/* Address */}
                  {currentUser && saved && (
                    <div className="m-5">
                      <h1 className="font-bold">Delivering to</h1>
                      <div className="flex flex-col text-primaryFontColor gap-2 text-sm border p-2 rounded-md">
                        <h1> {saved.address_type} </h1>
                        <div>
                          <p> {saved.address_detected} </p>
                          <p>{saved.address_complete}</p>
                        </div>
                      </div>
                    </div>
                  )}

                  {!saved && (
                    <p
                      className="mx-5 my-3 text-mainColor text-center"
                      onClick={() => navigate("/addresses")}
                    >
                      Please add an address to continue
                    </p>
                  )}

                  {!availablity.foods && (
                    <p className="mx-5 my-3 text-mainColor text-center">
                      OPENING SOON
                    </p>
                  )}

                  {/* Checkout */}
                  <div className="w-full flex items-center justify-center pb-10">
                    <input
                      onClick={() =>
                        currentUser
                          ? saved
                            ? handleCheckout()
                            : navigate("/addresses")
                          : navigate("/login?redirect=cart")
                      }
                      type="button"
                      disabled={!availablity.foods}
                      value="Checkout"
                      className="bg-mainColor hover:bg-[#fea531] text-white py-2 px-4 rounded-md w-full mx-5 focus:outline-none focus:shadow-outline-blue md:shadow-none md:block disabled:bg-primaryFontColor"
                    ></input>
                  </div>
                </div>
              ) : (
                <div className="p-10 flex flex-col gap-5 items-center">
                  <img src={cart_empty} alt="" />
                  <h1 className="text-primaryFontColor font-bold">
                    Your cart is empty
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
