import { useAuth } from "../auth/FirebaseAuth";
import { useEffect, useState } from "react";
import {
  ADDRESS_DEFAULT,
  CART,
  ORDER_FOOD,
  PAY_DATA,
} from "../../app/constants";
import { els } from "../../app/utils";
import cart_empty from "../assets/cart/cart_empty.avif";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

export function Cart({ availablity }) {
  const { currentUser } = useAuth();

  // state here
  const [cartItems, setCartItems] = useState(JSON.parse(els.get(CART)));
  const [deliveryCharges, setDeliveryCharges] = useState(50);
  const [suggestion, setSuggestion] = useState("");

  const saved = JSON.parse(els.get(ADDRESS_DEFAULT));

  useEffect(() => {
    window.scroll(0, 0);

    if (currentUser && saved) {
      fetch(
        `${process.env.REACT_APP_SERVER}/pincodes/${saved.address_pincode}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json", // Specify the content type as JSON
          },
        }
      )
        .then((data) => data.json())
        .then((pincode_data) => {
          setDeliveryCharges(pincode_data.charges);
        })
        .catch((err) => {});
    }
  }, [saved, currentUser]);

  const navigate = useNavigate();

  const changeQty = (item, add) => {
    if (add) {
      const newCart = cartItems.map((it) =>
        it.id === item.id
          ? {
              ...item,
              qty: it.qty + 1,
            }
          : it
      );
      setCartItems(newCart);
      els.set(CART, JSON.stringify(newCart));
    } else {
      if (item.qty === 1) {
        const filteredCart = cartItems.filter((it) => it.id !== item.id);
        setCartItems(filteredCart);
        els.set(CART, JSON.stringify(filteredCart));
      } else {
        const newCart = cartItems.map((it) =>
          it.id === item.id
            ? {
                ...item,
                qty: it.qty - 1,
              }
            : it
        );
        setCartItems(newCart);
        els.set(CART, JSON.stringify(newCart));
      }
    }
  };

  const getTotalCart = () => {
    let sum = 0;
    cartItems.forEach((item) => {
      sum += item.cost * item.qty;
    });
    return sum;
  };

  const handleCheckout = () => {
    const order = {
      user_uid: currentUser && currentUser.uid,
      billed_to:
        (currentUser && currentUser.displayName) ||
        (currentUser && currentUser.email.split("@")[0]),
      total: getTotalCart() + deliveryCharges + 3,
      delivery_charges: deliveryCharges,
      platform_fee: 3,
      items: cartItems.map((it) => {
        return {
          food_thumb_url: it.food_thumb_url,
          food_name: it.food_name,
          food_price: it.cost,
          food_qty: it.qty,
          food_type: it.opt,
        };
      }),
      suggestion: suggestion,
      address: saved,
    };

    fetch(`${process.env.REACT_APP_SERVER}/pay`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json", // Specify the content type as JSON
      },
      body: JSON.stringify({
        pay_type: ORDER_FOOD,
        user_uid: currentUser && currentUser.uid,
        food_order: order,
      }),
    })
      .then((data) => data.json())
      .then((json_data) => {
        if (json_data.pay_page_url) {
          els.set(
            PAY_DATA,
            JSON.stringify({
              merchant_transaction_id: json_data.merchant_transaction_id,
              pay_type: ORDER_FOOD,
              user_uid: currentUser.uid,
              code: json_data.code,
              success: json_data.success,
            })
          );
          // window.open(json_data.pay_page_url, "_blank");
          window.location.href = json_data.pay_page_url;
        }
      })
      .catch((err) => {
        // setModalState({
        //   title: "Error",
        //   message: "Unkown error occurred, please try again",
        // });
        // setModalOpen(true);
        window.alert("error");
      });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.1 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.1 } } }}
      className="pb-20"
    >
      <div>
        {/* Menu */}
        <div>
          {/* HR */}
          <div className="flex items-center mx-3 mt-7">
            <hr className="flex-grow border-t border-gray-300" />
            <span className="px-3 text-primaryFontColor font-semibold">
              Cart Items
            </span>
            <hr className="flex-grow border-t border-gray-300" />
          </div>
          {cartItems !== null && cartItems.length > 0 ? (
            <div>
              <div className="m-5 flex flex-col gap-5">
                {cartItems.map((item, index) => (
                  <div
                    className="flex items-center justify-between"
                    key={index}
                  >
                    <div className="flex items-center gap-2">
                      <svg
                        width="76"
                        height="76"
                        viewBox="0 0 76 76"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4 min-w-4"
                      >
                        <rect
                          x="3"
                          y="3"
                          width="70"
                          height="70"
                          stroke="#0F8A65"
                          strokeWidth="5"
                        />
                        <circle cx="37.5" cy="38.5" r="17.5" fill="#0F8A65" />
                      </svg>
                      <div className="flex flex-col">
                        <p className="text-wrap text-primaryFontColor">
                          {item.food_name}
                        </p>
                        <p className="text-sm text-mainColor">
                          {" • " + item.opt}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center min-w-36 gap-2">
                      <div className="flex items-center border rounded-md p-1">
                        <button
                          onClick={() => changeQty(item, false)}
                          className="transition-transform hover:scale-110"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#4A4B4D"
                            className="bi bi-dash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" />
                          </svg>
                        </button>

                        <input
                          type="text"
                          value={item.qty}
                          readOnly
                          className="w-12 text-center text-mainColor"
                        />

                        <button
                          onClick={() => changeQty(item, true)}
                          className="focus:outline-none transition-transform hover:scale-110"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#FC6011"
                            className="bi bi-plus-lg"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                            />
                          </svg>
                        </button>
                      </div>
                      <p className="text-primaryFontColor">
                        &#8377;{item.cost * item.qty}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              {/* Suggestion */}
              <div>
                <div className="relative h-12 m-5 flex bg-[#f9f9f9] items-center p-5 gap-3">
                  <svg
                    className="w-3 h-3 absolute top-3 mt-[1px]"
                    viewBox="0 0 32 32"
                  >
                    <path d="M7.031 14c3.866 0 7 3.134 7 7s-3.134 7-7 7-7-3.134-7-7l-0.031-1c0-7.732 6.268-14 14-14v4c-2.671 0-5.182 1.040-7.071 2.929-0.364 0.364-0.695 0.751-0.995 1.157 0.357-0.056 0.724-0.086 1.097-0.086zM25.031 14c3.866 0 7 3.134 7 7s-3.134 7-7 7-7-3.134-7-7l-0.031-1c0-7.732 6.268-14 14-14v4c-2.671 0-5.182 1.040-7.071 2.929-0.364 0.364-0.695 0.751-0.995 1.157 0.358-0.056 0.724-0.086 1.097-0.086z"></path>
                  </svg>
                  <textarea
                    onInput={(e) => {
                      e.currentTarget.style.height = "auto";
                      e.currentTarget.parentElement.style.height = `auto`;
                      e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
                      e.currentTarget.parentElement.style.height = `${e.currentTarget.scrollHeight}px`;
                    }}
                    placeholder="Any suggestions? We will pass it on..."
                    rows="1"
                    onChange={(e) => setSuggestion(e.currentTarget.value)}
                    className="bg-transparent appearance-none outline-none ml-5 text-sm resize-none py-2 w-full"
                    maxLength="140"
                  ></textarea>
                </div>
              </div>

              {/* Bill */}
              <div className="m-5">
                <h1 className="font-bold text-primaryFontColor">
                  Bill Details
                </h1>
                <div className="flex flex-col text-primaryFontColor gap-2 text-sm">
                  <div className="flex items-center justify-between">
                    <p> Item Total </p>
                    <p>
                      &#8377;
                      {getTotalCart()}
                    </p>
                  </div>

                  <div className="flex items-center justify-between">
                    <p> Delivery partner fee </p>
                    <p> &#8377;{deliveryCharges} </p>
                  </div>
                  <hr />
                  <div className="flex items-center justify-between">
                    <p> Platform fee </p>
                    <p> &#8377;3 </p>
                  </div>
                  <div className="w-full h-[2px] bg-black" />
                  <div className="flex items-center justify-between font-bold py-3">
                    <p> TO PAY </p>
                    <p> &#8377;{getTotalCart() + deliveryCharges + 3} </p>
                  </div>
                </div>
              </div>

              {/* Address */}
              {currentUser && saved && (
                <div className="m-5">
                  <h1 className="font-bold text-primaryFontColor">
                    Delivering to
                  </h1>
                  <div className="flex flex-col text-primaryFontColor gap-2 text-sm border p-2 rounded-md">
                    <h1>
                      {saved.address_type.charAt(0).toUpperCase() +
                        saved.address_type.slice(1)}
                    </h1>
                    <div>
                      <p> {saved.address_detected} </p>
                      <p>{saved.address_complete}</p>
                    </div>
                  </div>
                </div>
              )}

              {!saved && (
                <p
                  className="mx-5 my-3 text-mainColor text-center"
                  onClick={() => navigate("/addresses")}
                >
                  Please add an address to continue
                </p>
              )}

              {!availablity.foods && (
                <p className="mx-5 my-3 text-mainColor text-center">
                  OPENING SOON
                </p>
              )}

              {/* <div className="w-full flex flex-col items-center justify-center pb-10">
                <p className="text-xl py-2"> Scan to pay </p>
                <img
                  alt=""
                  className="w-2/3 h-2/3 md:w-1/3 md:h-1/3"
                  src={mqr}
                ></img>
              </div> */}

              <div className="w-full flex items-center justify-center pb-10">
                <input
                  onClick={() =>
                    currentUser
                      ? saved
                        ? handleCheckout()
                        : navigate("/addresses")
                      : navigate("/login?redirect=cart")
                  }
                  type="button"
                  disabled={!availablity.foods}
                  value="Checkout"
                  className="bg-mainColor hover:bg-[#fea531] text-white py-2 px-4 rounded-md w-full mx-5 focus:outline-none focus:shadow-outline-blue md:shadow-none md:block disabled:bg-primaryFontColor"
                ></input>
              </div>
            </div>
          ) : (
            <div className="p-10 flex flex-col gap-5 items-center">
              <img src={cart_empty} alt="" />
              <h1 className="text-primaryFontColor font-bold">
                Your cart is empty
              </h1>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}
