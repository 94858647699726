import { useRef, useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../features/firebase/firebase";
import { useNavigate } from "react-router-dom";

export function ForgetPassword() {
  // State here ...
  const [resetLinkSent, setResetLinkSent] = useState(false);
  const [resetError, setResetError] = useState(false);

  // Refs ...
  const emailRef = useRef();
  const navigate = useNavigate();

  const recoverPassword = (e) => {
    e.preventDefault();
    setResetError(false);
    setResetLinkSent(false);

    console.log("RESET FOR: ", emailRef.current.value);
    sendPasswordResetEmail(auth, emailRef.current.value)
      .then(() => {
        setResetLinkSent(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        console.log(errorCode);
        console.log(errorMessage);
        setResetError(true);
      });
  };

  return (
    <div className="flex w-full items-center justify-center pb-20">
      <div className="w-full md:m-20 md:mt-10 md:rounded-xl md:p-5">
        <div className="m-10 md:mt-0">
          <h1 className="text-4xl text-center text-primaryFontColor md:text-4xl lg:text-4xl">
            Reset Password
          </h1>
          <p className="text-center text-md text-secondaryFontColor pt-3 mt-5 md:mt-2 md:text-md lg:text-lg">
            Please enter your email to receive a link to create a new password
            via email
          </p>
        </div>
        <div className="m-10">
          <form
            className="w-full flex flex-col items-center gap-7"
            onSubmit={(e) => recoverPassword(e)}
          >
            <input
              className="h-12 w-full shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] outline-none border p-5 rounded-2xl text-primaryFontColor md:text-lg lg:text-lg lg:p-5 lg:h-10"
              type="email"
              required
              ref={emailRef}
              placeholder="Your Email"
            ></input>

            <p
              className="text-center text-md text-green-600 m-3 lg:text-lg"
              style={{
                display: resetLinkSent ? "block" : "none",
              }}
            >
              Password reset link sent, please click on the link in the email to
              reset your password
            </p>

            <p
              className="text-center text-md text-red-600 m-3 mt-0 lg:text-lg"
              style={{
                display: resetError ? "block" : "none",
              }}
            >
              Email address doesn't exist, please Sign Up
            </p>

            <input
              className="h-12 w-full bg-mainColor p-0 rounded-full text-white md:text-xl md:h-10 lg:text-lg"
              type="submit"
              value="Reset"
            ></input>

            <p
              onClick={() => navigate("/login")}
              className="text-center text-mainColor font-bold cursor-pointer"
            >
              Login Instead
            </p>
          </form>
          <div className="flex flex-row w-full justify-center left-0 items-center mt-16">
            <p
              className="text-secondaryFontColor cursor-pointer"
              onClick={() => navigate("/signup")}
            >
              Don't have an Account?
              <span className="font-bold text-mainColor"> Sign Up </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
