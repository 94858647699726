import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { GoogleAuthProvider } from "firebase/auth";

// Firebase Config from Console
const firebaseConfig = {
  apiKey: "AIzaSyAfeJ0xHv28zkVKBWD5iTlZ_f2yMYLthqk",
  authDomain: "eatoearn-58daf.firebaseapp.com",
  projectId: "eatoearn-58daf",
  storageBucket: "eatoearn-58daf.appspot.com",
  messagingSenderId: "1059994586678",
  appId: "1:1059994586678:web:9955d1b862056d51495297",
  measurementId: "G-536818H4XE",
};

const app = initializeApp(firebaseConfig);
const provider_ = new GoogleAuthProvider();

provider_.setCustomParameters({
  login_hint: "user@example.com",
});

export const db = getFirestore(app);
export const provider = provider_;
export const auth = getAuth(app);
export const storage = getStorage(app);
export default app;
