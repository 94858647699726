import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import ReactImageAppear from "react-image-appear";
import { els } from "../../app/utils";
import { FEATURED_DATA } from "../../app/constants";

export function Menu() {
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState(
    JSON.parse(els.get(FEATURED_DATA)) || []
  );

  useEffect(() => {
    // Featured
    fetch(`${process.env.REACT_APP_SERVER}/featured`, {
      method: "GET",
      mode: "cors",
    })
      .then((data) => data.json())
      .then((featured_) => {
        setMenuItems(featured_);
      })
      .catch((err) => {});
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.1 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.1 } } }}
    >
      <div>
        {/* Menu */}
        <div>
          {/* HR */}
          <div className="flex items-center mt-7 mx-3">
            <hr className="flex-grow border-t border-gray-300" />
            <span className="px-3 text-primaryFontColor font-semibold">
              Eat what makes you happy
            </span>
            <hr className="flex-grow border-t border-gray-300" />
          </div>

          {/* Food categories */}
          <div className="grid grid-cols-3 gap-5 mx-3 md:grid-cols-4 mt-3">
            {menuItems.map((it, index) => (
              <div className="flex flex-col items-center gap-1" key={index}>
                <ReactImageAppear
                  onClick={(e) => navigate(`/view?tag=${it.title}`)}
                  className="hover:scale-105 transition-transform rounded-full shadow-xl max-w-20 max-h-20 lg:max-w-28 lg:max-h-28"
                  src={it.featured_img_url}
                  animation="blurIn"
                  placeholderClass="rounded-full"
                  animationDuration="100ms"
                  placeholderStyle={{
                    backgroundColor: "white",
                  }}
                />
                <p className="text-[#6d6d6d] text-sm font-bold"> {it.title} </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
}
