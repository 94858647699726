// TermsAndConditions.js
import React, { useEffect } from "react";
import { motion } from "framer-motion";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.1 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.1 } } }}
      className="container mx-auto mt-8 p-4 pb-20"
    >
      <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
        <p className="mb-2">
          Welcome to EatoEarn! These terms and conditions outline the rules and
          regulations for the use of our website.
        </p>
        <p className="mb-2">
          By accessing this website, we assume you accept these terms and
          conditions. Do not continue to use EatoEarn if you do not agree to
          take all of the terms and conditions stated on this page.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">2. License</h2>
        <p className="mb-2">
          Unless otherwise stated, EatoEarn and/or its licensors own the
          intellectual property rights for all material on EatoEarn. All
          intellectual property rights are reserved.
        </p>
        <p className="mb-2">
          You may view and/or print pages from EatoEarn for your own personal
          use subject to restrictions set in these terms and conditions.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        <p className="mb-2">
          If you have any questions or concerns about our Terms and Conditions,
          please contact us at:
        </p>
        <p className="font-bold">support@eatoearn.com</p>
      </section>
    </motion.div>
  );
};

export default TermsAndConditions;
