import { useState, useEffect, useContext, createContext } from "react";
import { auth } from "../../features/firebase/firebase";
import { USER_LOGGED } from "../../app/constants";
import { els } from "../../app/utils";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(els.get(USER_LOGGED))
  );

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      (user) => {
        els.set(USER_LOGGED, JSON.stringify(user));
        setCurrentUser(user);
      },
      () => {
        els.remove(USER_LOGGED);
        setCurrentUser(null);
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  const value = {
    currentUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
