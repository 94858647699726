import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "@fontsource/metropolis";
import "@fontsource/metropolis/400.css";
import "@fontsource/metropolis/400-italic.css";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider, useAuth } from "./components/auth/FirebaseAuth";
import { CompleteProfile } from "./components/auth/Complete";

const root = ReactDOM.createRoot(document.getElementById("root"));

const PrivateRoute = () => {
  const { currentUser } = useAuth();
  if (currentUser && currentUser.emailVerified === false) {
    return <CompleteProfile />;
  }
  return <App />;
};

root.render(
  <AuthProvider>
    <Router>
      <PrivateRoute />
    </Router>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
