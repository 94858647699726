import { signOut } from "firebase/auth";
import { auth } from "../../features/firebase/firebase";
import { useAuth } from "../auth/FirebaseAuth";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

export function CustomerProfile({ userFetched = null, setUserFetched }) {
  const { currentUser } = useAuth();
  // state here

  const navigate = useNavigate();

  const handleLogout = (event) => {
    signOut(auth)
      .then(() => {
        setUserFetched(null);
        navigate("/");
      })
      .catch((error) => {
        window.alert(error.message);
      });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.1 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.1 } } }}
      className="pb-20 bg-[#f5f6fb] h-[100vh]"
    >
      <div className="pt-1">
        {/* Back nav */}
        <div className="m-3 mx-1" onClick={() => navigate("/")}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#3d4152"
            className="w-9 h-9"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
            />
          </svg>
        </div>

        {/* Profile */}
        {currentUser ? (
          <div className="m-3 bg-white rounded-2xl shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] flex items-center">
            <div className="p-3">
              <div>
                {currentUser && currentUser.photoURL ? (
                  <img
                    src={currentUser.photoURL}
                    alt=""
                    className="border-solid rounded-[100%] h-16 max-h-16 md:p-0"
                  />
                ) : (
                  <h1 className="font-bold w-16 h-16 bg-slate-200 rounded-full flex items-center justify-center text-2xl text-primaryFontColor">
                    {currentUser.email.charAt(0).toUpperCase()}
                  </h1>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <h1 className="text-xl">
                {currentUser && userFetched
                  ? userFetched.user_name ||
                    currentUser.email.charAt(0).toUpperCase() +
                      currentUser.email
                        .split("@")[0]
                        .slice(1)
                        .replace(/\d+/g, "")
                  : "Login"}
              </h1>
              <div className="">
                {currentUser && (
                  <div
                    className="flex items-center gap-1"
                    onClick={() => navigate("/settings")}
                  >
                    <p className="text-mainColor cursor-pointer">
                      Edit profile
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#FC6011"
                      className="w-3 h-3 mt-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            onClick={() => navigate("/login")}
            className="m-3 bg-white rounded-2xl cursor-pointer shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] flex items-center px-5"
          >
            <div className="border p-2 lg:p-3 mr-2 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#FC6011"
                width="18"
                height="18"
                viewBox="0 0 20 20"
                aria-labelledby="icon-svg-title- icon-svg-desc-"
                role="img"
              >
                <circle cx="10" cy="4.5" r="4.5"></circle>
                <path d="M18.18,14.73c-2.35-4.6-6.49-4.48-8.15-4.48s-5.86-.12-8.21,4.48C.59,17.14,1.29,20,4.54,20H15.46C18.71,20,19.41,17.14,18.18,14.73Z"></path>
              </svg>
            </div>
            <h1 className="p-5 text-mainColor"> Click to Login </h1>
          </div>
        )}

        {userFetched && userFetched.user_mode !== "admin" && (
          <div>
            {/* Fav & bag */}
            <div className="flex items-center mx-1">
              <div
                onClick={() => navigate("/cart")}
                className="bg-white m-3 ml-2 cursor-pointer mt-0 p-5 py-3 rounded-xl w-full shadow-[0px_0px_25px_-15px_rgba(0,0,0,.2)]"
              >
                <div className="bg-[#f4f4f4] rounded-full flex justify-center items-center w-9 h-9">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                  </svg>
                </div>
                <h1 className="mt-2"> Bag </h1>
              </div>
              <div
                onClick={() =>
                  currentUser ? navigate("/favourites") : navigate("/login")
                }
                className="bg-white m-3 mt-0 mr-2 p-5 cursor-pointer py-3 rounded-xl w-full shadow-[0px_0px_25px_-15px_rgba(0,0,0,.2)]"
              >
                <div className="bg-[rgb(244,244,244)] rounded-full flex justify-center items-center w-9 h-9">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                  </svg>
                </div>
                <h1 className="mt-2"> Favourites </h1>
              </div>
            </div>

            {/* Myorders */}
            <div
              onClick={() =>
                currentUser ? navigate("/history") : navigate("/login")
              }
              className="mx-3 bg-white rounded-xl px-3 cursor-pointer shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)]"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center justify-start gap-3 p-1">
                  <div className="bg-[#f4f4f4] rounded-full p-2">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 7.67001V6.70001C7.5 4.45001 9.31 2.24001 11.56 2.03001C14.24 1.77001 16.5 3.88001 16.5 6.51001V7.89001"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.00001 22H15C19.02 22 19.74 20.39 19.95 18.43L20.7 12.43C20.97 9.99 20.27 8 16 8H8.00001C3.73001 8 3.03001 9.99 3.30001 12.43L4.05001 18.43C4.26001 20.39 4.98001 22 9.00001 22Z"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.4955 12H15.5045"
                        stroke="#292D32"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.49451 12H8.50349"
                        stroke="#292D32"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <h1 className="py-3"> My orders </h1>
                </div>
                <svg
                  fill="#E8E8E8"
                  width="24"
                  height="24"
                  viewBox="0 0 20 20"
                  aria-labelledby="icon-svg-title- icon-svg-desc-"
                  role="img"
                  className="sc-rbbb40-0 fmIpur"
                >
                  <title>chevron-right</title>
                  <path d="M6.98 15.94c-0.3-0.28-0.3-0.76 0-1.060l4.46-4.46-4.46-4.48c-0.3-0.28-0.3-0.76 0-1.060s0.76-0.28 1.060 0l5 5c0.28 0.3 0.28 0.78 0 1.060l-5 5c-0.3 0.3-0.78 0.3-1.060 0z"></path>
                </svg>
              </div>
            </div>

            {/* Food orders */}
            <div className="m-3 bg-white px-0 rounded-xl shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)]">
              <div className="flex overflow-hidden">
                <span className="h-6 border-l border-2 rounded-2xl mt-3 border-mainColor" />
                <h1 className="px-3 mt-3"> Food Orders </h1>
              </div>
              <div
                onClick={() =>
                  currentUser ? navigate("/recent") : navigate("/login")
                }
                className="flex cursor-pointer items-center justify-between px-3"
              >
                <div className="flex items-center gap-3">
                  <div className="bg-[#f4f4f4] rounded-full p-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z" />
                      <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z" />
                      <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5" />
                    </svg>
                  </div>
                  <h1 className="py-3"> Recently Bought </h1>
                  <hr />
                </div>
                <svg
                  fill="#E8E8E8"
                  width="24"
                  height="24"
                  viewBox="0 0 20 20"
                  aria-labelledby="icon-svg-title- icon-svg-desc-"
                  role="img"
                  className="sc-rbbb40-0 fmIpur"
                >
                  <title>chevron-right</title>
                  <path d="M6.98 15.94c-0.3-0.28-0.3-0.76 0-1.060l4.46-4.46-4.46-4.48c-0.3-0.28-0.3-0.76 0-1.060s0.76-0.28 1.060 0l5 5c0.28 0.3 0.28 0.78 0 1.060l-5 5c-0.3 0.3-0.78 0.3-1.060 0z"></path>
                </svg>
              </div>

              <hr className="ml-14" />

              <div
                onClick={() =>
                  currentUser ? navigate("/addresses") : navigate("/login")
                }
                className="flex items-center justify-between px-3 cursor-pointer"
              >
                <div className="flex items-center gap-3">
                  <div className="bg-[#f4f4f4] rounded-full p-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0"
                      />
                      <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2" />
                      <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z" />
                    </svg>
                  </div>
                  <h1 className="py-3"> Addresses </h1>
                </div>
                <svg
                  fill="#E8E8E8"
                  width="24"
                  height="24"
                  viewBox="0 0 20 20"
                  aria-labelledby="icon-svg-title- icon-svg-desc-"
                  role="img"
                  className="sc-rbbb40-0 fmIpur"
                >
                  <title>chevron-right</title>
                  <path d="M6.98 15.94c-0.3-0.28-0.3-0.76 0-1.060l4.46-4.46-4.46-4.48c-0.3-0.28-0.3-0.76 0-1.060s0.76-0.28 1.060 0l5 5c0.28 0.3 0.28 0.78 0 1.060l-5 5c-0.3 0.3-0.78 0.3-1.060 0z"></path>
                </svg>
              </div>
            </div>

            {/* Money */}
            {userFetched && userFetched.user_mode === "affiliate" && (
              <div className="m-3 bg-white px-0 rounded-xl shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)]">
                <div className="flex overflow-hidden">
                  <span className="h-6 border-l border-2 rounded-2xl mt-3 border-mainColor" />
                  <h1 className="px-3 mt-3"> Money </h1>
                </div>
                <div
                  onClick={() =>
                    currentUser ? navigate("/plan") : navigate("/login")
                  }
                  className="flex items-center justify-between px-3 cursor-pointer"
                >
                  <div className="flex items-center gap-3">
                    <div className="bg-[#f4f4f4] rounded-full p-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
                        <path d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z" />
                      </svg>
                    </div>
                    <h1 className="py-3"> Affiliate Profile </h1>
                    <hr />
                  </div>
                  <svg
                    fill="#E8E8E8"
                    width="24"
                    height="24"
                    viewBox="0 0 20 20"
                    aria-labelledby="icon-svg-title- icon-svg-desc-"
                    role="img"
                    className="sc-rbbb40-0 fmIpur"
                  >
                    <title>chevron-right</title>
                    <path d="M6.98 15.94c-0.3-0.28-0.3-0.76 0-1.060l4.46-4.46-4.46-4.48c-0.3-0.28-0.3-0.76 0-1.060s0.76-0.28 1.060 0l5 5c0.28 0.3 0.28 0.78 0 1.060l-5 5c-0.3 0.3-0.78 0.3-1.060 0z"></path>
                  </svg>
                </div>

                <hr className="ml-14" />

                <div
                  onClick={() =>
                    currentUser ? navigate("/income") : navigate("/login")
                  }
                  className="flex items-center justify-between px-3 cursor-pointer"
                >
                  <div className="flex  items-center gap-3">
                    <div className="bg-[#f4f4f4] rounded-full p-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518z" />
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11m0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12" />
                      </svg>
                    </div>
                    <h1 className="py-3"> Affiliate Income </h1>
                  </div>
                  <svg
                    fill="#E8E8E8"
                    width="24"
                    height="24"
                    viewBox="0 0 20 20"
                    aria-labelledby="icon-svg-title- icon-svg-desc-"
                    role="img"
                    className="sc-rbbb40-0 fmIpur"
                  >
                    <title>chevron-right</title>
                    <path d="M6.98 15.94c-0.3-0.28-0.3-0.76 0-1.060l4.46-4.46-4.46-4.48c-0.3-0.28-0.3-0.76 0-1.060s0.76-0.28 1.060 0l5 5c0.28 0.3 0.28 0.78 0 1.060l-5 5c-0.3 0.3-0.78 0.3-1.060 0z"></path>
                  </svg>
                </div>
                <hr />
              </div>
            )}
          </div>
        )}

        {/* More */}
        <div className="m-3 bg-white px-0 rounded-xl shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)]">
          <div className="flex overflow-hidden">
            <span className="h-6 border-l border-2 rounded-2xl mt-3 border-mainColor" />
            <h1 className="px-3 mt-3"> More </h1>
          </div>
          <div
            onClick={() => navigate("/about")}
            className="flex items-center justify-between px-3 cursor-pointer"
          >
            <div className="flex items-center gap-3">
              <div className="bg-[#f4f4f4] rounded-full p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                </svg>
              </div>
              <h1 className="py-3"> About us </h1>
              <hr />
            </div>
            <svg
              fill="#E8E8E8"
              width="24"
              height="24"
              viewBox="0 0 20 20"
              aria-labelledby="icon-svg-title- icon-svg-desc-"
              role="img"
              className="sc-rbbb40-0 fmIpur"
            >
              <title>chevron-right</title>
              <path d="M6.98 15.94c-0.3-0.28-0.3-0.76 0-1.060l4.46-4.46-4.46-4.48c-0.3-0.28-0.3-0.76 0-1.060s0.76-0.28 1.060 0l5 5c0.28 0.3 0.28 0.78 0 1.060l-5 5c-0.3 0.3-0.78 0.3-1.060 0z"></path>
            </svg>
          </div>

          <hr className="ml-14" />

          <div
            onClick={() => navigate("/feedback")}
            className="flex items-center justify-between px-3 cursor-pointer"
          >
            <div className="flex items-center gap-3">
              <div className="bg-[#f4f4f4] rounded-full p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                  <path
                    fillRule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                  />
                </svg>
              </div>
              <h1 className="py-3"> Feedback </h1>
            </div>
            <svg
              fill="#E8E8E8"
              width="24"
              height="24"
              viewBox="0 0 20 20"
              aria-labelledby="icon-svg-title- icon-svg-desc-"
              role="img"
              className="sc-rbbb40-0 fmIpur"
            >
              <title>chevron-right</title>
              <path d="M6.98 15.94c-0.3-0.28-0.3-0.76 0-1.060l4.46-4.46-4.46-4.48c-0.3-0.28-0.3-0.76 0-1.060s0.76-0.28 1.060 0l5 5c0.28 0.3 0.28 0.78 0 1.060l-5 5c-0.3 0.3-0.78 0.3-1.060 0z"></path>
            </svg>
          </div>
          <hr className="ml-14" />
          <div
            onClick={() => navigate("/return-refund-policy")}
            className="flex items-center justify-between px-3 cursor-pointer"
          >
            <div className="flex items-center gap-3">
              <div className="bg-[#f4f4f4] rounded-full p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.5 5.5a.5.5 0 0 0-1 0v.634l-.549-.317a.5.5 0 1 0-.5.866L6 7l-.549.317a.5.5 0 1 0 .5.866l.549-.317V8.5a.5.5 0 1 0 1 0v-.634l.549.317a.5.5 0 1 0 .5-.866L8 7l.549-.317a.5.5 0 1 0-.5-.866l-.549.317zm-2 4.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z" />
                  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                </svg>
              </div>
              <h1 className="py-3"> Refund &amp; Return Policy </h1>
            </div>
            <svg
              fill="#E8E8E8"
              width="24"
              height="24"
              viewBox="0 0 20 20"
              aria-labelledby="icon-svg-title- icon-svg-desc-"
              role="img"
              className="sc-rbbb40-0 fmIpur"
            >
              <title>chevron-right</title>
              <path d="M6.98 15.94c-0.3-0.28-0.3-0.76 0-1.060l4.46-4.46-4.46-4.48c-0.3-0.28-0.3-0.76 0-1.060s0.76-0.28 1.060 0l5 5c0.28 0.3 0.28 0.78 0 1.060l-5 5c-0.3 0.3-0.78 0.3-1.060 0z"></path>
            </svg>
          </div>
          <hr className="ml-14" />
          <div
            onClick={() => navigate("/terms-services")}
            className="flex items-center justify-between px-3 cursor-pointer "
          >
            <div className="flex items-center gap-3">
              <div className="bg-[#f4f4f4] rounded-full p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z" />
                  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                </svg>
              </div>
              <h1 className="py-3"> Terms &amp; Conditions </h1>
            </div>
            <svg
              fill="#E8E8E8"
              width="24"
              height="24"
              viewBox="0 0 20 20"
              aria-labelledby="icon-svg-title- icon-svg-desc-"
              role="img"
              className="sc-rbbb40-0 fmIpur"
            >
              <title>chevron-right</title>
              <path d="M6.98 15.94c-0.3-0.28-0.3-0.76 0-1.060l4.46-4.46-4.46-4.48c-0.3-0.28-0.3-0.76 0-1.060s0.76-0.28 1.060 0l5 5c0.28 0.3 0.28 0.78 0 1.060l-5 5c-0.3 0.3-0.78 0.3-1.060 0z"></path>
            </svg>
          </div>
          <hr className="ml-14" />
          <div
            onClick={() => navigate("/shipping-policy")}
            className="flex items-center justify-between px-3 cursor-pointer "
          >
            <div className="flex items-center gap-3">
              <div className="bg-[#f4f4f4] rounded-full p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z" />
                  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                </svg>
              </div>
              <h1 className="py-3"> Shipping Policy </h1>
            </div>
            <svg
              fill="#E8E8E8"
              width="24"
              height="24"
              viewBox="0 0 20 20"
              aria-labelledby="icon-svg-title- icon-svg-desc-"
              role="img"
              className="sc-rbbb40-0 fmIpur"
            >
              <title>chevron-right</title>
              <path d="M6.98 15.94c-0.3-0.28-0.3-0.76 0-1.060l4.46-4.46-4.46-4.48c-0.3-0.28-0.3-0.76 0-1.060s0.76-0.28 1.060 0l5 5c0.28 0.3 0.28 0.78 0 1.060l-5 5c-0.3 0.3-0.78 0.3-1.060 0z"></path>
            </svg>
          </div>
        </div>

        {/* Logout */}
        {currentUser && (
          <div className="flex items-center justify-center mt-10 mb-20 pb-20 ">
            <input
              onClick={() => handleLogout()}
              type="button"
              value="SIGN OUT"
              className="px-16 p-3 text-mainColor font-bold cursor-pointer"
            ></input>
          </div>
        )}
      </div>
    </motion.div>
  );
}
