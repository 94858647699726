import { useEffect, useRef, useState } from "react";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { auth } from "../../features/firebase/firebase";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./FirebaseAuth";
import { COMMAND_GET } from "../../app/constants";
import Modal from "../utils/Modal";

export function Login() {
  // State here ...
  const [error, setError] = useState(false);
  // const [userError, setUserError] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalState, setModalState] = useState({});

  const { currentUser } = useAuth();
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) navigate("/");
  }, [currentUser, navigate]);

  // const logWithGoogle = (e) => {
  //   setUserError(false);

  //   signInWithPopup(auth, provider)
  //     .then((result) => {
  //       // This gives you a Google Access Token. You can use it to access the Google API.
  //       // const credential = GoogleAuthProvider.credentialFromResult(result);
  //       // const token = credential.accessToken;
  //       // The signed-in user info.
  //       const user = result.user;
  //       // UPDATE in db
  //       fetch(`${process.env.REACT_APP_SERVER}/user`, {
  //         method: "POST",
  //         mode: "cors",
  //         body: JSON.stringify({
  //           profile: {
  //             user_uid: user.uid,
  //             is_login: true,
  //             user_profile: {
  //               user_email: user.email,
  //             },
  //           },
  //           command: COMMAND_UPDATE,
  //         }),
  //       })
  //         .then((resp) => resp.json())
  //         .then((resp) => {
  //           if (resp.user_mode === "affiliate" && !resp.user_active_plan_id) {
  //             navigate("/plan");
  //           }
  //         })
  //         .catch((err) => {
  //           // console.log("LOG-UPDATE-ERROR: ", err);
  //         });
  //     })
  //     .catch((error) => {
  //       // const errorCode = error.code;
  //       // const errorMessage = error.message;
  //       // ..
  //       // console.log("FAILURE SIGNIN: ", errorCode, errorMessage);
  //     });
  // };

  const logWithEmail = (e) => {
    e.preventDefault();
    setError(false);
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const params = new URLSearchParams(window.location.search);

    // Authenticate
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            fetch(`${process.env.REACT_APP_SERVER}/user`, {
              method: "POST",
              mode: "cors",
              body: JSON.stringify({
                profile: {
                  user_uid: userCredential.user.uid,
                },
                command: COMMAND_GET,
              }),
            })
              .then((data) => data.json())
              .then((json_data) => {
                const user_fetched = JSON.parse(json_data.user);
                if (
                  user_fetched &&
                  user_fetched.user_profile.user_mode === "affiliate" &&
                  !user_fetched.user_active_plan_id
                ) {
                  navigate("/plan");
                }
              })
              .catch((err) => {})
              .finally(() => {
                params.has("redirect") &&
                  navigate(`/${params.get("redirect")}`);
              });
          })
          .catch((error) => {
            if (error.code === "auth/network-request-failed") {
              setModalState({
                title: "Error",
                message: "Network error occured",
              });
              setModalOpen(true);
            } else {
              setError(true);
            }
          });
      })
      .catch((error) => {});
  };

  return (
    <div className="flex w-full items-center justify-center pb-20">
      <div className="w-full md:m-20 md:mt-10 md:rounded-xl md:p-5">
        <div className="m-10 md:mt-0">
          <h1 className="text-4xl text-center text-primaryFontColor md:text-5xl lg:text-4xl">
            Login
          </h1>
          <p className="text-center text-md text-secondaryFontColor pt-3 md:text-lg lg:text-xl">
            Add your details to login
          </p>
        </div>
        <div className="m-10 lg:m-5">
          <form
            className="w-full flex flex-col items-center gap-7"
            onSubmit={(e) => logWithEmail(e)}
          >
            <input
              className="h-12 w-full shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] outline-none border p-5 rounded-2xl text-primaryFontColor md:text-lg lg:text-lg lg:p-5 lg:h-10"
              type="email"
              ref={emailRef}
              required
              placeholder="Your Email"
            ></input>

            <input
              className="w-full shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] border outline-none px-5 py-3 rounded-2xl text-primaryFontColor md:text-lg lg:text-lg lg:p-5 lg:h-10"
              type="password"
              ref={passwordRef}
              required
              placeholder="Password"
            ></input>

            {error ? (
              <p className="text-red-600 text-left md:text-3xl lg:text-lg">
                Incorrect email or password
              </p>
            ) : (
              <></>
            )}

            <input
              className="h-12 w-full bg-mainColor p-0 rounded-2xl text-white md:text-lg lg:text-lg lg:h-10"
              type="submit"
              value="Login"
            ></input>

            {/* Link */}
            <p
              onClick={() => navigate("/forget")}
              className="text-center cursor-pointer text-secondaryFontColor md:text-lg lg:text-lg"
            >
              Forgot Your Password?
            </p>
          </form>
          {/* <div className="mt-11 md:mt-16 lg:mt-5">
            <p className="text-center text-secondaryFontColor mt-5 md:text-xl lg:text-xl lg:m-0">
              OR
            </p>
            <div
              onClick={(e) => logWithGoogle(e)}
              className="cursor-pointer mt-7 border w-full flex flex-row items-center justify-center gap-3 rounded-2xl md:text-lg md:h-10 md:p-5 md:mt-5 lg:text-lg lg:p-5 lg:h-10"
            >
              <span className="py-2 md:p-0">
                <svg
                  enableBackground="new 0 0 48 48"
                  height="48"
                  viewBox="0 0 48 48"
                  width="48"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-7 h-7"
                >
                  <path
                    d="m43.611 20.083h-1.611v-.083h-18v8h11.303c-1.649 4.657-6.08 8-11.303 8-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657c-3.572-3.329-8.35-5.382-13.618-5.382-11.045 0-20 8.955-20 20s8.955 20 20 20 20-8.955 20-20c0-1.341-.138-2.65-.389-3.917z"
                    fill="#ffc107"
                  />
                  <path
                    d="m6.306 14.691 6.571 4.819c1.778-4.402 6.084-7.51 11.123-7.51 3.059 0 5.842 1.154 7.961 3.039l5.657-5.657c-3.572-3.329-8.35-5.382-13.618-5.382-7.682 0-14.344 4.337-17.694 10.691z"
                    fill="#ff3d00"
                  />
                  <path
                    d="m24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238c-2.008 1.521-4.504 2.43-7.219 2.43-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025c3.31 6.477 10.032 10.921 17.805 10.921z"
                    fill="#4caf50"
                  />
                  <path
                    d="m43.611 20.083h-1.611v-.083h-18v8h11.303c-.792 2.237-2.231 4.166-4.087 5.571.001-.001.002-.001.003-.002l6.19 5.238c-.438.398 6.591-4.807 6.591-14.807 0-1.341-.138-2.65-.389-3.917z"
                    fill="#1976d2"
                  />
                </svg>
              </span>
              <div className="p-0">Signin with Google</div>
            </div>

            <p
              className="text-center text-red-600 mt-5 md:text-3xll lg:text-xl"
              style={{
                display: userError ? "block" : "none",
              }}
            >
              User doesn't exist, please Sign Up
            </p>

            <div className="flex flex-row w-full justify-center left-0 items-center mt-16 md:text-lg lg:text-lg">
              <p
                onClick={() => navigate("/signup")}
                className="text-secondaryFontColor cursor-pointer"
              >
                Don't have an Account?
                <span className="font-bold text-mainColor"> Sign Up </span>
              </p>
            </div>
          </div> */}
          <div className="flex flex-row w-full justify-center left-0 items-center mt-16 md:text-lg lg:text-lg">
            <p
              onClick={() => navigate("/signup")}
              className="text-secondaryFontColor cursor-pointer"
            >
              Don't have an Account?
              <span className="font-bold text-mainColor"> Sign Up </span>
            </p>
          </div>
        </div>
      </div>
      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false);
          window.location.reload();
        }}
      >
        <h1 className="text-2xl mb-4">{modalState.title}</h1>
        <p>{modalState.message}</p>
      </Modal>
    </div>
  );
}
