import React, { useEffect } from "react";
import { motion } from "framer-motion";

export function ShippingPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.1 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.1 } } }}
      className="container mx-auto mt-8 p-4 pb-20"
    >
      <h2 className="text-3xl font-bold mb-4">Shipping Policy</h2>

      <p className="text-gray-700 mb-4">
        Thank you for choosing EatoEarn! We are committed to providing you with
        a delightful food delivery experience. Please read our shipping policy
        below for details on how we handle food deliveries.
      </p>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Delivery Method</h3>
        <p className="text-gray-700">
          At EatoEarn, we deliver food manually using our dedicated team of
          delivery professionals. Our delivery boys ensure that your food is
          handled with care and delivered promptly to your doorstep.
        </p>
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Delivery Areas</h3>
        <p className="text-gray-700">
          We currently provide food delivery services to specific areas within
          our coverage. To check if we deliver to your location, please enter
          your delivery address during the checkout process.
        </p>
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Delivery Time</h3>
        <p className="text-gray-700">
          Our goal is to deliver your food within 60 minutes of placing your
          order. The estimated delivery time will be provided to you during the
          checkout process based on your location and order details. Please note
          that delivery times may vary due to factors such as traffic conditions
          and order volume.
        </p>
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Delivery Charges</h3>
        <p className="text-gray-700">
          We strive to keep our delivery charges reasonable and transparent. The
          delivery fee will be displayed at the checkout before you confirm your
          order. Free delivery may be available for orders above a certain
          amount – check our promotions for more details.
        </p>
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Order Tracking</h3>
        <p className="text-gray-700">
          To enhance your experience, we are working on implementing a real-time
          order tracking system. Soon, you'll be able to track the status of
          your delivery and know when your delicious meal will arrive.
        </p>
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Contact Us</h3>
        <p className="text-gray-700">
          If you have any questions or concerns regarding your food delivery,
          please don't hesitate to contact our customer support team at{" "}
          <a href="mailto:support@eatoearn.com" className="text-blue-500">
            support@eatoearn.com
          </a>{" "}
        </p>
      </div>

      <p className="text-mainColor text-center">
        Thank you for choosing EatoEarn for your food delivery needs. We
        appreciate your trust and look forward to serving you!
      </p>
    </motion.div>
  );
}
