import { useEffect, useState } from "react";
import { CreatePayment } from "../phonepe/phonePe";
import { USER_REF } from "../../app/constants";
import { useAuth } from "../auth/FirebaseAuth";
import {
  ShimmerCategoryItem,
  ShimmerText,
  ShimmerThumbnail,
  ShimmerTitle,
} from "react-shimmer-effects";
import Modal from "../utils/Modal";
import { motion } from "framer-motion";
import giftIcon from "../assets/plans/gift.png";
import { HttpStatusCode } from "axios";
import { useNavigate } from "react-router-dom";

export function ActivePlan({ saved, availablity, charges }) {
  const { currentUser } = useAuth();
  const [activePlan, setActivePlan] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [modalViewState, setModalViewState] = useState(null);
  const [coppied, setCoppied] = useState(false);
  const [plans, setPlans] = useState(null);
  const [userFetched, setUserFetched] = useState(null);
  const [rewards, setRewards] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });

    if (currentUser) {
      const user_fetch = fetch(
        `${process.env.REACT_APP_SERVER}/users/${currentUser.uid}`,
        {
          method: "GET",
          mode: "cors",
        }
      )
        .then((data) => data.json())
        .catch((err) => {});

      // Active Plan
      const active_plan_data = fetch(
        `${process.env.REACT_APP_SERVER}/active/${currentUser.uid}`,
        {
          method: "GET",
          mode: "cors",
        }
      )
        .then((data) => {
          if (data.ok) return data.json();
          return null;
        })
        .catch(() => {});

      const plan_rewards = fetch(
        `${process.env.REACT_APP_SERVER}/benifits/${currentUser.uid}`,
        {
          method: "GET",
          mode: "cors",
        }
      )
        .then((resp) => resp.json())
        .catch(() => {});

      // Plans ...
      const plans_data = fetch(`${process.env.REACT_APP_SERVER}/plans/`, {
        method: "GET",
        mode: "cors",
      }).then((data) => data.json());

      Promise.all([
        user_fetch,
        active_plan_data,
        plan_rewards,
        plans_data,
      ]).then(([userFetched, active_, rewards_, plans_]) => {
        setUserFetched(userFetched);
        setActivePlan(active_ || "NULL");
        if (active_ !== null) {
          const fileredPlans = plans_
            .sort((a, b) => a.plan_price - b.plan_price)
            .filter((item) => item.plan_price > active_.plan_price);
          setPlans(fileredPlans);
        } else {
          setPlans(plans_.sort((a, b) => a.plan_price - b.plan_price));
        }
        setRewards(rewards_);
      });
    }
  }, [currentUser, isOpen]);

  // TODO: ADD TO FOOD ORDERS MARK AVAILED
  const handleRedeemOffer = (food) => {
    const resp = window.confirm("Do you want to redeem this offer?");
    if (resp === true) {
      const order = {
        user_uid: currentUser.uid,
        billed_to: userFetched.user_name || currentUser.email,
        total: food.food_types[0].type_price,
        delivery_charges: 0,
        platform_fee: 0,
        food_order_successfull: true,
        address: saved,
        suggestion: "",
        items: [food],
        is_redeem: true,
      };
      // REDEEM THE OFFER ...
      if (currentUser) {
        fetch(`${process.env.REACT_APP_SERVER}/orders/`, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json", // Specify the content type as JSON
          },
          body: JSON.stringify(order),
        })
          .then((response) => {
            if (response.status === HttpStatusCode.Created) {
              setModalState({
                title: "Success",
                message: "Reward redeemed successfully",
                is_redeem: true,
              });
              setModalOpen(true);
            }
          })
          .catch((err) => {});
      }
    }
  };

  const copyToClipBoard = (e) => {
    setCoppied(false);
    const link = `https://eatoearn.com/signup/?${USER_REF}=${userFetched.user_ID}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setCoppied(true);
      })
      .catch(() => {
        setCoppied(false);
      });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.1 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.1 } } }}
      className="pb-20"
    >
      {currentUser && activePlan && plans ? (
        <div>
          {/*  */}
          <div className="flex items-center mx-3 mt-7">
            <hr className="flex-grow border-t border-gray-300" />
            <span className="px-3 text-primaryFontColor font-semibold">
              Affiliate profile
            </span>
            <hr className="flex-grow border-t border-gray-300" />
          </div>

          {/* Active Plan */}
          {activePlan !== "NULL" ? (
            <div className="mt-5 mx-3 rounded-2xl border shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] p-5 flex justify-between md:p-10 lg:p-7 lg:mr-24">
              <div>
                <h1 className="text-4xl font-bold text-primaryFontColor md:text-5xl">
                  &#8377;{activePlan.plan_price}
                </h1>
                <p className="text-mainColor md:text-xl pt-2 lg:text-2xl">
                  {activePlan.plan_name}
                </p>
              </div>
            </div>
          ) : (
            <div className="mt-5 mx-3 rounded-2xl p-5 shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] border flex justify-between md:p-10 lg:p-7 lg:mr-24 ">
              <div>
                <h1 className="text-3xl font-bold text-primaryFontColor md:text-2xl">
                  No Active Plan
                </h1>
                <p className="text-mainColor"> Please buy a new plan bellow </p>
              </div>
            </div>
          )}
          {/* Reward */}
          {rewards && rewards.plan_benifits && (
            <div
              className="flex m-3 p-3 items-center gap-3 cursor-pointer select-none shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] border rounded-2xl"
              onClick={() => setIsOpen(true)}
            >
              <img className="max-w-16 max-h-16" src={giftIcon} alt=""></img>
              <div>
                <h1 className="text-primaryFontColor">
                  You have earned a new reward
                </h1>
                <p className="text-mainColor"> Tap to view </p>
              </div>
            </div>
          )}

          {/* Affiliate Referral Link */}
          <h1 className="mx-3 text-primaryFontColor mt-7 md:text-lg md:font-bold">
            Referral link
          </h1>
          <div className="mt-5 shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] border rounded-2xl p-5 mx-3 flex justify-between gap-1 md:mr-24">
            <a href="#1" className="text-blue-600 md:text-lg break-all">
              https://eatoearn.com/signup/?{USER_REF}={userFetched.user_ID}
            </a>
            <span
              className="flex flex-col gap-1 items-center cursor-pointer hover:scale-110 transition"
              onClick={(e) => copyToClipBoard(e)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#FC6011"
                className="w-6 h-6 mx-2"
                viewBox="0 0 16 16"
              >
                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
              </svg>
              <p className="text-mainColor text-sm md:text-lg">
                {coppied ? "Coppied" : "Copy"}
              </p>
            </span>
          </div>

          {/* ACtive Plan Benifits Modal */}
          <div>
            <div
              className={
                isOpen
                  ? "fixed inset-0 flex items-center justify-center z-50"
                  : "hidden"
              }
            >
              <div
                className="fixed inset-0 bg-black opacity-50"
                onClick={() => {
                  setIsOpen(false);
                }}
              ></div>
              <div className="bg-white p-5 rounded-lg z-10 w-full mx-7 md:w-1/2 lg:w-1/4">
                {rewards.plan_benifits ? (
                  <div className="select-none">
                    {rewards.hours_remaining !== null && (
                      <p className="px-3 text-red-600 py-2 text-center font-bold">
                        Expiring in {rewards.hours_remaining} Hours
                      </p>
                    )}

                    <div className="p-3 text-red-600 text-sm">
                      <strong>NOTE:</strong> If you face any problem use
                      different browser, or contact support@eatoearn.com
                    </div>
                    <div className="pt-0 flex flex-col gap-1 w-full">
                      {rewards.plan_benifits.map((food, index) => (
                        <div
                          className="flex items-center gap-2 text-sm lg:text-xl"
                          key={index}
                        >
                          <div
                            style={{
                              color: activePlan.plan_bg,
                            }}
                            className="flex gap-3 w-full shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] border outline-none rounded-2xl text-blue-500 md:text-lg lg:text-lg lg:p-2"
                          >
                            <img
                              alt=""
                              className="max-h-16 max-w-16 object-cover aspect-square rounded-l-lg"
                              src={food.food_thumb_url}
                            />
                            <div className="mt-3 lg:mt-1">
                              <h1> {food.food_name} </h1>
                              <div className="flex gap-5 py-1 items-start justify-evenly w-full">
                                <p className="text-primaryFontColor font-bold">
                                  &#8377;{food.food_types[0].type_price}
                                </p>
                                <p
                                  className="text-mainColor cursor-pointer"
                                  onClick={() =>
                                    saved
                                      ? handleRedeemOffer(food)
                                      : navigate("/addresses")
                                  }
                                >
                                  REDEEM NOW{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div>
                    <h1 className="md:text-xl font-bold text-[#e24949]">
                      Plan benifits expired
                    </h1>
                  </div>
                )}
                <div className="gap-5 sm:flex sm:flex-col md:flex-row">
                  <button
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded w-full"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Benifits Modal */}
          <div>
            <div
              className={
                isViewOpen
                  ? "fixed inset-0 flex items-center justify-center z-50"
                  : "hidden"
              }
            >
              <div
                className="fixed inset-0 bg-black opacity-50"
                onClick={() => {
                  setIsViewOpen(false);
                }}
              ></div>
              <div className="bg-white p-5 select-none rounded-lg z-10 w-full mx-7 md:w-1/2 lg:w-1/4">
                <div>
                  <h1 className="text-center pb-2 text-primaryFontColor">
                    "{modalViewState && modalViewState.plan_name}" Plan Benifits
                  </h1>
                  <p className="text-mainColor py-1 px-1"> Free foods </p>
                  <div className="pt-0 flex flex-col gap-1 w-full">
                    {modalViewState &&
                      modalViewState.plan_benifits.map((food, index) => (
                        <div
                          className="flex items-center gap-2 text-sm text-primaryFontColor"
                          key={index}
                        >
                          <div
                            style={{
                              color: modalViewState.plan_bg,
                            }}
                            className="flex gap-3 w-full shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] border outline-none rounded-2xl text-primaryFontColor md:text-lg lg:p-3"
                          >
                            <img
                              alt=""
                              className="max-h-16 max-w-16 aspect-square rounded-lg"
                              src={food.food_thumb_url}
                            />
                            <div className="mt-3 lg:mt-1">
                              <h1> {food.food_name} </h1>
                              <p className="text-primaryFontColor font-bold">
                                &#8377;{food.food_types[0].type_price}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="gap-5 sm:flex sm:flex-col md:flex-row">
                  <button
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded w-full"
                    onClick={() => {
                      setIsViewOpen(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="mx-3">
            {/* Upgrade */}
            {plans && plans.length > 0 && (
              <h1 className="text-primaryFontColor mt-7 md:text-lg md:mt-10 md:font-bold">
                Buy new plan
              </h1>
            )}
            {/* Plans */}
            <div className="flex gap-5 overflow-x-auto">
              {plans.map((plan, indexOuter) => (
                <div
                  className="mt-3 rounded-2xl min-w-[11em] select-none shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] border md:mt-5 md:min-w-[16em] lg:min-w-[13em]"
                  key={indexOuter}
                >
                  {/* inner color top box */}
                  <div
                    className="min-h-36 rounded-2xl clip-custom md:min-h-48 md:rounded-xl lg:min-h-48"
                    style={{
                      background: plan.plan_bg,
                    }}
                  >
                    <div className="p-5 flex flex-col items-start justify-center md:p-8 lg:p-5">
                      <p className=" text-white md:text-xl lg:text-xl">
                        {plan.plan_name}
                      </p>
                      <h1 className="text-4xl font-bold text-white md:text-5xl">
                        &#8377;{plan.plan_price}
                      </h1>
                      {saved && !availablity.free_plan_delivery ? (
                        <p className="text-sm font-bold text-primaryFontColor">
                          +{charges} Delivery{""}
                        </p>
                      ) : (
                        <p className="text-sm font-bold text-white">
                          Free Delivery
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="p-5 pt-0 flex flex-col gap-1">
                    <p
                      className="font-bold text-center cursor-pointer p-1 border rounded-xl"
                      style={{
                        color: plan.plan_bg,
                      }}
                      onClick={() => {
                        setModalViewState({ ...plan });
                        setIsViewOpen(true);
                      }}
                    >
                      View details
                    </p>
                    {saved || availablity.free_plan_delivery ? (
                      <div>
                        {availablity.plans ? (
                          <CreatePayment
                            xxtraStyles="rounded-xl py-2"
                            order={{
                              ...plan,
                              id: plan.id,
                              charges: availablity.free_plan_delivery
                                ? 0
                                : charges,
                            }}
                          />
                        ) : (
                          <p className="my-3  text-mainColor text-center">
                            OPENING SOON
                          </p>
                        )}
                      </div>
                    ) : (
                      <p
                        className="mx-5 my-3 text-mainColor max-w-36 text-center text-wrap"
                        onClick={() => navigate("/addresses")}
                      >
                        Please add delivery address to continue
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="overflow-hidden m-3">
          <ShimmerCategoryItem />
          <ShimmerThumbnail center height={50} width={300} />

          <div className="flex flex-row items-center justify-center gap-4 mt-2">
            <div>
              <ShimmerThumbnail height={70} width={70} rounded />
              <ShimmerText line={1} />
            </div>
            <div>
              <ShimmerThumbnail height={70} width={70} rounded />
              <ShimmerText line={1} />
            </div>
            <div>
              <ShimmerThumbnail height={70} width={70} rounded />
              <ShimmerText line={1} />
            </div>
            <div>
              <ShimmerThumbnail height={70} width={70} rounded />
              <ShimmerText line={1} />
            </div>
          </div>

          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-2/3" />
            <ShimmerText line={1} />
          </div>

          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-2/3" />
            <ShimmerText line={1} />
          </div>

          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-2/3" />
            <ShimmerText line={1} />
          </div>

          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-2/3" />
            <ShimmerText line={1} />
          </div>
        </div>
      )}

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false);
          setIsOpen(false);
          if (modalState.is_redeem) navigate("/history");
        }}
      >
        <h1 className="text-2xl mb-4">{modalState.title}</h1>
        <p>{modalState.message}</p>
      </Modal>
    </motion.div>
  );
}
