import { useEffect, useRef, useState } from "react";
import { useAuth } from "../auth/FirebaseAuth";
import { CART, MAX_FETCH_ELEMENTS } from "../../app/constants";

import Modal from "../utils/Modal";
import {
  ShimmerText,
  ShimmerThumbnail,
  ShimmerTitle,
} from "react-shimmer-effects";
import loading from "../assets/global/loading.svg";
import { els } from "../../app/utils";
import { BottomSheet } from "react-spring-bottom-sheet";
import { SheetContent } from "../utils/SheetContent";
import { useNavigate } from "react-router-dom";
import ReactImageAppear from "react-image-appear";
import { motion } from "framer-motion";
import toast from "react-hot-toast";

import nothingHere from "../assets/food/nothing_here.webp";

export function ViewAll({ setCartItems }) {
  const { currentUser } = useAuth();
  const [viewItems, setViewItems] = useState(null);
  const [loadingError, setLoadingError] = useState(false);
  const [matches, setMatches] = useState(null);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalState, setModalState] = useState({});
  const [showLoading, setShowLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [isTypesOpen, setIsTypesOpen] = useState(false);
  const [openSheet, setOpenSheet] = useState(false);
  const [favourites, setFavourites] = useState([]);
  const [showX, setShowX] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const category = params.get("q");
  const tag = params.get("tag");
  const offer_tag = params.get("offer_tag");
  const sheetRef = useRef();
  const searchRef = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (params.has("q") || params.has("tag") || params.has("offer_tag")) {
      // view all
      const paramsGet = new URLSearchParams();
      paramsGet.append("skip", 0);
      paramsGet.append("limit", MAX_FETCH_ELEMENTS + 1);
      //
      if (params.has("q")) {
        paramsGet.append("category", category.toLowerCase());
      }
      if (params.has("tag")) {
        paramsGet.append("tag", tag.toLowerCase());
      }
      if (params.has("offer_tag")) {
        paramsGet.append("offer_tag", offer_tag.toLowerCase());
      }
      //
      fetch(`${process.env.REACT_APP_SERVER}/foods?${paramsGet}`, {
        method: "GET",
        mode: "cors",
      })
        .then((data) => data.json())
        .then((jdata) => {
          setViewItems(jdata || []);
          if (jdata.length <= 0) {
            setLoadingError(true);
          }
        })
        .catch((err) => {
          setModalState({
            title: "Error",
            message: "Network error occured",
          });
          setModalOpen(true);
        });
    } else if (params.has("name") || params.has("id")) {
      // view one item
      const paramsGet = new URLSearchParams();
      if (params.has("id")) paramsGet.append("id", params.get("id"));
      if (params.has("name")) paramsGet.append("food_name", params.get("name"));
      paramsGet.append("skip", 0);
      paramsGet.append("limit", 1);

      fetch(`${process.env.REACT_APP_SERVER}/foods?${paramsGet}`, {
        method: "GET",
        mode: "cors",
      })
        .then((data) => data.json())
        .then((jdata) => {
          setViewItems(jdata);
        })
        .catch((err) => {
          setModalState({
            title: "Error",
            message: "Network error occured",
          });
          setModalOpen(true);
        });
    }

    //
    const paramsGet = new URLSearchParams();
    paramsGet.append("skip", 0);
    paramsGet.append("limit", 100);
    paramsGet.append("user_uid", currentUser && currentUser.uid);

    if (currentUser) {
      fetch(`${process.env.REACT_APP_SERVER}/favourites?${paramsGet}`, {
        method: "GET",
        mode: "cors",
      })
        .then((data) => data.json())
        .then((favourites) => {
          setFavourites(
            favourites.map((it) => {
              return {
                ...it,
                _id: it.id,
              };
            })
          );
        })
        .finally(() => {
          window.scrollTo(0, 0);
        });
    }
  }, [category, currentUser, offer_tag, tag]);

  const loadMoreItems = (e) => {
    setShowLoading(true);
    const paramsGet = new URLSearchParams();
    paramsGet.append("skip", viewItems.length);
    paramsGet.append("limit", viewItems.length + MAX_FETCH_ELEMENTS + 1);
    //
    if (params.has("q")) {
      paramsGet.append("category", category.toLowerCase());
    }
    if (params.has("tag")) {
      paramsGet.append("tag", tag.toLowerCase());
    }
    if (params.has("offer_tag")) {
      paramsGet.append("offer_tag", offer_tag.toLowerCase());
    }
    //
    fetch(`${process.env.REACT_APP_SERVER}/foods?${paramsGet}`, {
      method: "GET",
      mode: "cors",
    })
      .then((data) => data.json())
      .then((view_items) => {
        if (view_items.length > 0) {
          setViewItems(viewItems.concat(view_items));
        } else {
          setLoadingError(true);
        }
      })
      .catch((err) => {
        // console.log("MORE ORDERS-FETCH_ERROR: ", err);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };

  const handleSearch = (query) => {
    setMatches(null);
    setShowX(false);

    if (query.length >= 1) {
      setShowX(true);
    }

    if (query.length >= 2) {
      debounce((query) => {
        fetch(`${process.env.REACT_APP_SERVER}/search/${query}`, {
          method: "GET",
          mode: "cors",
        })
          .then((data) => data.json())
          .then((matches) => {
            setMatches(matches);
            if (matches.length <= 0) {
              setMatches([{ food_name: "No results found", isNone: true }]);
            }
          })
          .catch((err) => {
            // console.log("SEARCH-FETCH_ERROR: ", err);
          });
      }, 500)(query);
    } else if (query.length <= 0) {
      setMatches(null);
      setSelectedMatch(null);
    }
  };

  const inCart = (item, recent) => {
    const cartDataRaw = els.get(CART);
    if (cartDataRaw !== null) {
      const cartItems = JSON.parse(cartDataRaw);
      return cartItems.filter((itm) => itm.id === item.id).length === 1;
    }
    return false;
  };

  const addToCart = (item) => {
    toast.success("Added to bag");
    const cartDataRaw = els.get(CART);
    if (cartDataRaw !== null) {
      const cartItems = JSON.parse(cartDataRaw);
      if (!inCart(item)) {
        cartItems.push({ ...item, id: item.id });
        els.set(CART, JSON.stringify(cartItems));
        setCartItems(cartItems);
      } else {
        const filteredCart = cartItems.filter((it) => it.id !== item.id);
        els.set(CART, JSON.stringify(filteredCart));
        setCartItems(filteredCart);
      }
    } else {
      const data = [{ ...item, id: item.id }];
      els.set(CART, JSON.stringify(data));
      setCartItems(data);
    }
    setOpenSheet(false);
    setIsTypesOpen(false);
  };

  const inFavourites = (item) => {
    return favourites.filter((it) => it.id === item.id).length === 1;
  };

  const handleAddToCart = (item) => {
    setSelected(item);
    setIsTypesOpen(true);
    setOpenSheet(true);
  };

  const handleAddToFavourites = (item) => {
    if (currentUser) {
      if (inFavourites(item)) {
        setFavourites(favourites.filter((it) => it.id !== item.id));
      } else {
        toast.success("Added to favourites");
        setFavourites(favourites.concat([item]));
      }
      // add to favs
      fetch(`${process.env.REACT_APP_SERVER}/favourites/`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          food_id: item.id,
          user_uid: currentUser && currentUser.uid,
        }),
      }).catch((err) => {
        // console.log("ADD-FAV_ERROR: ", err);
        setFavourites(favourites.filter((itm) => item.id !== itm.id));
      });
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="m-3 pb-20">
      {/* Search */}
      <div className="flex flex-col relative">
        <div className="h-10 flex items-center mt-2 border shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] py-5 px-3 rounded-xl m-1 text-primaryFontColor md:text-lg md:p-5 md:mt-10 lg:text-xl lg:p-6">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            className="min-w-5 min-h-5"
          >
            <path
              d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
              stroke="#FC6011"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22 22L20 20"
              stroke="#FC6011"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <input
            onChange={(e) => handleSearch(e.target.value)}
            ref={searchRef}
            type="text"
            className="bg-transparent grow-10 w-full ml-5 outline-none border-r border-mainColor mr-2"
            placeholder="Start typing..."
          ></input>
          {showX && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="w-6 h-6"
              viewBox="0 0 16 16"
              onClick={() => {
                searchRef.current.value = "";
                setSelectedMatch(null);
                setShowX(false);
                setMatches(null);
              }}
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          )}
        </div>
        {/* Matches */}
        {matches && (
          <motion.div
            initial={{ y: -10, scale: 0.8 }}
            animate={{ y: 0, scale: 1 }}
            className="flex flex-col items-center mt-2 p-3 border shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] rounded-xl  gap-3 text-primaryFontColor md:text-3xl md:py-5 md:mt-0 lg:text-xl lg:p-5 absolute top-16 lg:top-24 left-0 w-full bg-white z-20"
          >
            {matches
              .filter((it) =>
                selectedMatch ? it.id !== selectedMatch.id : true
              )
              .map((match, idx) => (
                <div
                  key={idx}
                  className="border w-full flex items-center justify-center p-3 rounded-lg cursor-pointer"
                  onClick={(e) => {
                    setMatches(null);
                    setShowX(false);
                    !match.isNone && setViewItems([match]);
                    searchRef.current.value = "";
                    !match.isNone && navigate(`/view?id=${match.id}`);
                  }}
                >
                  <motion.h1 initial={{ y: -10 }} animate={{ y: 0 }}>
                    {match.food_name}
                  </motion.h1>
                </div>
              ))}
          </motion.div>
        )}
      </div>
      {/* HR Viewing */}
      {(category || tag) && (
        <div className="flex items-center mt-7 mx-3">
          <hr className="flex-grow border-t border-gray-300" />
          <span className="px-3 text-primaryFontColor font-semibold">
            {(category || tag).toUpperCase().replace("_", " ")}
          </span>
          <hr className="flex-grow border-t border-gray-300" />
        </div>
      )}
      {/* view Items */}
      <div>
        {viewItems ? (
          <div className="mt-5 sm:flex sm:flex-col sm:gap-5 md:gap-5 lg:grid lg:grid-cols-3">
            {viewItems.map((item, idx) => (
              <div
                key={idx}
                className="flex flex-col flex-shrink-0 justify-center select-none shadow-[0px_0px_20px_-10px_rgba(0,0,0,0.7)] mt-3 rounded-2xl pb-3"
              >
                <div className="relative">
                  <div className="w-full bg-gradient-to-b from-[#00000070] to-transparent h-14 absolute z-10 rounded-t-2xl flex items-center justify-end px-5">
                    <div
                      className="cursor-pointer"
                      onClick={() => handleAddToFavourites(item)}
                    >
                      {inFavourites(item) ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#f1414f"
                          className="w-6 h-6"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#ffffff"
                          className="w-6 h-6"
                          viewBox="0 0 16 16"
                        >
                          <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                        </svg>
                      )}
                    </div>
                  </div>
                  {item.food_thumb_url && (
                    <ReactImageAppear
                      className="w-full max-h-48 object-cover min-h-48 rounded-t-2xl md:min-h-64 md:max-h-64 lg:min-h-36 lg:max-h-36"
                      src={item.food_thumb_url}
                      animation="blurIn"
                      animationDuration="100ms"
                      placeholderClass="rounded-t-2xl"
                      key={item.id}
                    />
                  )}
                </div>
                <div className="flex items-start justify-between">
                  <div>
                    <h1 className="pt-3 px-3 text-primaryFontColor font-bold line-clamp-1 text-lg md:text-lg md:pt-7 lg:pt-2">
                      {item.food_name}
                    </h1>
                    <p className="px-3 text-primaryFontColor">
                      <span className="text-mainColor">
                        {item.food_category.replace("_", " ")}
                      </span>{" "}
                      • &#8377;{item.food_types[0].type_price} for one
                    </p>
                  </div>
                  <span
                    onClick={(e) => handleAddToCart(item)}
                    className="cursor-pointer block mt-3"
                  >
                    {inCart(item) ? (
                      <svg
                        width="16"
                        height="16"
                        fill="#FC6011"
                        className="w-6 h-6 lg:min-h-6 mb-1 mr-3"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0M8.5 8a.5.5 0 0 0-1 0v1.5H6a.5.5 0 0 0 0 1h1.5V12a.5.5 0 0 0 1 0v-1.5H10a.5.5 0 0 0 0-1H8.5z"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="16"
                        height="16"
                        fill="#FC6011"
                        className="w-6 h-6 lg:min-h-6 mr-3"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5"
                        />
                        <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                      </svg>
                    )}
                  </span>
                </div>
                <div>
                  <div className="flex items-center justify-between"></div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="overflow-hidden mt-5">
            <div className="mt-3">
              <ShimmerThumbnail height={200} rounded />
              <ShimmerTitle line={1} className="w-2/3" />
              <ShimmerText line={1} />
            </div>

            <div className="mt-3">
              <ShimmerThumbnail height={200} rounded />
              <ShimmerTitle line={1} className="w-2/3" />
              <ShimmerText line={1} />
            </div>

            <div className="mt-3">
              <ShimmerThumbnail height={200} rounded />
              <ShimmerTitle line={1} className="w-2/3" />
              <ShimmerText line={1} />
            </div>
          </div>
        )}
      </div>
      <div
        className={`hidden ${
          showLoading ? "flex" : ""
        } items-center justify-center`}
      >
        <img src={loading} alt="" className="w-12 h-12 mt-7" />
      </div>
      {viewItems && viewItems.length <= 0 && (
        <div className="p-10">
          <img src={nothingHere} alt="" />
          <p className="text-xl text-center mt-5 text-slate-400">
            No food items
          </p>
        </div>
      )}
      {!loadingError &&
        (category !== null || tag !== null || offer_tag !== null) && (
          <span className="flex flex-col items-center justify-center w-full">
            <button
              disabled={loadingError}
              className="bg-mainColor w-full p-3 rounded-2xl mt-10 text-white disabled:bg-slate-400 md:w-full md:text-3xl lg:text-sm lg:p-2 lg:max-w-56"
              onClick={(e) => loadMoreItems(e)}
            >
              Load More
            </button>
          </span>
        )}
      {/* Types select Modal */}
      {selected && isTypesOpen && (
        <div className="hidden fixed inset-0 md:flex items-center justify-center z-50">
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={() => {
              setIsTypesOpen(false);
              setOpenSheet(false);
            }}
          ></div>
          <div className="bg-white p-1 rounded-lg z-10 w-full mx-7 md:w-1/2">
            <div>
              <SheetContent
                item={selected}
                addToCart={addToCart}
                close={() => {
                  setOpenSheet(false);
                  setIsTypesOpen(false);
                }}
                added={inCart(selected)}
              />
            </div>
          </div>
        </div>
      )}
      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <h1 className="text-2xl mb-4">{modalState.title}</h1>
        <p>{modalState.message}</p>
      </Modal>
      {/* Bottom sheet */}
      {selected && (
        <BottomSheet
          open={openSheet}
          ref={sheetRef}
          className="fixed z-50 lg:hidden"
          // onClick={() => setOpenSheet(false)}
        >
          <SheetContent
            item={selected}
            addToCart={addToCart}
            close={() => setOpenSheet(false)}
            added={inCart(selected)}
          />
        </BottomSheet>
      )}
    </div>
  );
}
