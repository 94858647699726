import { useState } from "react";
import { ORDER_PLAN, PAY_DATA } from "../../app/constants";
import { els } from "../../app/utils";
import Modal from "../utils/Modal";
import { useAuth } from "../auth/FirebaseAuth";

export function CreatePayment({
  order,
  style = {},
  extraStyles = "",
  xxtraStyles = "",
}) {
  const { currentUser } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalState, setModalState] = useState({});

  const handlePlaceOrderPaymentGateway = (orderData) => {
    fetch(`${process.env.REACT_APP_SERVER}/pay`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json", // Specify the content type as JSON
      },
      body: JSON.stringify({
        pay_type: 1,
        user_uid: currentUser.uid,
        plan_id: orderData.id,
        charges: orderData.charges,
      }),
    })
      .then((data) => data.json())
      .then((json_data) => {
        if (json_data.pay_page_url) {
          els.set(
            PAY_DATA,
            JSON.stringify({
              merchant_transaction_id: json_data.merchant_transaction_id,
              pay_type: ORDER_PLAN,
              user_uid: currentUser.uid,
              code: json_data.code,
              success: json_data.success,
            })
          );
          // window.open(json_data.pay_page_url, "_blank");
          window.location.href = json_data.pay_page_url;
        }
      })
      .catch((err) => {
        setModalState({
          title: "Error",
          message: "Unkown error occurred, please try again",
        });
        setModalOpen(true);
      });
  };

  return (
    <div className="w-full lg:w-auto">
      <div className={`${extraStyles}`}>
        {order !== null && (
          <input
            type="button"
            value="Buy now"
            className={`p-2 rounded-md text-white mt-2 w-full cursor-pointer ${xxtraStyles}`}
            style={{
              ...style,
              background: order.plan_bg ? order.plan_bg : style.background,
            }}
            onClick={() => handlePlaceOrderPaymentGateway(order)}
          />
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false);
          window.location.reload();
        }}
      >
        <h1 className="text-2xl mb-4">{modalState.title}</h1>
        <p>{modalState.message}</p>
      </Modal>
    </div>
  );
}
