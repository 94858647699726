import { useEffect, useRef, useState } from "react";
import {
  createUserWithEmailAndPassword,
  deleteUser,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../../features/firebase/firebase";
import { useNavigate } from "react-router-dom";
import { USER_REF } from "../../app/constants";
import { useAuth } from "./FirebaseAuth";
import Modal from "../utils/Modal";
import { HttpStatusCode } from "axios";

export function Signup({ userFetched, setUserFetched }) {
  const { currentUser } = useAuth();
  // State here ...
  const [userError, setUserError] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalState, setModalState] = useState({});
  const [showRefInput, setShowRefInput] = useState(false);
  const [mode, setMode] = useState("customer");

  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);

  // Refs
  const signUpFormRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  useEffect(() => {
    if (currentUser) navigate("/");
  }, [currentUser, navigate]);

  const sendVerificationEmail = async (isAffiliate) => {
    const contunueURL = isAffiliate
      ? "https://www.eatoearn.com/plan"
      : "https://www.eatoearn.com";

    console.log("GOT CONT URL");
    console.log(contunueURL);
    console.log(isAffiliate);
    console.log("END");

    if (auth.currentUser !== null && auth.currentUser !== undefined) {
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: contunueURL,
        // This must be true.
        handleCodeInApp: true,
      };

      sendEmailVerification(auth.currentUser, actionCodeSettings)
        .then(() => {})
        .catch((error) => {});
    }
  };

  const signUpNow = (e) => {
    e.preventDefault();
    const form = new FormData(signUpFormRef.current);
    const formDataJSON = Object.fromEntries(form);
    //
    createUserWithEmailAndPassword(
      auth,
      formDataJSON.email,
      formDataJSON.password
    )
      .then((userCredential) => {
        const user = userCredential.user;
        const rawUser = {
          user_uid: user.uid,
          user_email: user.email,
          user_phone: formDataJSON.phone,
          user_name: formDataJSON.username,
          user_referer_uid:
            searchParams.get(USER_REF) || formDataJSON.referral_code || "",
          user_mode: formDataJSON.userMode.toLowerCase(),
        };
        // POST to db
        fetch(`${process.env.REACT_APP_SERVER}/users`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Specify the content type as JSON
          },
          mode: "cors",
          body: JSON.stringify(rawUser),
        })
          .then(async (response) => {
            if (response.status === HttpStatusCode.Created) {
              sendVerificationEmail(
                formDataJSON.userMode === "affiliate" ||
                  searchParams.has(USER_REF)
              );

              setTimeout(() => {
                if (
                  formDataJSON.userMode === "affiliate" ||
                  searchParams.has(USER_REF)
                ) {
                  navigate("/plan");
                }
              }, 500);

              setUserFetched({
                user_profile: {
                  ...userFetched.user_profile,
                  user_mode: formDataJSON.userMode,
                  user_name: formDataJSON.username,
                  user_email: user.email,
                  user_phone: formDataJSON.phone,
                },
              });

              if (
                formDataJSON.userMode === "affiliate" ||
                searchParams.has(USER_REF)
              ) {
                navigate("/plan");
              }
            } else {
              // ensure ATOMIC Behaviour by deleting the user ...
              if (auth.currentUser) {
                deleteUser(auth.currentUser).then(() => {});
              }
            }
          })
          .catch((err) => {});
      })
      .catch((error) => {
        const errorCode = error.code;
        if (errorCode === "auth/email-already-in-use") {
          setUserError("Error, Email already registered");
        } else if (errorCode === "auth/network-request-failed") {
          setModalState({
            title: "Error",
            message: "Network error occured",
          });
          setModalOpen(true);
        } else if (errorCode === "auth/weak-password") {
          setModalState({
            title: "Error",
            message: "Weak password",
          });
          setModalOpen(true);
        } else if (errorCode === "auth/invalid-email") {
          setModalState({
            title: "Error",
            message: "Invalid email address",
          });
          setModalOpen(true);
        }
      });
  };

  return (
    <div className="flex items-center justify-center pb-20">
      <div className="w-full md:m-20 md:mt-10 md:rounded-xl md:p-5">
        <div className="m-10 md:mt-0">
          <h1 className="text-4xl text-center text-primaryFontColor md:text-5xl lg:text-4xl">
            Sign Up
          </h1>
          <p className="text-center text-md text-secondaryFontColor pt-3 md:text-xl">
            Add your details to sign up
          </p>
        </div>
        <div className="m-10">
          <form
            ref={signUpFormRef}
            className="w-full flex flex-col items-center gap-7 md:gap-7"
            onSubmit={(e) => signUpNow(e)}
          >
            <div>
              <p className="text-center text-secondaryFontColor p-2">I am a</p>
              <div className="flex flex-row items-center justify-center gap-5">
                <label className="text-primaryFontColor">
                  <input
                    type="radio"
                    name="userMode"
                    value="customer"
                    className="mr-2 accent-mainColor"
                    onChange={() => setMode("customer")}
                    defaultChecked={!searchParams.has("sponsor")}
                  />
                  Customer
                </label>
                <label className="text-primaryFontColor">
                  <input
                    type="radio"
                    name="userMode"
                    value="affiliate"
                    className="mr-2 accent-mainColor"
                    onChange={() => setMode("affiliate")}
                    defaultChecked={searchParams.has("sponsor")}
                  />
                  Affiliate
                </label>
              </div>
            </div>

            <input
              className="h-12 w-full shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] outline-none border p-5 rounded-2xl text-primaryFontColor md:text-lg lg:text-lg lg:p-5 lg:h-10"
              type="text"
              name="username"
              required
              placeholder="Username"
            ></input>

            <input
              className="h-12 w-full shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] outline-none border p-5 rounded-2xl text-primaryFontColor md:text-lg lg:text-lg lg:p-5 lg:h-10"
              type="email"
              name="email"
              required
              placeholder="Email"
            ></input>

            <input
              className="h-12 w-full shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] outline-none border p-5 rounded-2xl text-primaryFontColor md:text-lg lg:text-lg lg:p-5 lg:h-10"
              type="tel"
              name="phone"
              pattern="\+[0-9]{2}[0-9]{10}"
              required
              defaultValue="+91"
              onChange={(e) => {
                if (e.target.value.length <= 3) {
                  e.target.value = "+91";
                }
              }}
              maxLength={13}
              placeholder="Mobile No"
            ></input>

            <input
              className="h-12 w-full shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] outline-none border p-5 rounded-2xl text-primaryFontColor md:text-lg lg:text-lg lg:p-5 lg:h-10"
              type="password"
              name="password"
              ref={passwordRef}
              required
              placeholder="Password"
            ></input>

            <input
              className="h-12 w-full shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] outline-none border p-5 rounded-2xl text-primaryFontColor md:text-lg lg:text-lg lg:p-5 lg:h-10"
              type="password"
              ref={confirmPasswordRef}
              required
              placeholder="Confirm Password"
              onChange={(e) => {
                if (e.target.value !== passwordRef.current.value)
                  confirmPasswordRef.current.setCustomValidity(
                    "Passwords Don't Match"
                  );
                else confirmPasswordRef.current.setCustomValidity("");
              }}
            ></input>

            {!searchParams.has("sponsor") && mode === "affiliate" && (
              <p
                className="text-mainColor cursor-pointer text-left md:text-3xl lg:text-lg"
                onClick={() => setShowRefInput(true)}
              >
                Got Referral code ?
              </p>
            )}

            <input
              style={{
                display:
                  showRefInput || searchParams.has("sponsor")
                    ? "block"
                    : "none",
              }}
              className="h-12 w-full shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] outline-none border p-5 rounded-2xl text-primaryFontColor md:text-lg lg:text-lg lg:p-5 lg:h-10"
              type="text"
              name="referral_code"
              defaultValue={searchParams.get("sponsor") || ""}
              placeholder="Referral code"
            ></input>

            {userError && (
              <p className="text-red-600 text-left md:text-3xl lg:text-lg">
                {userError}
              </p>
            )}

            <input
              className="h-12 w-full bg-mainColor p-0 rounded-2xl text-white md:text-lg md:h-12 lg:text-lg lg:h-10"
              type="submit"
              value="Sign Up"
            ></input>
          </form>
          <div className="flex flex-row w-full justify-center left-0 items-center mt-10 md:text-lg lg:text-lg">
            <p
              onClick={() => navigate("/login")}
              className="text-secondaryFontColor cursor-pointer"
            >
              Already have an Account?
              <span className="font-bold text-mainColor"> Login </span>
            </p>
          </div>
        </div>
      </div>
      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <h1 className="text-2xl mb-4">{modalState.title}</h1>
        <p>{modalState.message}</p>
      </Modal>
    </div>
  );
}
