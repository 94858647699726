import { useEffect, useRef, useState } from "react";
import { useAuth } from "../auth/FirebaseAuth";
import { STATUS_SUCCESS } from "../../app/constants";
import Modal from "../utils/Modal";
import {
  ShimmerText,
  ShimmerThumbnail,
  ShimmerTitle,
} from "react-shimmer-effects";

export function ProfileSetting({ userFetched, setUserFetched }) {
  const { currentUser } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalState, setModalState] = useState({});
  const [showUpdateForm, setshowUpdateForm] = useState(false);

  const accRef = useRef();

  useEffect(() => {}, [isModalOpen]);

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const formDataJSON = Object.fromEntries(form);

    fetch(`${process.env.REACT_APP_SERVER}/users/${currentUser.uid}`, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json", // Specify the content type as JSON
      },
      body: JSON.stringify(
        formDataJSON.bank_acc && formDataJSON.bank_ifsc
          ? {
              user_name: formDataJSON.user_name,
              user_email: formDataJSON.user_email,
              user_phone: formDataJSON.user_phone,
              user_bank: {
                bank_acc: formDataJSON.bank_acc,
                bank_ifsc: formDataJSON.bank_ifsc,
              },
            }
          : {
              user_name: formDataJSON.user_name,
              user_email: formDataJSON.user_email,
              user_phone: formDataJSON.user_phone,
            }
      ),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status === STATUS_SUCCESS) {
          setUserFetched(
            formDataJSON.bank_acc && formDataJSON.bank_ifsc
              ? {
                  ...userFetched,
                  user_name: formDataJSON.user_name,
                  user_email: formDataJSON.user_email,
                  user_phone: formDataJSON.user_phone,
                  user_bank: {
                    bank_acc: formDataJSON.bank_acc,
                    bank_ifsc: formDataJSON.bank_ifsc,
                  },
                }
              : {
                  ...userFetched,
                  user_name: formDataJSON.user_name,
                  user_email: formDataJSON.user_email,
                  user_phone: formDataJSON.user_phone,
                }
          );

          setModalState({
            title: "Success",
            message: "Profile updated successfully",
          });
          setModalOpen(true);
        }
      })
      .catch((err) => {
        setModalState({
          title: "Error",
          message: "Unknown error occured, please try again",
        });
        setModalOpen(true);
      });
  };

  const handleInputChange = () => {
    let inputValue = accRef.current.value.trim();
    // Remove non-numeric characters
    inputValue = inputValue.replace(/\D/g, "");
    // Truncate input to 18 characters
    inputValue = inputValue.slice(0, 24);
    // Add a space after every 4 characters
    inputValue = inputValue
      .replace(/\s/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();
    // Update input value
    accRef.current.value = inputValue;
  };

  return (
    <div className="m-3 pb-20">
      <div className="flex flex-col items-center justify-between">
        <div className="w-full mt-10">
          {currentUser && currentUser.photoURL ? (
            <div className="flex flex-col items-center justify-center gap-3 text-lg">
              <img
                src={currentUser.photoURL}
                alt=""
                className="rounded-full min-w-36 min-h-36"
              />
              <h1 className="font-bold">
                {userFetched && userFetched.user_name
                  ? userFetched.user_name
                  : currentUser.displayName || ""}
              </h1>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <div className="flex flex-col items-center justify-center gap-3 text-lg">
                <h1 className="font-bold w-36 h-36 text-primaryFontColor text-6xl bg-slate-200 rounded-full text-center pt-12">
                  {currentUser.email.charAt(0).toUpperCase()}
                </h1>
                <h1 className="font-bold">
                  {userFetched && userFetched.user_name
                    ? userFetched.user_name
                    : currentUser.displayName || ""}
                </h1>
              </div>
            </div>
          )}
        </div>
        {/* Current */}
        <div className={currentUser.photoURL ? "" : "mt-2"}>
          {userFetched && (
            <h1 className="text-sm text-mainColor font-bold">
              {userFetched.user_mode.toUpperCase()}
            </h1>
          )}
        </div>
      </div>

      <form
        className="mt-10 flex flex-col gap-3"
        onSubmit={(e) => handleUpdateProfile(e)}
      >
        <input
          className="h-12 w-full shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] outline-none border p-5 rounded-2xl text-primaryFontColor md:text-lg lg:text-lg lg:p-5 lg:h-10"
          type="text"
          name="user_name"
          required
          placeholder="Username"
          defaultValue={
            userFetched && userFetched.user_name
              ? userFetched.user_name
              : currentUser.displayName || ""
          }
        ></input>

        <input
          className="h-12 w-full shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] outline-none border p-5 rounded-2xl text-primaryFontColor md:text-lg lg:text-lg lg:p-5 lg:h-10"
          type="email"
          name="user_email"
          required
          placeholder="Email"
          defaultValue={
            userFetched && userFetched.user_email
              ? userFetched.user_email
              : currentUser.email || ""
          }
        ></input>

        <input
          className="h-12 w-full shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] outline-none border p-5 rounded-2xl text-primaryFontColor md:text-lg lg:text-lg lg:p-5 lg:h-10"
          type="tel"
          name="user_phone"
          pattern="\+[0-9]{2}[0-9]{10}"
          required
          defaultValue={
            userFetched && userFetched.user_phone
              ? userFetched.user_phone
              : currentUser.phoneNumber || "+91"
          }
          onChange={(e) => {
            if (e.target.value.length <= 3) {
              e.target.value = "+91";
            }
          }}
          maxLength={13}
          placeholder="Mobile No"
        ></input>

        {userFetched && userFetched.user_mode === "affiliate" && (
          <div
            className="my-3 flex justify-center"
            onClick={() => {
              setshowUpdateForm(!showUpdateForm);
              setTimeout(() => {
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: "smooth",
                });
              }, 200);
            }}
          >
            <p className="text-mainColor cursor-pointer"> Update your bank </p>
          </div>
        )}

        {userFetched &&
          userFetched.user_mode === "affiliate" &&
          showUpdateForm && (
            <div className="flex flex-col gap-3">
              <input
                ref={accRef}
                className="h-12 tracking-widest w-full shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] outline-none border p-5 rounded-2xl text-primaryFontColor md:text-lg lg:text-lg lg:p-5 lg:h-10"
                type="text"
                required
                name="bank_acc"
                maxLength={24}
                onChange={handleInputChange}
                defaultValue={
                  userFetched && userFetched.user_bank
                    ? userFetched.user_bank.bank_acc
                    : ""
                }
                placeholder="BANK ACCOUNT NO."
              ></input>
              <input
                className="h-12 w-full tracking-widest shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] outline-none border p-5 rounded-2xl text-primaryFontColor md:text-lg lg:text-lg lg:p-5 lg:h-10"
                type="text"
                name="bank_ifsc"
                maxLength={20}
                style={{ textTransform: "uppercase" }}
                defaultValue={
                  userFetched && userFetched.user_bank
                    ? userFetched.user_bank.bank_ifsc
                    : ""
                }
                required
                placeholder="Bank IFSC Code."
              ></input>
            </div>
          )}

        <div className="p-3 text-red-600 text-center">
          <strong>NOTE:</strong> Recheck your bank details before submitting it,
          we take no responsibility of your details to check.
        </div>
        <input
          className="h-12 w-full bg-mainColor p-0 rounded-2xl text-white md:text-lg md:h-12 lg:text-lg lg:h-10"
          type="submit"
          value="Update"
        ></input>
      </form>

      {userFetched === null && (
        <div className="overflow-hidden mt-5 m-3">
          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-1/2" />
            <ShimmerText line={1} />
          </div>
        </div>
      )}

      {/*  */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <h1 className="text-2xl mb-4">{modalState.title}</h1>
        <p>{modalState.message}</p>
      </Modal>
    </div>
  );
}
