import { useEffect, useState } from "react";
import { auth } from "../../features/firebase/firebase";
import { useAuth } from "./FirebaseAuth";
import { sendEmailVerification, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import EmailError from "../assets/email/verifError.png";

export function CompleteProfile() {
  const { currentUser } = useAuth();

  const navigate = useNavigate();
  const [timer, setTimer] = useState(30);
  const [allowResend, setAllowResend] = useState(false);
  const [userFetched, setUserFetched] = useState(null);

  // State here ...
  const [emailSent, setEmailSent] = useState(false);

  const verifyEmail = (e) => {
    const continueURL =
      userFetched && userFetched.user_mode === "affiliate"
        ? `https://www.eatoearn.com/plan`
        : `https://www.eatoearn.com`;

    if (auth.currentUser !== null && auth.currentUser !== undefined) {
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: continueURL,
        // This must be true.
        handleCodeInApp: true,
      };

      sendEmailVerification(auth.currentUser, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          setEmailSent(true);
        })
        .catch((error) => {});
    }
  };

  const handleLogout = (event) => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        window.alert(error.message);
      });
  };

  useEffect(() => {
    // User
    if (currentUser) {
      fetch(`${process.env.REACT_APP_SERVER}/users/${currentUser.uid}`, {
        method: "GET",
        mode: "cors",
      })
        .then((data) => data.json())
        .then((user_data) => {
          setUserFetched(user_data);
        })
        .catch((err) => {});
    }

    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        clearInterval(interval);
        setAllowResend(true);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timer, currentUser]);

  return (
    <div className="w-full flex items-center justify-center pb-5">
      <div className="w-full md:w-1/3 md:p-10 md:mt-10 md:rounded-xl md:shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] outline-none md:border">
        <div className="m-10">
          <h1 className="text-3xl text-center text-primaryFontColor">
            Email not verified
          </h1>
        </div>
        <div className="p-5 md:p-0 flex w-full flex-col items-center">
          <div className="p-0 md:w-56 md:h-56">
            <img src={EmailError} alt="" />
          </div>
          <div className="w-full flex flex-col items-center gap-5">
            <div className="w-full relative">
              <form
                className="flex flex-col items-center gap-4 mt-5"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <h1
                  className="text-center text-primaryFontColor select-none"
                  style={{
                    display: currentUser.emailVerified ? "none" : "block",
                  }}
                >
                  Your email has not been verified
                </h1>
                <p className="text-center text-red-600 border rounded p-1 border-dashed border-spacing-5 mx-10">
                  Verification email sent, check your spam folder for the email.
                </p>
                <p
                  className="text-center text-secondaryFontColor select-none"
                  style={{
                    display: currentUser.emailVerified ? "none" : "block",
                  }}
                >
                  Did'nt receive verification email?, resend email to "
                  {currentUser.email}"
                </p>

                {!allowResend && (
                  <p className="text-primaryFontColor font-bold text-sm">
                    {" "}
                    Resend in {timer}{" "}
                  </p>
                )}

                {!emailSent && allowResend && userFetched && (
                  <button
                    className="bg-mainColor w-full py-2 rounded-2xl text-white cursor-pointer"
                    onClick={(e) => verifyEmail(e)}
                  >
                    Resend verification email
                  </button>
                )}

                <p
                  className="text-center text-md text-green-600 m-3 mt-5"
                  style={{
                    display: emailSent ? "block" : "none",
                  }}
                >
                  Email verification link sent, please click on the link in the
                  email to verify,{" "}
                  <span className="font-bold">
                    NOTE: Check your SPAM folder.
                  </span>
                </p>
              </form>
            </div>
            <div className="mt-5 text-mainColor font-bold">
              <div className="flex items-center justify-center">
                <input
                  onClick={() => handleLogout()}
                  type="button"
                  value="SIGN OUT"
                  className="px-16 p-3 text-mainColor font-bold cursor-pointer"
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
