import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import feedback from "../assets/feedback/feedback.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { motion } from "framer-motion";

export function Feedback() {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleSubmitFeedback = () => {
    const message = `Rating: ${rating}, Comment: ${comment}`;
    window.location.href = `mailto:support@eatoearn.com?subject=EatoEarn Rating&body=${message}`;
    setRating(0);
    setComment("");
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.1 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.1 } } }}
      className="bg-gray-100 md:bg-white min-h-screen flex flex-col md:justify-center items-center"
    >
      <div className="max-w-3xl mx-auto p-8 bg-white shadow-md rounded-md h-screen md:h-auto md:border">
        <div>
          <Player src={feedback} className="player w-52 h-52" loop autoplay />
        </div>
        <h1 className="text-4xl font-bold mb-6 text-center text-mainColor">
          Give Us Your Feedback
        </h1>
        <div className="flex items-center justify-center mb-6">
          {[...Array(5)].map((star, index) => {
            const ratingValue = index + 1;
            return (
              <label key={index} className="cursor-pointer">
                <input
                  type="radio"
                  name="rating"
                  value={ratingValue}
                  className="hidden"
                  onClick={() => handleRatingChange(ratingValue)}
                />
                <FaStar
                  className={`text-3xl ${
                    ratingValue <= rating ? "text-yellow-500" : "text-gray-400"
                  }`}
                />
              </label>
            );
          })}
        </div>
        <textarea
          className="w-full p-4 mb-6 rounded-md border border-gray-300"
          rows="4"
          placeholder="Your feedback..."
          value={comment}
          onChange={(e) => setComment(e.currentTarget.value)}
        ></textarea>
        <button
          className="bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 w-full md:w-auto"
          onClick={handleSubmitFeedback}
        >
          Submit Feedback
        </button>
      </div>
    </motion.div>
  );
}
