import brand from "../assets/global/brand.png";
import { useNavigate } from "react-router-dom";

export function Auth() {
  const nav = useNavigate();

  return (
    <div className="md:rounded-xl flex items-center justify-center">
      <div className="flex h-screen flex-col items-center justify-stretch overflow-hidden md:max-h-[56rem] md:shadow-2xl md:m-20 lg:m-5 lg:w-1/3 lg:mt-10">
        <div
          className="w-full h-1/3 bg-mainColor rounded-b-3xl relative
				 	flex flex-col-reverse items-center md:rounded-t-xl md:p-10"
        >
          <div>
            <svg className="top-[3.0rem] left-[3rem] absolute">
              <circle cx="52.5" cy="52.5" r="52.5" fill="#fff" opacity="0.14" />
            </svg>

            {/* smallest */}
            <svg className="top-[2.0rem] left-[10rem] absolute">
              <circle cx="10.5" cy="10.5" r="10.5" fill="#fff" opacity="0.14" />
            </svg>

            {/* small */}
            <svg className="bottom-[-2.0rem] left-[10rem] absolute">
              <circle cx="20.5" cy="20.5" r="20.5" fill="#fff" opacity="0.14" />
            </svg>

            <svg className="bottom-[2.0rem] left-[18rem] absolute">
              <circle cx="30.5" cy="30.5" r="30.5" fill="#fff" opacity="0.14" />
            </svg>

            {/* top left */}
            <svg className="absolute top-0 left-0">
              <circle cx="0" cy="0" r="120" fill="#fff" opacity="0.14" />
            </svg>

            {/* top right */}
            <svg className="absolute top-0 right-[-8rem]">
              <circle cx="8rem" cy="0" r="120" fill="#fff" opacity="0.14" />
            </svg>

            {/* bottom left */}
            <svg className="absolute left-[-6rem] bottom-[-3rem]">
              <circle cx="96" cy="96" r="96" fill="#fff" opacity="0.14" />
            </svg>

            {/* dish */}
            <svg className="absolute right-[-11rem] bottom-[0]">
              <g opacity="0.18" transform="scale(0.7)">
                <path
                  d="M210.606,142c0,4.172-3.712,7.577-8.241,7.577H18.727c-4.529,0-8.241-3.405-8.241-7.577,0-4.155,3.712-7.543,8.241-7.543H202.365C206.894,134.453,210.606,137.841,210.606,142ZM120.158,46.985a9.615,9.615,0,0,0-19.207-.051,76.628,76.628,0,0,1,19.207.051Zm74.817,80.777H26.117a84.691,84.691,0,0,1,168.858,0ZM100.883,57.15S53.548,67.468,39.415,119.878h17.9S67.22,76.169,100.883,57.15Z"
                  fill="#fff"
                />
              </g>
            </svg>
          </div>
          <div className="w-36 h-40 bg-white rounded-[100px] absolute bottom-[-80px] flex items-center justify-center p-5">
            <img alt="brand" src={brand} className="-translate-y-5" />
          </div>
        </div>
        <p className="font-bold text-5xl mt-20 md:text-5xl lg:text-4xl lg:mt-20">
          <span className="text-mainColor">Eato</span>Earn
        </p>
        <p className="text-primaryFontColor pt-1 md:text-md lg:text-sm">
          FOOD DELIVERY
        </p>
        <p className="text-secondaryFontColor pl-5 pr-5 pt-10 text-center md:text-lg lg:text-lg lg:mx-10 lg:pt-5">
          Discover the best foods from over 1,000 restaurants and fast delivery
          to your doorstep
        </p>
        <div className="flex w-full flex-col gap-5 p-10 lg:gap-5 lg:pt-5">
          <button
            className="h-12 rounded-full bg-mainColor text-white md:text-lg lg:text-xl lg:h-10"
            onClick={() => nav("/login")}
          >
            Login
          </button>
          <button
            className="h-12 text-mainColor border-solid border-mainColor border-2 rounded-full md:text-lg lg:text-xl lg:h-10"
            onClick={() => nav("/signup")}
          >
            Create An Account
          </button>
        </div>
      </div>
    </div>
  );
}
