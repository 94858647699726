import { useEffect, useRef, useState } from "react";
import { CART, SCROLL_OFFSET } from "../../app/constants";
import { useAuth } from "../auth/FirebaseAuth";
import { els } from "../../app/utils";
import {
  ShimmerText,
  ShimmerThumbnail,
  ShimmerTitle,
} from "react-shimmer-effects";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { SheetContent } from "../utils/SheetContent";
import { useNavigate } from "react-router-dom";
import ReactImageAppear from "react-image-appear";
import { motion } from "framer-motion";
import toast from "react-hot-toast";

export function Customer({ setCartItems, userMode, banners, featuredItems }) {
  const { currentUser } = useAuth();

  const [popularItems, setPopularItems] = useState([]);
  const [mostPopularItems, setMostPopularItems] = useState([]);
  const [matches, setMatches] = useState(null);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [openSheet, setOpenSheet] = useState(false);
  const [selected, setSelected] = useState(null);
  const [isTypesOpen, setIsTypesOpen] = useState(false);
  const [favourites, setFavourites] = useState([]);
  const [showX, setShowX] = useState(false);

  const searchRef = useRef();
  const sheetRef = useRef();

  const navigate = useNavigate();

  const scrollCallback = (yOffset) => {
    els.set(SCROLL_OFFSET, yOffset);
  };

  useEffect(() => {
    //
    if (userMode === "admin" && currentUser) {
      navigate("/admin");
    }

    if (currentUser && !currentUser.emailVerified) {
      navigate("/complete");
    }

    // popular
    const params = new URLSearchParams();
    params.append("skip", 0);
    params.append("limit", 6);
    params.append("category", "popular");
    const p1 = fetch(`${process.env.REACT_APP_SERVER}/foods?${params}`, {
      method: "GET",
      mode: "cors",
    })
      .then((data) => data.json())
      .catch((err) => {});

    // Most popular
    const paramsPop = new URLSearchParams();
    paramsPop.append("skip", 0);
    paramsPop.append("limit", 6);
    paramsPop.append("category", "most_popular");
    const p2 = fetch(`${process.env.REACT_APP_SERVER}/foods?${paramsPop}`, {
      method: "GET",
      mode: "cors",
    })
      .then((data) => data.json())
      .catch((err) => {});

    const paramsGetFavs = new URLSearchParams();
    paramsGetFavs.append("skip", 0);
    paramsGetFavs.append("limit", 10);
    paramsGetFavs.append("user_uid", currentUser && currentUser.uid);
    const p3 = fetch(
      `${process.env.REACT_APP_SERVER}/favourites/?${paramsGetFavs}`,
      {
        method: "GET",
        mode: "cors",
      }
    )
      .then((data) => data.json())
      .catch(() => {});

    Promise.all([p1, p2, p3]).then(
      ([popular_items, most_popular_items, fav_items]) => {
        // banners_ && setBanners(banners_.splice(0, 2));
        // featured_ && setFeaturedItems(featured_);

        if (popular_items && popular_items.length > 0) {
          const newItemsPopular = popular_items.map((item) => {
            return {
              ...item,
              added: false,
            };
          });
          setPopularItems(newItemsPopular);
        }

        if (most_popular_items && most_popular_items.length > 0) {
          const newItemsMostPopular = most_popular_items.map((item) => {
            return {
              ...item,
              added: false,
            };
          });
          setMostPopularItems(newItemsMostPopular);
        }

        //
        if (fav_items && fav_items.length > 0) {
          setFavourites(
            fav_items.map((it) => {
              return {
                ...it,
                _id: it.id,
              };
            })
          );
        }

        // SCROLL
        setTimeout(() => {
          const scrollPosition =
            parseInt(els.get(SCROLL_OFFSET)) | parseInt(els.get("ofs"));
          if (
            scrollPosition &&
            window.location.pathname.replace("/", "") !== "view"
          ) {
            window.scrollTo({
              top: scrollPosition,
              behavior: "smooth",
            });
            els.remove(SCROLL_OFFSET);
            els.remove("ofs");
          } else {
            window.scrollTo(0, 0);
          }
        }, 0);
      }
    );
  }, [currentUser, navigate, userMode]);

  const inCart = (item, recent) => {
    const cartDataRaw = els.get(CART);
    if (cartDataRaw !== null) {
      const cartItems = JSON.parse(cartDataRaw);
      return cartItems.filter((itm) => itm.id === item.id).length === 1;
    }
    return false;
  };

  const addToCart = (item) => {
    //
    const cartDataRaw = els.get(CART);
    if (cartDataRaw !== null) {
      const cartItems = JSON.parse(cartDataRaw);
      if (!inCart(item)) {
        cartItems.push({ ...item, id: item.id });
        els.set(CART, JSON.stringify(cartItems));
        setCartItems(cartItems);
        toast.success("Added to bag");
      } else {
        const filteredCart = cartItems.filter((it) => it.id !== item.id);
        els.set(CART, JSON.stringify(filteredCart));
        setCartItems(filteredCart);
      }
    } else {
      const data = [{ ...item, id: item.id }];
      els.set(CART, JSON.stringify(data));
      setCartItems(data);
      toast.success("Added to bag");
    }
    setOpenSheet(false);
    setIsTypesOpen(false);
  };

  const handleAddToCart = (item) => {
    setSelected(item);
    setIsTypesOpen(true);
    setOpenSheet(true);
  };

  const inFavourites = (item) => {
    return favourites.filter((it) => it.id === item.id).length === 1;
  };

  const handleAddToFavourites = (item) => {
    if (currentUser) {
      if (inFavourites(item)) {
        setFavourites(favourites.filter((it) => it.id !== item.id));
      } else {
        setFavourites(favourites.concat([item]));
        toast.success("Added to favourites");
      }
      // add to favs
      fetch(`${process.env.REACT_APP_SERVER}/favourites/`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          food_id: item.id,
          user_uid: currentUser && currentUser.uid,
        }),
      }).catch((err) => {
        console.log("ADD-FAV_ERROR: ", err);
        setFavourites(favourites.filter((itm) => item.id !== itm.id));
      });
    } else {
      navigate("/login");
    }
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };

  const handleSearch = (query) => {
    setMatches(null);
    setShowX(false);

    if (query.length >= 1) {
      setShowX(true);
    }

    if (query.length >= 2) {
      debounce((query) => {
        fetch(`${process.env.REACT_APP_SERVER}/search/${query}`, {
          method: "GET",
          mode: "cors",
        })
          .then((data) => data.json())
          .then((matches) => {
            setMatches(matches);
            if (matches.length <= 0) {
              setMatches([{ food_name: "No results found", isNone: true }]);
            }
          })
          .catch((err) => {
            console.log("SEARCH-FETCH_ERROR: ", err);
          });
      }, 500)(query);
    } else if (query.length <= 0) {
      setMatches(null);
      setSelectedMatch(null);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.1 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.1 } } }}
      className="pb-20"
    >
      {/* Search */}
      <div className="flex flex-col mx-3 relative z-10">
        <div className="h-10 flex items-center mt-5 border shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] py-5 px-3 rounded-xl m-1 text-primaryFontColor md:text-lg md:p-5 md:mt-10 lg:text-xl lg:p-6">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            className="min-w-5 min-h-5"
          >
            <path
              d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
              stroke="#FC6011"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22 22L20 20"
              stroke="#FC6011"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <input
            onChange={(e) => handleSearch(e.target.value)}
            ref={searchRef}
            type="text"
            className="bg-transparent grow-10 w-full ml-5 outline-none border-r border-mainColor mr-2"
            placeholder="Start typing..."
          ></input>
          {showX && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="w-6 h-6"
              viewBox="0 0 16 16"
              onClick={() => {
                searchRef.current.value = "";
                setSelectedMatch(null);
                setShowX(false);
                setMatches(null);
              }}
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          )}
        </div>

        {/* Matches */}
        {matches && (
          <motion.div
            initial={{ y: -10, scale: 0.8 }}
            animate={{ y: 0, scale: 1 }}
            className="flex flex-col items-center mt-2 p-3 border shadow-[0px_0px_25px_-15px_rgba(0,0,0,.7)] rounded-xl  gap-3 text-primaryFontColor md:text-3xl md:py-5 md:mt-0 lg:text-xl lg:p-5 absolute top-16 lg:top-24 left-0 w-full bg-white"
          >
            {matches
              .filter((it) =>
                selectedMatch ? it.id !== selectedMatch.id : true
              )
              .map((match, index) => (
                <div
                  key={index}
                  className="border w-full flex items-center justify-center p-3 rounded-lg cursor-pointer"
                  onClick={(e) => {
                    !match.isNone && navigate(`/view?id=${match.id}`);
                  }}
                >
                  <motion.h1 initial={{ y: -10 }} animate={{ y: 0 }}>
                    {match.food_name}
                  </motion.h1>
                </div>
              ))}
          </motion.div>
        )}
      </div>

      {/* Banner */}
      <div className="sm:flex sm:flex-col md:grid md:grid-cols-2 mt-5">
        {banners &&
          banners.map((banner, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
              onClick={() => navigate(`/view?offer_tag=${banner.offer_tag}`)}
              className={`${
                index === 1 ? "hidden md:block" : ""
              } relative min-h-36 max-h-36 m-3 shadow-[0px_0px_15px_-10px_rgba(0,0,0,0.7)] rounded-3xl md:min-h-40 md:max-h-40`}
            >
              <ReactImageAppear
                className="text-white rounded-3xl w-full h-full absolute object-cover"
                src={banner.banner_img_url}
                animation="blurIn"
                placeholderClass="rounded-3xl"
                animationDuration="100ms"
              />
              <div className="absolute text-white p-5 top-0">
                <p> {banner.line_title} </p>
                <h1 className="text-4xl font-bold"> {banner.headline}</h1>

                <div className="text-sm flex items-center gap-2">
                  <p> {banner.line_action}</p>
                  <svg
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                    fill="none"
                    className="w-5 h-5"
                  >
                    <circle cx="15.5" cy="15.5" r="15.5" fill="white" />
                    <path
                      d="M16.4175 12.4592L19.9583 16L16.4175 19.5408"
                      stroke="#1C1C1C"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.0417 16L19.8592 16"
                      stroke="#1C1C1C"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </motion.div>
          ))}
      </div>

      {/* HR */}
      <div className="flex items-center mt-7 mx-3">
        <hr className="flex-grow border-t border-gray-300" />
        <span className="px-3 text-primaryFontColor font-semibold">
          EXPLORE
        </span>
        <hr className="flex-grow border-t border-gray-300" />
      </div>

      {/* Featured  items */}
      {featuredItems && (
        <div className="mt-2 flex overflow-x-auto gap-7 sm:gap-5 md:mt-5 md:gap-10 items-center px-5 py-2 md:cursor-pointer">
          {featuredItems.slice(0, 5).map((item, index) => (
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{
                opacity: 1,
                scale: 1,
                transition: { duration: 0.5 },
              }}
              onClick={() => navigate(`/view?tag=${item.title}`)}
              className="flex flex-col flex-shrink-0 justify-center items-center select-none max-w-24 md:max-w-28"
              key={index}
            >
              {item.featured_img_url && (
                <ReactImageAppear
                  className="max-w-20 max-h-20 object-cover min-w-20 min-h-20 md:min-w-28 md:min-h-28 aspect-square shadow-[0px_0px_20px_-10px_rgba(0,0,0,0.7)] rounded-full"
                  src={item.featured_img_url}
                  animation="blurIn"
                  placeholderClass="rounded-full"
                  animationDuration="100ms"
                  placeholderStyle={{
                    backgroundColor: "transparent",
                  }}
                />
              )}
              <p className="text-sm p-1 font-semibold text-primaryFontColor line-clamp-2 md:text-xl md:p-5 lg:text-xl">
                {item.title}
              </p>
            </motion.div>
          ))}
        </div>
      )}

      {/* Popular */}
      <div>
        <div className="flex mt-10 justify-between items-center md:mt-10 mx-3">
          <p className="font-bold text-primaryFontColor md:text-lg md:font-bold">
            POPULAR
          </p>
          <button
            className="text-mainColor md:text-lg"
            onClick={() => {
              scrollCallback(window.scrollY);
              navigate("/view?q=popular");
            }}
          >
            View All
          </button>
        </div>
        {popularItems.length > 0 ? (
          <div className="sm:flex sm:flex-col md:gap-0 lg:grid lg:grid-cols-3">
            {popularItems.map((item, idx) => (
              <div
                className="flex flex-col flex-shrink-0 justify-center select-none shadow-[0px_0px_20px_-10px_rgba(0,0,0,0.7)] m-3 rounded-2xl pb-3"
                key={idx}
                onClick={(e) => {
                  scrollCallback(window.scrollY);
                }}
              >
                <div className="relative">
                  <div className="w-full bg-gradient-to-b from-[#00000070] to-transparent h-14 absolute z-10 rounded-t-2xl flex items-center justify-end px-5">
                    <div
                      className="cursor-pointer"
                      onClick={() => handleAddToFavourites(item)}
                    >
                      {inFavourites(item) ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#f1414f"
                          className="w-6 h-6"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#ffffff"
                          className="w-6 h-6"
                          viewBox="0 0 16 16"
                        >
                          <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                        </svg>
                      )}
                    </div>
                  </div>
                  {item.food_thumb_url && (
                    <ReactImageAppear
                      className="w-full object-cover max-h-48 min-h-48 rounded-t-2xl md:min-h-64 md:max-h-64 lg:min-h-36 lg:max-h-36"
                      src={item.food_thumb_url}
                      animation="blurIn"
                      placeholderClass="rounded-t-2xl"
                      animationDuration="100ms"
                    />
                  )}
                </div>
                <div className="flex items-start justify-between">
                  <div>
                    <h1 className="pt-3 px-3 text-primaryFontColor font-bold line-clamp-1 text-lg md:text-lg md:pt-7 lg:pt-2">
                      {item.food_name}
                    </h1>
                    <p className="px-3 text-primaryFontColor">
                      <span className="text-mainColor">
                        {item.food_category}
                      </span>{" "}
                      • &#8377;{item.food_types[0].type_price} for one
                    </p>
                  </div>
                  <span
                    onClick={(e) => handleAddToCart(item)}
                    className="cursor-pointer block mt-3"
                  >
                    {inCart(item) ? (
                      <svg
                        width="16"
                        height="16"
                        fill="#FC6011"
                        className="w-6 h-6 lg:min-h-6 mb-1 mr-3"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0M8.5 8a.5.5 0 0 0-1 0v1.5H6a.5.5 0 0 0 0 1h1.5V12a.5.5 0 0 0 1 0v-1.5H10a.5.5 0 0 0 0-1H8.5z"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="16"
                        height="16"
                        fill="#FC6011"
                        className="w-6 h-6 lg:min-h-6 mr-3"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5"
                        />
                        <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                      </svg>
                    )}
                  </span>
                </div>
                <div>
                  <div className="flex items-center justify-between"></div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="overflow-hidden mt-5 mx-3">
            <div className="mt-3">
              <ShimmerThumbnail height={200} rounded />
              <ShimmerTitle line={1} className="w-2/3" />
              <ShimmerText line={1} />
            </div>

            <div className="mt-3">
              <ShimmerThumbnail height={200} rounded />
              <ShimmerTitle line={1} className="w-2/3" />
              <ShimmerText line={1} />
            </div>

            <div className="mt-3">
              <ShimmerThumbnail height={200} rounded />
              <ShimmerTitle line={1} className="w-2/3" />
              <ShimmerText line={1} />
            </div>
          </div>
        )}
      </div>

      {/* Most Popular */}
      <div>
        <div className="flex mt-7 justify-between items-center md:mt-10 mx-3">
          <p className="font-bold text-primaryFontColor md:text-lg md:font-bold">
            MOST POPULAR
          </p>
          <button
            className="text-mainColor md:text-lg"
            onClick={(e) => {
              scrollCallback(window.scrollY);
              navigate("/view?q=most_popular");
            }}
          >
            View All
          </button>
        </div>
        <div className="flex overflow-x-auto">
          {mostPopularItems.map((item, idx) => (
            <div
              className="flex flex-col justify-center select-none rounded-2xl shadow-[0px_0px_15px_-10px_rgba(0,0,0,0.7)] m-3 pb-3 lg:max-w-56"
              key={idx}
              onClick={(e) => {
                scrollCallback(window.scrollY);
              }}
            >
              <div className="relative">
                <div className="w-full bg-gradient-to-b from-[#00000070] to-transparent h-14 absolute z-10 rounded-t-2xl flex items-center justify-end px-5">
                  <div
                    className="cursor-pointer"
                    onClick={() => handleAddToFavourites(item, false)}
                  >
                    {inFavourites(item) ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#f1414f"
                        className="w-6 h-6"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#ffffff"
                        className="w-6 h-6"
                        viewBox="0 0 16 16"
                      >
                        <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                      </svg>
                    )}
                  </div>
                </div>
                {item.food_thumb_url && (
                  <ReactImageAppear
                    className="min-w-48 max-w-48 object-cover min-h-28 max-h-28 rounded-t-2xl aspect-[3/2] md:min-w-56 md:min-h-44 md:max-h-44 lg:min-h-32 lg:max-h-32"
                    src={item.food_thumb_url}
                    animation="blurIn"
                    placeholderClass="rounded-t-2xl"
                    animationDuration="100ms"
                  />
                )}
              </div>
              <div className="flex md:flex-row items-start justify-between">
                <div>
                  <h1 className="pt-3 px-3 text-primaryFontColor font-bold line-clamp-1 md:pt-7 lg:pt-2">
                    {item.food_name}
                  </h1>
                  <div className="px-3 text-primaryFontColor flex flex-col">
                    <span className="text-mainColor">
                      {item.food_category.replace("_", " ")}
                    </span>
                    <span>&#8377;{item.food_types[0].type_price} for one</span>
                  </div>
                </div>
                <span
                  onClick={(e) => handleAddToCart(item)}
                  className="cursor-pointer block mt-3"
                >
                  {inCart(item) ? (
                    <svg
                      width="16"
                      height="16"
                      fill="#FC6011"
                      className="w-6 h-6 lg:min-h-6 mb-1 mr-3"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0M8.5 8a.5.5 0 0 0-1 0v1.5H6a.5.5 0 0 0 0 1h1.5V12a.5.5 0 0 0 1 0v-1.5H10a.5.5 0 0 0 0-1H8.5z"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="16"
                      height="16"
                      fill="#FC6011"
                      className="w-6 h-6 lg:min-h-6 mr-3"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5"
                      />
                      <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                    </svg>
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Types select Modal */}
      {selected && isTypesOpen && (
        <div className="hidden fixed inset-0 md:flex items-center justify-center z-50">
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={() => {
              setIsTypesOpen(false);
              setOpenSheet(false);
            }}
          ></div>
          <div className="bg-white md:p-0 rounded-lg z-10 w-full mx-7 md:w-1/2">
            <div>
              <SheetContent
                item={selected}
                addToCart={addToCart}
                close={() => {
                  setOpenSheet(false);
                  setIsTypesOpen(false);
                }}
                added={inCart(selected)}
              />
            </div>
          </div>
        </div>
      )}

      {/* Bottom sheet */}
      {selected && (
        <BottomSheet
          open={openSheet}
          ref={sheetRef}
          className="fixed z-50 lg:hidden"
          // onClick={() => setOpenSheet(false)}
        >
          <SheetContent
            item={selected}
            addToCart={addToCart}
            close={() => setOpenSheet(false)}
            added={inCart(selected)}
          />
        </BottomSheet>
      )}
    </motion.div>
  );
}
