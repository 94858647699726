import { useState } from "react";
import { CART } from "../../app/constants";
import { els } from "../../app/utils";

export function SheetContent({ item, addToCart, close, added }) {
  const [qty, setQty] = useState(1);
  const [opt, setOpt] = useState(0);
  const itemTypes = item.food_types || [];
  const [price, setPrice] = useState(item.food_types[0].type_price);

  useState(() => {
    const cartItems = JSON.parse(els.get(CART));
    if (cartItems !== null && cartItems.length > 0) {
      const selectedOpt = cartItems.filter((it) => it.id === item.id);
      if (selectedOpt && selectedOpt.length === 1) {
        setQty(selectedOpt[0].qty);

        const selIndex = itemTypes
          .map((it, index) =>
            it.type_name === selectedOpt[0].opt ? index : -1
          )
          .filter((it) => it !== -1)[0];
        //
        setOpt(selIndex);
      }
    }
  }, []);

  return (
    <div className="">
      {/* Item Name*/}
      <div className="flex items-center justify-between shadow-md px-5 py-2">
        <div className="flex items-center gap-2">
          <img
            src={item.food_thumb_url}
            alt=""
            className="max-w-11 rounded-md"
          />
          <h1 className="text-primaryFontColor line-clamp-1">
            {item.food_name}
          </h1>
        </div>
        <div
          className="cursor-pointer border rounded-full p-2"
          onClick={() => close()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#f1414f"
            className="w-5 h-5"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>

      {/* item options */}
      <div className="flex items-center overflow-x-auto py-5">
        {itemTypes.map((itemType, index) => (
          <div
            key={index}
            onClick={() => {
              setOpt(index);
              setPrice(itemType.type_price);
            }}
            className={`px-5 py-2 mx-2 cursor-pointer rounded-2xl min-w-28 max-w-28 shadow-[0px_0px_15px_-10px_rgba(0,0,0,0.7)] border-mainColor ${
              index === opt ? "border" : ""
            }`}
          >
            <h1 className="text-primaryFontColor">
              {itemType.type_name[0].toUpperCase() +
                itemType.type_name.slice(1)}
            </h1>
            <p> &#8377;{itemType.type_price} </p>
          </div>
        ))}
      </div>

      {/* buttons */}
      <div className="flex gap-2 px-2 py-2 border-t">
        <div className="flex items-center border p-1 rounded-lg">
          <button
            onClick={() => setQty(qty > 1 ? qty - 1 : qty)}
            className="transition-transform hover:scale-110"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#4A4B4D"
              className="bi bi-dash"
              viewBox="0 0 16 16"
            >
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" />
            </svg>
          </button>

          <input
            type="text"
            value={qty}
            readOnly
            className="w-12 text-center text-mainColor"
          />

          <button
            onClick={() => setQty(qty + 1)}
            className="focus:outline-none transition-transform hover:scale-110"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#FC6011"
              className="bi bi-plus-lg"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
              />
            </svg>
          </button>
        </div>
        <button
          onClick={() => {
            addToCart({
              ...item,
              qty: qty,
              opt: itemTypes[opt].type_name,
              cost: price,
            });
          }}
          className="bg-mainColor hover:bg-[#fea531] text-white py-2 px-4 rounded-md w-full focus:outline-none focus:shadow-outline-blue md:shadow-none md:block"
        >
          {added ? "Remove" : <p>Add Item - &#8377;{qty * price}</p>}
        </button>
      </div>
    </div>
  );
}
