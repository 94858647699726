import { useEffect, useState } from "react";
import { useAuth } from "../auth/FirebaseAuth";
import { MAX_FETCH_ELEMENTS } from "../../app/constants";
import {
  ShimmerCategoryItem,
  ShimmerText,
  ShimmerThumbnail,
  ShimmerTitle,
} from "react-shimmer-effects";
import { useNavigate } from "react-router-dom";
import loading from "../assets/global/loading.svg";
import { motion } from "framer-motion";
import ReactImageAppear from "react-image-appear";
import nothingHere from "../assets/income/nothing.webp";

export function RecentlyViewed() {
  const { currentUser } = useAuth();

  // state here
  const [recentlyViewed, setRecentlyViewed] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const getParams = new URLSearchParams();
    getParams.append("user_uid", currentUser && currentUser.uid);
    getParams.append("skip", 0);
    getParams.append("limit", MAX_FETCH_ELEMENTS);

    if (currentUser) {
      fetch(`${process.env.REACT_APP_SERVER}/recents/?${getParams}`, {
        method: "GET",
        mode: "cors",
      })
        .then((data) => data.json())
        .then((recent_data) => {
          setRecentlyViewed(recent_data);
        })
        .catch((err) => {
          console.log("RECENTLY-VIEWED-FETCH_ERROR: ", err);
        });
    }
  }, [currentUser]);

  const loadMoreItems = (e) => {
    if (currentUser) {
      setShowLoading(true);
      //
      const getParams = new URLSearchParams();
      getParams.append("user_uid", currentUser && currentUser.uid);
      getParams.append("skip", recentlyViewed.length);
      getParams.append("limit", recentlyViewed.length + MAX_FETCH_ELEMENTS);

      if (currentUser) {
        fetch(`${process.env.REACT_APP_SERVER}/recents/?${getParams}`, {
          method: "GET",
          mode: "cors",
        })
          .then((data) => data.json())
          .then((view_items) => {
            if (view_items.length > 0) {
              setRecentlyViewed(recentlyViewed.concat(view_items));
            } else {
              setLoadingError(true);
            }
          })
          .catch((err) => {})
          .finally(() => {
            setShowLoading(false);
          });
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.1 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.1 } } }}
    >
      {currentUser ? (
        <div>
          {/* Recent */}
          <div className="pb-20">
            {/* HR */}
            <div className="flex items-center mx-3 mt-7">
              <hr className="flex-grow border-t border-gray-300" />
              <span className="px-3 text-primaryFontColor font-semibold">
                Recently Bought
              </span>
              <hr className="flex-grow border-t border-gray-300" />
            </div>
            {recentlyViewed && recentlyViewed.length > 0 ? (
              <div className="flex flex-col">
                {recentlyViewed.map((item, index) => (
                  <div
                    className="flex items-center select-none rounded-md md:items-start m-3 shadow-lg border"
                    key={index}
                    onClick={(e) => navigate(`/view?name=${item.food_name}`)}
                    //   onClick={(e) => {
                    //     scrollCallback(window.scrollY);
                    //   }}
                  >
                    {item.food_thumb_url ? (
                      <ReactImageAppear
                        className="max-w-20 max-h-20 object-cover min-h-11 rounded-l-md aspect-square md:min-w-24 md:min-h-24"
                        src={item.food_thumb_url}
                        animation="blurIn"
                        animationDuration="100ms"
                      />
                    ) : (
                      <div className="min-w-11 min-h-11 bg-slate-300 rounded-lg md:min-w-28 md:min-h-28"></div>
                    )}
                    <div className="flex flex-col justify-center">
                      <p className="pl-3 font-semibold text-primaryFontColor line-clamp-1 md:text-xl md:pl-3 md:mt-2 lg:text-xl">
                        {item.food_name}
                      </p>
                      <div className="flex gap-5 items-baseline justify-start">
                        <h1 className="text-3xl font-bold text-mainColor md:text-3xl ml-3">
                          &#8377;{item.food_price}
                        </h1>
                      </div>
                      <div className="flex flex-row gap-2 items-center justify-start lg:gap-5 lg:ml-5 min-w-56"></div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                {!recentlyViewed && (
                  <div className="overflow-hidden m-3 mt-5">
                    <div className="mt-3">
                      <ShimmerThumbnail height={200} rounded />
                      <ShimmerTitle line={1} className="w-2/3" />
                      <ShimmerText line={1} />
                    </div>

                    <div className="mt-3">
                      <ShimmerThumbnail height={200} rounded />
                      <ShimmerTitle line={1} className="w-2/3" />
                      <ShimmerText line={1} />
                    </div>

                    <div className="mt-3">
                      <ShimmerThumbnail height={200} rounded />
                      <ShimmerTitle line={1} className="w-2/3" />
                      <ShimmerText line={1} />
                    </div>
                  </div>
                )}
              </div>
            )}
            {showLoading && (
              <div className="flex items-center justify-center">
                <img src={loading} alt="" className="w-12 h-12 mt-7" />
              </div>
            )}
            {!loadingError && recentlyViewed && recentlyViewed.length > 0 && (
              <span className="flex flex-col items-center justify-center w-full px-3">
                <button
                  disabled={loadingError}
                  className="w-full p-3 rounded-2xl mt-3 border font-bold text-[#f1414f] shadow-[0px_0px_15px_-10px_rgba(0,0,0,0.7)]  disabled:bg-slate-400 md:w-full md:text-3xl lg:text-sm lg:p-2 lg:max-w-56"
                  onClick={(e) => loadMoreItems(e)}
                >
                  Load More
                </button>
              </span>
            )}
            {!loadingError && recentlyViewed && recentlyViewed.length <= 0 && (
              <div className="flex flex-col items-center justify-center mt-16 p-5">
                <img src={nothingHere} alt="" />
                <p className="text-xl text-center mt-5 text-slate-400">
                  Nothing here yet
                </p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="overflow-hidden m-3">
          <ShimmerCategoryItem />
          <ShimmerThumbnail center height={50} width={300} />

          <div className="flex flex-row items-center justify-center gap-4 mt-2">
            <div>
              <ShimmerThumbnail height={70} width={70} rounded />
              <ShimmerText line={1} />
            </div>
            <div>
              <ShimmerThumbnail height={70} width={70} rounded />
              <ShimmerText line={1} />
            </div>
            <div>
              <ShimmerThumbnail height={70} width={70} rounded />
              <ShimmerText line={1} />
            </div>
            <div>
              <ShimmerThumbnail height={70} width={70} rounded />
              <ShimmerText line={1} />
            </div>
          </div>

          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-2/3" />
            <ShimmerText line={1} />
          </div>

          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-2/3" />
            <ShimmerText line={1} />
          </div>

          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-2/3" />
            <ShimmerText line={1} />
          </div>

          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-2/3" />
            <ShimmerText line={1} />
          </div>
        </div>
      )}
    </motion.div>
  );
}
