import { useEffect, useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import * as anim_done from "../assets/email/anim_done.json";
import * as anim_clock from "../assets/payments/clock_anim.json";
import * as anim_error from "../assets/payments/pay_error_anim.json";
import { els } from "../../app/utils";
import {
  CART,
  ORDER_FOOD,
  ORDER_PLAN,
  PAY_DATA,
  STATUS_ERROR,
  STATUS_PENDING,
  STATUS_SUCCESS,
  STATUS_SUCCESS_INIT,
} from "../../app/constants";
import { useNavigate } from "react-router-dom";
import Modal from "../utils/Modal";

export function PaymentSuccess({ setCartItems }) {
  // State here ...
  const [paymentResponse, setPaymentResponse] = useState(STATUS_PENDING);
  const [donePayment, setDonePayment] = useState(false);
  const [retrySeconds, setRetrySeconds] = useState(2);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalState, setModalState] = useState({});
  const [payType, setPayType] = useState(ORDER_FOOD);

  const navigate = useNavigate();

  useEffect(() => {
    const dataRaw = els.get(PAY_DATA);
    setPayType(JSON.parse(dataRaw).pay_type);
    if (dataRaw) {
      fetch(`${process.env.REACT_APP_SERVER}/status/`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json", // Specify the content type as JSON
        },
        body: dataRaw,
      })
        .then((response) => response.json())
        .then((payResponse) => {
          if (
            payResponse.status === STATUS_SUCCESS ||
            payResponse.status === STATUS_SUCCESS_INIT
          ) {
            setPaymentResponse(STATUS_SUCCESS);
            setDonePayment(true);
            els.remove(PAY_DATA);
            els.remove(CART);
            setCartItems([]);
          } else if (payResponse.status === STATUS_ERROR) {
            setPaymentResponse(STATUS_ERROR);
            setDonePayment(true);
          } else if (payResponse.status === STATUS_PENDING) {
            setPaymentResponse(STATUS_PENDING);
            setTimeout(() => {
              setRetrySeconds(retrySeconds + 3);
            }, Math.floor(retrySeconds * 1000));
          }
        })
        .catch((err) => {
          setModalState({
            title: "Error",
            message: "Error checking payment status, please try again",
          });
          setModalOpen(true);
        });
    } else {
      navigate("/");
    }
  }, [retrySeconds]);

  return (
    <div className="flex items-center justify-center lg:scale-90">
      <div className="w-full md:m-20 md:rounded-xl md:shadow-2xl md:p-5 lg:w-1/2">
        <div className="m-10 lg:mb-0 lg:mt-10">
          {paymentResponse === STATUS_SUCCESS ? (
            <h1 className="text-4xl text-center text-primaryFontColor lg:text-3xl">
              Thank you
            </h1>
          ) : (
            <h1 className="text-4xl text-center text-primaryFontColor lg:text-3xl">
              Validating Payment
            </h1>
          )}

          {!donePayment && (
            <div>
              <p className="text-center text-md text-secondaryFontColor p-5">
                {paymentResponse === STATUS_PENDING
                  ? "Payment pending, please wait"
                  : "Please wait until we process your payment"}
              </p>
              <Player
                src={anim_clock}
                className="player w-52 h-52"
                loop
                autoplay
              />
            </div>
          )}
          {paymentResponse === STATUS_ERROR && (
            <div>
              <p className="text-center text-md text-secondaryFontColor p-5">
                Payment Failed
              </p>
              <div className="flex flex-col items-center">
                <Player
                  src={anim_error}
                  className="player lg:w-60 lg:h-60"
                  keepLastFrame
                  autoplay
                />
                <input
                  className="h-12 w-1/2 bg-mainColor p-0 rounded-3xl text-white mb-20"
                  type="button"
                  value="Continue"
                  onClick={() => navigate("/home")}
                ></input>
              </div>
            </div>
          )}
        </div>
        {paymentResponse === STATUS_SUCCESS && (
          <p className="text-center text-md text-secondaryFontColor p-5">
            Payment successfull
          </p>
        )}
        {paymentResponse === STATUS_SUCCESS && (
          <div className="m-10 lg:m-0">
            <div className="flex flex-col items-center">
              <Player
                src={anim_done}
                className="player lg:w-60 lg:h-60"
                keepLastFrame
                autoplay
              />
              <input
                className="h-12 w-1/2 bg-mainColor p-0 rounded-3xl text-white mb-20"
                type="button"
                value="Continue"
                onClick={() =>
                  payType === ORDER_PLAN
                    ? navigate("/plan")
                    : navigate("/history")
                }
              ></input>
            </div>
          </div>
        )}
      </div>
      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false);
          window.location.reload();
        }}
      >
        <h1 className="text-2xl mb-4">{modalState.title}</h1>
        <p>{modalState.message}</p>
      </Modal>
    </div>
  );
}
