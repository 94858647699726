import { useEffect, useRef, useState } from "react";
import { useAuth } from "../auth/FirebaseAuth";
import {
  ShimmerCategoryItem,
  ShimmerText,
  ShimmerThumbnail,
  ShimmerTitle,
} from "react-shimmer-effects";
import noAddressImg from "../assets/address/no_address.avif";
import Modal from "../utils/Modal";
import { motion } from "framer-motion";
import { ADDRESS_DEFAULT } from "../../app/constants";
import { els } from "../../app/utils";

export function Addresses({
  addresses,
  setAddresses,
  setCurrentAddress,
  setSaved,
}) {
  const { currentUser } = useAuth();

  // state here ...
  const [locationInfo, setLocationInfo] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalState, setModalState] = useState({});
  //
  const [completeAddress, setCompleteAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [addressType, setAddressType] = useState("Home");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [detected, setDetected] = useState("");

  const addressFormRef = useRef();

  const handleAddressTypeChange = (event) => {
    setAddressType(event.target.value);
  };

  const handleSaveVerified = (formDataJSON) => {
    fetch(`${process.env.REACT_APP_SERVER}/addresses/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formDataJSON),
    })
      .then((data) => data.json())
      .then((address_saved) => {
        if (address_saved.user_uid === currentUser.uid) {
          loadInititalAddresses();
        }
      })
      .catch((err) => {
        setModalState({
          title: "Error",
          message: "Network error occured, please try again",
        });
        setModalOpen(true);
      })
      .finally(() => {
        setIsDialogOpen(false);
      });
  };

  const handleSave = (e) => {
    e.preventDefault();
    const form = new FormData(addressFormRef.current);
    form.append("address_type", addressType.toLocaleLowerCase());
    form.append("user_uid", currentUser && currentUser.uid);

    if (form.get("address_type") === "other") {
      form.delete("address_type");
      form.append("address_type", form.get("other_name").toLowerCase());
      form.delete("other_name");
    }

    const _exists = addresses.filter(
      (it) => it.address_type === form.get("address_type")
    ).length;

    if (_exists === 1) {
      setModalState({
        title: "Error",
        message: "Name already added, use different name",
      });
      setModalOpen(true);
      return;
    }

    const formDataJSON = Object.fromEntries(form);

    // Check Availalility...
    if (currentUser) {
      fetch(
        `${process.env.REACT_APP_SERVER}/pincodes/${formDataJSON.address_pincode}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json", // Specify the content type as JSON
          },
        }
      )
        .then((data) => data.json())
        .then((pincode_data) => {
          if (pincode_data.index === -1 || pincode_data.charges === -1.0) {
            setModalState({
              title: "Not available",
              message:
                "Sorry, our services are not available at this location yet, please choose different location",
            });
            setModalOpen(true);
          } else {
            handleSaveVerified(formDataJSON);
          }
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInititalAddresses();
  }, [currentUser]);

  const handleDeleteAddress = (address) => {
    fetch(`${process.env.REACT_APP_SERVER}/delete_address/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_uid: currentUser.uid,
        id: address.id,
      }),
    })
      .then((resp) => {
        const filtered_ = addresses.filter((it) => it.id !== address.id);
        const ELS_DATA = JSON.parse(els.get(ADDRESS_DEFAULT));
        //
        if (ELS_DATA.id === address.id && filtered_.length > 0) {
          els.set(ADDRESS_DEFAULT, JSON.stringify(filtered_[0]));
        } else {
          els.remove(ADDRESS_DEFAULT);
        }
        //
        setAddresses(filtered_);
      })
      .catch((err) => {
        setModalState({
          title: "Error",
          message: "Network error occured, please try again",
        });
        setModalOpen(true);
      });
  };

  const getLocationAddress = (e) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          const reverseGeoURL = `https://geocode.maps.co/reverse?lat=${latitude}&lon=${longitude}&api_key=${process.env.REACT_APP_GEOCODE_KEY}`;
          fetch(reverseGeoURL, {
            method: "GET",
          })
            .then((raw) => raw.json())
            .then((locationInfo_) => {
              // console.log(locationInfo);
              setPincode(locationInfo_.address.postcode || "");
              setLocationInfo(locationInfo_);
              setDetected(
                `${
                  locationInfo_.address.city ||
                  locationInfo_.address.state_district ||
                  "Srinagar"
                }, ${locationInfo_.address.postcode || ""}`
              );
            });
        },
        (error) => {
          // console.error("Error getting location:", error.message);
        }
      );
    }
  };

  const loadInititalAddresses = (e) => {
    const paramsGet = new URLSearchParams();
    paramsGet.append("user_uid", currentUser && currentUser.uid);

    if (currentUser) {
      fetch(`${process.env.REACT_APP_SERVER}/addresses?${paramsGet}`, {
        method: "GET",
        mode: "cors",
      })
        .then((data) => data.json())
        .then((address_data) => {
          if (addresses.length !== address_data.length) {
            setAddresses(address_data);
            els.set(ADDRESS_DEFAULT, JSON.stringify(address_data[0]));
            setCurrentAddress(address_data[0].address_detected);
            setSaved(address_data[0]);
          }
        })
        .catch((err) => {
          setModalState({
            title: "Error",
            message: "Network error occured, please try again",
          });
          setModalOpen(true);
        });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.1 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.1 } } }}
      className="pb-20"
    >
      {currentUser ? (
        <div className="">
          {/* Addresses */}
          <div className="">
            {addresses && addresses.length > 0 && (
              <div className="flex mt-3 justify-between items-center mx-3">
                <h1 className="font-bold text-primaryFontColor md:text-lg">
                  My Addresses
                </h1>
              </div>
            )}
            {addresses ? (
              <div>
                {addresses.length > 0 ? (
                  <div className="sm:flex sm:flex-col md:grid md:grid-cols-2 gap-0 select-none">
                    {addresses.map((address, index) => (
                      <div
                        className="max-w-md m-3 p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700"
                        key={index}
                      >
                        <div className="flex items-center justify-between mb-3">
                          <h5 className="font-bold leading-none text-gray-900 dark:text-white">
                            {address.address_type.toUpperCase()}
                          </h5>
                          <p
                            className="text-sm font-medium text-red-400 cursor-pointer text-bold"
                            onClick={(e) => handleDeleteAddress(address)}
                          >
                            Delete
                          </p>
                        </div>
                        <div className="flow-root">
                          <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                            <li className="">
                              <div className="flex items-center">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                    Detected Address
                                  </p>
                                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                    {address.address_detected}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li className="py-2">
                              <div className="flex items-center ">
                                <div className="flex-shrink-0"></div>
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                    Complete Address
                                  </p>
                                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                    {address.address_complete}
                                  </p>
                                </div>
                              </div>
                            </li>
                            {address.address_floor.length > 0 && (
                              <li className="py-2">
                                <div className="flex items-center ">
                                  <div className="flex-1 min-w-0">
                                    <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                      Floor
                                    </p>
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                      {address.address_floor}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            )}
                            {address.address_landmark.length > 0 && (
                              <li className="pt-2 pb-0">
                                <div className="flex items-center ">
                                  <div className="flex-1 min-w-0">
                                    <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                      Landmark
                                    </p>
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                      {address.address_landmark}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    ))}
                    <div
                      className="flex flex-col items-center justify-center p-10 mx-3 gap-3 text-primaryFontColor border rounded-xl hover:bg-slate-50 cursor-pointer"
                      onClick={() => {
                        getLocationAddress();
                        setIsDialogOpen(true);
                      }}
                    >
                      <svg
                        fill="#EF4F5F"
                        width="24"
                        height="24"
                        viewBox="0 0 20 20"
                        aria-labelledby="icon-svg-title- icon-svg-desc-"
                        role="img"
                        className="sc-rbbb40-0 fmIpur"
                      >
                        <path d="M1.5 10C1.5 5.3125 5.3125 1.5 10 1.5C14.6875 1.5 18.5 5.3125 18.5 10C18.5 14.6875 14.6875 18.5 10 18.5C5.3125 18.5 1.5 14.6875 1.5 10ZM0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0C4.5 0 0 4.5 0 10ZM14 9.25H10.75V6C10.75 5.5625 10.4375 5.25 10 5.25C9.5625 5.25 9.25 5.5625 9.25 6V9.25H6C5.5625 9.25 5.25 9.5625 5.25 10C5.25 10.4375 5.5625 10.75 6 10.75H9.25V14C9.25 14.375 9.5625 14.75 10 14.75C10.4375 14.75 10.75 14.4375 10.75 14V10.75H14C14.4375 10.75 14.75 10.4375 14.75 10C14.75 9.5625 14.4375 9.25 14 9.25Z"></path>
                      </svg>
                      <h1> Add address </h1>
                    </div>
                  </div>
                ) : (
                  <div className="m-3">
                    <div className="flex flex-col items-center justify-center gap-7 text-primaryFontColor mt-10">
                      <img src={noAddressImg} alt="" className="max-w-40" />
                      <h1> You haven't added any address yet </h1>
                    </div>
                    <div
                      className="flex flex-col items-center justify-center p-10 gap-3 text-primaryFontColor border rounded-xl hover:bg-slate-50 cursor-pointer mt-11"
                      onClick={() => {
                        getLocationAddress();
                        setIsDialogOpen(true);
                      }}
                    >
                      <svg
                        fill="#EF4F5F"
                        width="24"
                        height="24"
                        viewBox="0 0 20 20"
                        aria-labelledby="icon-svg-title- icon-svg-desc-"
                        role="img"
                        className="sc-rbbb40-0 fmIpur"
                      >
                        <path d="M1.5 10C1.5 5.3125 5.3125 1.5 10 1.5C14.6875 1.5 18.5 5.3125 18.5 10C18.5 14.6875 14.6875 18.5 10 18.5C5.3125 18.5 1.5 14.6875 1.5 10ZM0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0C4.5 0 0 4.5 0 10ZM14 9.25H10.75V6C10.75 5.5625 10.4375 5.25 10 5.25C9.5625 5.25 9.25 5.5625 9.25 6V9.25H6C5.5625 9.25 5.25 9.5625 5.25 10C5.25 10.4375 5.5625 10.75 6 10.75H9.25V14C9.25 14.375 9.5625 14.75 10 14.75C10.4375 14.75 10.75 14.4375 10.75 14V10.75H14C14.4375 10.75 14.75 10.4375 14.75 10C14.75 9.5625 14.4375 9.25 14 9.25Z"></path>
                      </svg>
                      <h1> Add address </h1>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="overflow-hidden mt-5 m-3">
                <div className="mt-3">
                  <ShimmerThumbnail height={200} rounded />
                  <ShimmerTitle line={1} className="w-2/3" />
                  <ShimmerText line={1} />
                </div>

                <div className="mt-3">
                  <ShimmerThumbnail height={200} rounded />
                  <ShimmerTitle line={1} className="w-2/3" />
                  <ShimmerText line={1} />
                </div>

                <div className="mt-3">
                  <ShimmerThumbnail height={200} rounded />
                  <ShimmerTitle line={1} className="w-2/3" />
                  <ShimmerText line={1} />
                </div>
              </div>
            )}
          </div>

          {/* Add new */}
          <div className="">
            {isDialogOpen && (
              <div className="fixed inset-0 flex items-end justify-center md:items-center md:w-full z-50">
                <div className="fixed inset-0 bg-black opacity-50"></div>

                <form
                  ref={addressFormRef}
                  className="p-6 w-full bg-white md:rounded-lg shadow-lg lg:min-w-96 lg:px-10 transform transition-transform duration-300 flex flex-col items-start justify-end h-auto md:w-auto"
                  onSubmit={(e) => handleSave(e)}
                >
                  <div className="w-full flex items-center justify-between my-5">
                    <h1 className="text-xl"> Add Address </h1>
                    <svg
                      onClick={() => setIsDialogOpen(false)}
                      fill="#1C1C1C"
                      width="24"
                      height="24"
                      viewBox="0 0 20 20"
                      aria-labelledby="icon-svg-title- icon-svg-desc-"
                      role="img"
                      className="cursor-pointer"
                    >
                      <title>cross</title>
                      <path d="M11.42 10.42l3.54-3.54c0.38-0.4 0.38-1.040 0-1.42s-1.020-0.4-1.42 0l-3.54 3.54-3.54-3.54c-0.4-0.4-1.020-0.4-1.42 0s-0.38 1.020 0 1.42l3.54 3.54-3.54 3.54c-0.38 0.38-0.38 1.020 0 1.42 0.2 0.18 0.46 0.28 0.72 0.28s0.5-0.1 0.7-0.28l3.54-3.56 3.54 3.56c0.2 0.18 0.46 0.28 0.72 0.28s0.5-0.1 0.7-0.28c0.38-0.4 0.38-1.040 0-1.42l-3.54-3.54z"></path>
                    </svg>
                  </div>

                  <h1 className="text-primaryFontColor py-2">DELIVERY AREA</h1>

                  <div className="w-full border flex items-center justify-between rounded-md sm:flex-wrap py-1 px-2 text-gray-700 mb-4 leading-tight focus:outline-none focus:shadow-outline">
                    <div className="flex gap-2 p-1 items-center justify-start w-full">
                      <svg
                        fill="#256FEF"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        role="img"
                      >
                        <path d="M1.5 10C1.5 5.3125 5.3125 1.5 10 1.5C14.6875 1.5 18.5 5.3125 18.5 10C18.5 14.6875 14.6875 18.5 10 18.5C5.3125 18.5 1.5 14.6875 1.5 10ZM0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0C4.5 0 0 4.5 0 10ZM13.75 7.25L8.75 12.1875L6.25 9.75C5.9375 9.4375 5.5 9.4375 5.1875 9.75C4.875 10 4.875 10.5 5.1875 10.8125L8.1875 13.8125C8.3125 13.9375 8.5 14.0625 8.75 14.0625C8.9375 14.0625 9.125 14 9.25 13.8125L14.75 8.3125C15.0625 8 15.0625 7.5625 14.75 7.25C14.4375 6.9375 14 6.9375 13.75 7.25Z"></path>
                      </svg>

                      <input
                        type="text"
                        name="address_detected"
                        className="text-ellipsis w-full outline-none"
                        onChange={(e) => {
                          setDetected(e.target.value);
                          const parts = e.target.value.split(",");
                          if (parts.length === 2) {
                            setPincode(parts[1].trim());
                          }
                        }}
                        value={detected}
                        placeholder="Locating..."
                      ></input>
                    </div>
                  </div>

                  {addressType === "Other" && (
                    <input
                      className="w-full border rounded-md py-3 px-3 text-gray-700 mb-4 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="other_name"
                      placeholder="Name like Home2, Work2"
                      required
                    />
                  )}

                  <input
                    className="w-full border rounded-md py-3 px-3 text-gray-700 mb-4 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    name="address_complete"
                    placeholder="Complete Address *"
                    required
                    value={completeAddress}
                    onChange={(e) => setCompleteAddress(e.target.value)}
                  />

                  <input
                    className="w-full border rounded-md py-3 px-3 text-gray-700 mb-4 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    name="address_pincode"
                    placeholder="Pincode *"
                    required
                    value={pincode}
                    onChange={(e) => {
                      if (detected.split(",").length >= 1) {
                        setDetected(
                          detected.split(",")[0] + ", " + e.target.value
                        );
                      }
                      setPincode(e.target.value);
                    }}
                  />

                  <input
                    className="w-full border rounded-md py-3 px-3 text-gray-700 mb-4 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    name="address_floor"
                    placeholder="Floor ( optional )"
                  />

                  <input
                    className="w-full border rounded-md py-3 px-3 text-gray-700 mb-4 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    name="address_landmark"
                    placeholder="Nearby Landmark ( optional )"
                  />

                  <div className="mb-4">
                    <input
                      type="radio"
                      value="Home"
                      checked={addressType === "Home"}
                      onChange={handleAddressTypeChange}
                      disabled={
                        addresses.length > 0 &&
                        addresses.filter((it) => it.address_type === "home")
                          .length === 1
                      }
                      className="form-radio text-blue-500 focus:outline-none focus:shadow-outline-blue"
                    />
                    <span className="ml-2">Home</span>

                    <input
                      type="radio"
                      value="Work"
                      disabled={
                        addresses.length > 0 &&
                        addresses.filter((it) => it.address_type === "work")
                          .length === 1
                      }
                      checked={addressType === "Work"}
                      onChange={handleAddressTypeChange}
                      className="form-radio text-blue-500 focus:outline-none focus:shadow-outline-blue ml-4"
                    />
                    <span className="ml-2">Work</span>

                    <input
                      type="radio"
                      value="Hotel"
                      disabled={
                        addresses.length > 0 &&
                        addresses.filter((it) => it.address_type === "hotel")
                          .length === 1
                      }
                      checked={addressType === "Hotel"}
                      onChange={handleAddressTypeChange}
                      className="form-radio text-blue-500 focus:outline-none focus:shadow-outline-blue ml-4"
                    />
                    <span className="ml-2">Hotel</span>

                    <input
                      type="radio"
                      value="Other"
                      checked={addressType === "Other"}
                      onChange={handleAddressTypeChange}
                      className="form-radio text-blue-500 focus:outline-none focus:shadow-outline-blue ml-4"
                    />
                    <span className="ml-2">Other</span>
                  </div>
                  <div className="h-10" />
                  <div className="shadow-[0px_0px_15px_-10px_rgba(0,0,0,0.7)] rounded-b-lg fixed bottom-0 left-0 p-3 w-full bg-white">
                    <input
                      type="submit"
                      value="Save and Proceed"
                      className="bg-[#f1414f] hover:bg-[#e03546] text-white py-2 px-4 rounded-md w-full focus:outline-none focus:shadow-outline-blue md:w-full md:shadow-none md:block"
                    ></input>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="overflow-hidden m-3">
          <ShimmerCategoryItem />
          <ShimmerThumbnail center height={50} width={300} />

          <div className="flex flex-row items-center justify-center gap-4 mt-2">
            <div>
              <ShimmerThumbnail height={70} width={70} rounded />
              <ShimmerText line={1} />
            </div>
            <div>
              <ShimmerThumbnail height={70} width={70} rounded />
              <ShimmerText line={1} />
            </div>
            <div>
              <ShimmerThumbnail height={70} width={70} rounded />
              <ShimmerText line={1} />
            </div>
            <div>
              <ShimmerThumbnail height={70} width={70} rounded />
              <ShimmerText line={1} />
            </div>
          </div>

          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-2/3" />
            <ShimmerText line={1} />
          </div>

          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-2/3" />
            <ShimmerText line={1} />
          </div>

          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-2/3" />
            <ShimmerText line={1} />
          </div>

          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-2/3" />
            <ShimmerText line={1} />
          </div>
        </div>
      )}

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false);
          loadInititalAddresses();
        }}
      >
        <h1 className="text-2xl mb-4">{modalState.title}</h1>
        <p>{modalState.message}</p>
      </Modal>
    </motion.div>
  );
}
