export const USER_SET = "user_set";

export const COMMAND_INSERT = "1";
export const COMMAND_UPDATE = "2";
export const COMMAND_DELETE = "3";
export const COMMAND_GET = "4";
export const COMMAND_GET_ACTIVE_PLAN = "5";
export const COMMAND_GET_ONE = "6";
export const COMMAND_SEARCH = "7";
export const COMMAND_GET_ADMIN = "8";
export const COMMAND_GET_ADMIN_BY_DATE = "9";
export const COMMAND_MAKE_BENIFIT_ORDER = "10";
export const COMMAND_GET_BY_IDS = "11";
export const COMMAND_GET_INCOME = "12";

export const USER_LOGGED = "user_logged";
export const USER_REF = "sponsor";

export const ADDRESS_DEFAULT = "address_default";

export const STATUS_SUCCESS = 1;
export const STATUS_ERROR = 0;
export const STATUS_NO_ADMIN = 2;
export const STATUS_PENDING = 3;
export const STATUS_SUCCESS_INIT = 4;

export const ORDER_PLAN = 1;
export const ORDER_FOOD = 2;

export const PAY_DATA = "pay_data";
export const MAX_FETCH_ELEMENTS = 3;

export const NAV_MENU = "menu";
export const NAV_MORE = "more";
export const NAV_FAV = "favourites";
export const NAV_CART = "cart";
export const NAV_PLANS = "plans";
export const NAV_VIEW = "view";

// Admin
export const MODE_FOOD_CREATE = "create_food";
export const MODE_FOOD_DELETE = "delete_food";
export const MODE_FOOD_VIEW = "view_food";
export const NAV_ADMIN_HOME = "admin";
export const NAV_ADMIN_PLAN = "admin_plan";
export const NAV_ADMIN_EARNINGS = "admin_earnings";
export const NAV_ADMIN_BANNER = "admin_banner";
export const NAV_ADMIN_ACCESS = "admin_access";
export const NAV_ADMIN_PINCODES = "admin_pincodes";
export const NAV_ADMIN_FEATURED = "admin_featured";

export const NAV_ADMIN_ORDERS = "admin_orders";
export const SCROLL_OFFSET = "yoffset";
export const CART = "cart";

export const BANNER_DATA = "banners";
export const FEATURED_DATA = "featured";
