import React, { useEffect } from "react";
import { motion } from "framer-motion";

const RefundReturnPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.1 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.1 } } }}
      className="container mx-auto mt-8 p-4 pb-20"
    >
      <h1 className="text-3xl font-bold mb-4">Refund and Return Policy</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Refund Policy</h2>
        <p className="mb-2">
          After receiving your return and inspecting the condition of your item,
          we will process your exchange. Please allow at least five (5) days
          from the receipt of your item to process your exchange. We will notify
          you by email when your return has been processed.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">EXCEPTIONS</h2>
        <p className="mb-2">
          For defective or damaged products, please contact us at the contact
          details below to arrange a refund or exchange.
        </p>
        <br />
        <span className="font-bold">Please Note</span>
        <li> Sale items are FINAL SALE and cannot be returned.</li>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Return Policy</h2>
        <p className="mb-2">
          At EatoEarn.com, we highly value your patronage and aim to deliver an
          unparalleled culinary experience. Should you decide to cancel your
          order within the initial 60 seconds of placement, we are pleased to
          inform you that we will promptly process a full refund to the original
          payment source, adhering to the standard banking policy with an
          anticipated processing time of 5-7 working days. However, please be
          mindful that beyond the initial 60-second timeframe, regrettably, we
          are unable to accommodate refund requests or returns. We encourage our
          esteemed customers to carefully review their orders before confirming,
          ensuring complete satisfaction with their selections.
        </p>
        <p className="mb-2">
          At EatoEarn.com, customer satisfaction is paramount, and our dedicated
          support team is readily available to address any inquiries or concerns
          you may have. We sincerely appreciate your trust in our services, and
          we look forward to continuing to serve you with excellence. Thank you
          for choosing EatoEarn.com for your culinary delights.
        </p>
      </section>
      <section>
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        <p className="mb-2">
          If you have any questions or concerns about our Refund and Return
          Policy, please contact us at:
        </p>
        <p className="font-bold">support@eatoearn.com</p>
      </section>
    </motion.div>
  );
};

export default RefundReturnPolicy;
