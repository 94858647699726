import { useEffect, useRef, useState } from "react";
import { useAuth } from "../auth/FirebaseAuth";
import { CART, MAX_FETCH_ELEMENTS } from "../../app/constants";
import {
  ShimmerCategoryItem,
  ShimmerText,
  ShimmerThumbnail,
  ShimmerTitle,
} from "react-shimmer-effects";
import { useNavigate } from "react-router-dom";
import loading from "../assets/global/loading.svg";
import { els } from "../../app/utils";
import { SheetContent } from "../utils/SheetContent";
import { BottomSheet } from "react-spring-bottom-sheet";
import { motion } from "framer-motion";
import ReactImageAppear from "react-image-appear";
import nothingHere from "../assets/income/nothing.webp";

export function Favourites({ setCartItems }) {
  const { currentUser } = useAuth();

  // state here
  const [favourites, setFavourites] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [openSheet, setOpenSheet] = useState(false);
  const [selected, setSelected] = useState(null);
  const [isTypesOpen, setIsTypesOpen] = useState(false);

  const navigate = useNavigate();
  const sheetRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    const paramsGet = new URLSearchParams();
    paramsGet.append("skip", 0);
    paramsGet.append("limit", MAX_FETCH_ELEMENTS);
    paramsGet.append("user_uid", currentUser.uid);

    if (currentUser) {
      fetch(`${process.env.REACT_APP_SERVER}/favourites?${paramsGet}`, {
        method: "GET",
        mode: "cors",
      })
        .then((data) => data.json())
        .then((favourites) => {
          setFavourites(favourites);
        });
    }
  }, [currentUser]);

  const loadMoreItems = (e) => {
    setShowLoading(true);

    const paramsGet = new URLSearchParams();
    paramsGet.append("skip", favourites.length);
    paramsGet.append("limit", favourites.length + MAX_FETCH_ELEMENTS);
    paramsGet.append("user_uid", currentUser.uid);

    if (currentUser) {
      fetch(`${process.env.REACT_APP_SERVER}/favourites?${paramsGet}`, {
        method: "GET",
        mode: "cors",
      })
        .then((data) => data.json())
        .then((new_favourites) => {
          if (new_favourites.length > 0) {
            setFavourites(favourites.concat(new_favourites));
          } else {
            setLoadingError(true);
          }
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };

  const inFavourites = (item) => {
    if (favourites === null) return false;
    return favourites.filter((it) => it.id === item.id).length === 1;
  };

  const handleAddToFavourites = (item) => {
    if (currentUser) {
      if (inFavourites(item)) {
        setFavourites(favourites.filter((it) => it.id !== item.id));
      } else {
        setFavourites(favourites.concat([item]));
      }
      // add to favs
      fetch(`${process.env.REACT_APP_SERVER}/favourites/`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          food_id: item.id,
          user_uid: currentUser && currentUser.uid,
        }),
      }).catch((err) => {
        setFavourites(favourites.filter((itm) => item.id !== itm.id));
      });
    } else {
      navigate("/login");
    }
  };

  const inCart = (item) => {
    const cartDataRaw = els.get(CART);
    if (cartDataRaw !== null) {
      const cartItems = JSON.parse(cartDataRaw);
      return cartItems.filter((itm) => itm.id === item.id).length === 1;
    }
    return false;
  };

  const handleAddToCart = (item) => {
    setSelected(item);
    setIsTypesOpen(true);
    setOpenSheet(true);
  };

  const addToCart = (item) => {
    const cartDataRaw = els.get(CART);
    if (cartDataRaw !== null) {
      const cartItems = JSON.parse(cartDataRaw);
      if (!inCart(item)) {
        cartItems.push({ ...item, id: item.id });
        els.set(CART, JSON.stringify(cartItems));
        setCartItems(cartItems);
      } else {
        const filteredCart = cartItems.filter((it) => it.id !== item.id);
        els.set(CART, JSON.stringify(filteredCart));
        setCartItems(filteredCart);
      }
    } else {
      const data = [{ ...item, id: item.id }];
      els.set(CART, JSON.stringify(data));
      setCartItems(data);
    }
    setOpenSheet(false);
    setIsTypesOpen(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.1 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.1 } } }}
      className="pb-20"
    >
      {currentUser ? (
        <div>
          {/* Recent */}
          <div>
            {/* HR */}
            <div className="flex items-center mx-3 mt-7">
              <hr className="flex-grow border-t border-gray-300" />
              <span className="px-3 text-primaryFontColor font-semibold">
                Favourites
              </span>
              <hr className="flex-grow border-t border-gray-300" />
            </div>

            {favourites && favourites.length > 0 ? (
              <div className="sm:flex sm:flex-col sm:gap-5 items-start justify-center md:grid md:grid-cols-2 lg:grid-cols-3">
                {favourites &&
                  favourites.map((item, index) => (
                    <div
                      className="flex flex-col flex-shrink-0 justify-center select-none shadow-[0px_0px_20px_-10px_rgba(0,0,0,0.7)] m-3 rounded-2xl pb-3"
                      key={index}
                    >
                      <div className="relative">
                        <div className="w-full bg-gradient-to-b from-[#00000070] to-transparent h-14 absolute z-10 rounded-t-2xl flex items-center justify-end px-5">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleAddToFavourites(item)}
                          >
                            {inFavourites(item) ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#f1414f"
                                className="w-6 h-6"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#ffffff"
                                className="w-6 h-6"
                                viewBox="0 0 16 16"
                              >
                                <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                              </svg>
                            )}
                          </div>
                        </div>
                        {item.food_thumb_url && (
                          <ReactImageAppear
                            className="w-full max-h-48 object-cover min-h-48 rounded-t-2xl md:min-h-64 md:max-h-64 lg:min-h-36 lg:max-h-36"
                            src={item.food_thumb_url}
                            animation="blurIn"
                            animationDuration="100ms"
                            placeholderClass="rounded-t-2xl"
                            key={item.id}
                          />
                        )}
                      </div>
                      <div className="flex items-start justify-between">
                        <div className="lg:min-h-20 lg:max-h-20">
                          <h1 className="pt-3 px-3 text-primaryFontColor font-bold line-clamp-1 text-lg md:text-lg md:pt-7 lg:pt-2">
                            {item.food_name}
                          </h1>
                          <p className="px-3 text-primaryFontColor">
                            <span className="text-mainColor">
                              {item.food_category.replace("_", " ")}
                            </span>{" "}
                            • &#8377;{item.food_types[0].type_price} for one
                          </p>
                        </div>
                        <span
                          onClick={(e) => handleAddToCart(item)}
                          className="cursor-pointer block mt-3"
                        >
                          {inCart(item) ? (
                            <svg
                              width="16"
                              height="16"
                              fill="#FC6011"
                              className="w-6 h-6 lg:min-h-6 mb-1 mr-3"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0M8.5 8a.5.5 0 0 0-1 0v1.5H6a.5.5 0 0 0 0 1h1.5V12a.5.5 0 0 0 1 0v-1.5H10a.5.5 0 0 0 0-1H8.5z"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="16"
                              height="16"
                              fill="#FC6011"
                              className="w-6 h-6 lg:min-h-6 mr-3"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5"
                              />
                              <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                            </svg>
                          )}
                        </span>
                      </div>
                      <div>
                        <div className="flex items-center justify-between"></div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div>
                {favourites === null && (
                  <div className="overflow-hidden mt-5 m-3">
                    <div className="mt-3">
                      <ShimmerThumbnail height={200} rounded />
                      <ShimmerTitle line={1} className="w-2/3" />
                      <ShimmerText line={1} />
                    </div>

                    <div className="mt-3">
                      <ShimmerThumbnail height={200} rounded />
                      <ShimmerTitle line={1} className="w-2/3" />
                      <ShimmerText line={1} />
                    </div>

                    <div className="mt-3">
                      <ShimmerThumbnail height={200} rounded />
                      <ShimmerTitle line={1} className="w-2/3" />
                      <ShimmerText line={1} />
                    </div>
                  </div>
                )}
              </div>
            )}
            {showLoading && (
              <div className="flex items-center justify-center">
                <img src={loading} alt="" className="w-12 h-12 mt-7" />
              </div>
            )}
            {!loadingError && favourites && favourites.length > 0 && (
              <span className="flex flex-col items-center justify-center w-full px-3">
                <button
                  disabled={loadingError}
                  className="w-full p-3 rounded-2xl mt-3 border font-bold text-[#f1414f] shadow-[0px_0px_15px_-10px_rgba(0,0,0,0.7)]  disabled:bg-slate-400 md:w-full md:text-3xl lg:text-sm lg:p-2 lg:max-w-56"
                  onClick={(e) => loadMoreItems(e)}
                >
                  Load More
                </button>
              </span>
            )}
            {!loadingError && favourites && favourites.length <= 0 && (
              <div className="flex flex-col items-center justify-center mt-16 p-5">
                <img src={nothingHere} alt="" />
                <p className="text-xl text-center mt-5 text-slate-400">
                  Nothing here yet
                </p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="overflow-hidden m-3">
          <ShimmerCategoryItem />
          <ShimmerThumbnail center height={50} width={300} />

          <div className="flex flex-row items-center justify-center gap-4 mt-2">
            <div>
              <ShimmerThumbnail height={70} width={70} rounded />
              <ShimmerText line={1} />
            </div>
            <div>
              <ShimmerThumbnail height={70} width={70} rounded />
              <ShimmerText line={1} />
            </div>
            <div>
              <ShimmerThumbnail height={70} width={70} rounded />
              <ShimmerText line={1} />
            </div>
            <div>
              <ShimmerThumbnail height={70} width={70} rounded />
              <ShimmerText line={1} />
            </div>
          </div>

          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-2/3" />
            <ShimmerText line={1} />
          </div>

          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-2/3" />
            <ShimmerText line={1} />
          </div>

          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-2/3" />
            <ShimmerText line={1} />
          </div>

          <div className="mt-3">
            <ShimmerThumbnail height={200} rounded />
            <ShimmerTitle line={1} className="w-2/3" />
            <ShimmerText line={1} />
          </div>
        </div>
      )}

      {/* Types select Modal */}
      {selected && isTypesOpen && (
        <div className="hidden fixed inset-0 md:flex items-center justify-center z-50">
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={() => {
              setIsTypesOpen(false);
              setOpenSheet(false);
            }}
          ></div>
          <div className="bg-white md:p-0 rounded-lg z-10 w-full mx-7 md:w-1/2">
            <div>
              <SheetContent
                item={selected}
                addToCart={addToCart}
                close={() => {
                  setOpenSheet(false);
                  setIsTypesOpen(false);
                }}
                added={inCart(selected)}
              />
            </div>
          </div>
        </div>
      )}

      {/* Bottom sheet */}
      {selected && (
        <BottomSheet
          open={openSheet}
          ref={sheetRef}
          className="fixed z-50 lg:hidden"
          // onClick={() => setOpenSheet(false)}
        >
          <SheetContent
            item={selected}
            addToCart={addToCart}
            close={() => setOpenSheet(false)}
            added={inCart(selected)}
          />
        </BottomSheet>
      )}
    </motion.div>
  );
}
